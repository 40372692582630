<template>
    <spinner-component v-if="loader" type="Table"></spinner-component>
    <q-card ref="fullscreenTarget" class="hierarchyCard ">
        <q-card-actions class="filterhead table-card-actions" v-if="!loader">
            <div style="display: none;">{{ disupdatePrice }} {{ data }}</div>
            <q-btn @click="toggleFullscreen1" flat round dense :icon="toggleicon()"> <q-tooltip anchor="bottom middle"
                    self="top middle" :offset="[-2, -2]">FullScreen</q-tooltip></q-btn>
            <q-btn v-if="isColumnOpen == false" rounded dense flat color="primary" icon="more_vertical"
                @click="toggleColumn"> <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]">Hide
                    Columns</q-tooltip></q-btn>
            <span class=" visiblecolumns">
                <q-select v-if="isColumnOpen" v-model="visibleColumns" :options="hidecolumn" multiple outlined dense
                    options-dense :display-value="$q.lang.table.columns" emit-value map-options option-value="key"
                    options-cover style="min-width: 150px" class="q-mx-md ">

                    <template v-slot:option="{ opt, selected, toggleOption }">
                        <q-item :clickable="true" @click="toggleOption(opt)">
                            <q-item-section>
                                {{ opt.label }}
                            </q-item-section>
                            <q-item-section side>
                                <q-item-label>
                                    <!-- :class="icons.search" -->
                                    <q-icon :name="selected ? icons.view : icons.hide" v-if="opt.group === 'starred'" />
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </template>

                </q-select>
            </span>
            <q-btn v-if="isColumnOpen" style="margin-left: -48px;" flat dense color="primary" size="xs" icon="clear"
                @click="closeColumn" />
            <q-input v-if="isSearchOpen" v-model="search" label="Search" outlined dense class="treeSearch2">
                <template v-slot:append>
                    <q-btn flat dense color="primary" size="sm" icon="clear" @click="closeSearch" />
                </template>
            </q-input>
            <q-btn v-if="isSearchOpen == false" :disable="GroupValue != 'none' || min.value != 'all'" rounded dense flat
                color="primary" icon="search" @click="toggleSearch"> <q-tooltip anchor="bottom middle" self="top middle"
                    :offset="[-2, -2]">Search</q-tooltip></q-btn>
            <q-icon rounded dense flat class="q-ml-sm cursor-pointer" color="primary" name="fas fa-refresh"
                @click="resetQuotespopup()" size="15px"> <q-tooltip anchor="bottom middle" self="top middle"
                    :offset="[-2, -2]">Refresh</q-tooltip></q-icon>
            <q-btn-dropdown v-if="!isSearchOpen && !isColumnOpen" dense color="secondary" outline
                class=" custom-button q-mx-md q-mb-xs" :label="grouplabel" icon="fa-regular fa-object-group">
                <span v-for="(data, index) in GroupByJson" :key="index">
                    <q-list dense>
                        <q-item clickable v-close-popup @click="onItemClickgroup(data.value, data.name)">
                            <q-item-section>
                                <q-item-label>{{ data.name }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </span>
            </q-btn-dropdown>
            <q-space />
            <q-breadcrumbs align="center" style="font-weight: 600;" class="qsummarybread">
                <q-breadcrumbs-el :label="formattedString(titledata[0])" icon="factory">
                    <q-tooltip anchor="bottom middle" self="top middle" :offset="[-1, -1]">
                        {{ myStore.NOEM }}
                    </q-tooltip>
                </q-breadcrumbs-el>
                <q-breadcrumbs-el :label="formattedString(titledata[1])" icon="book"> <q-tooltip anchor="bottom middle"
                        self="top middle" :offset="[-1, -1]">
                        {{ myStore.NProduct.product }}
                    </q-tooltip></q-breadcrumbs-el>
                <q-breadcrumbs-el class="text-info cursor-pointer" @click="showTree()"
                    :label="formattedString(titledata[2])" icon="login"> <q-tooltip anchor="bottom middle"
                        self="top middle" :offset="[-1, -1]">
                        {{ myStore.NIter.iteration }}
                    </q-tooltip></q-breadcrumbs-el>
            </q-breadcrumbs>
            <q-space />
            <q-btn-dropdown outline color=secondary sixe="xs" :label="selectedQtyLabel" dense
                class="custom-button q-ml-sm">
                <q-list dense>
                    <q-item v-for="qty, index in rfq" :key="index" clickable v-close-popup @click="options(qty.value)">
                        <q-item-section>
                            {{ qty.value }}
                        </q-item-section>
                    </q-item>
                </q-list>
            </q-btn-dropdown>
            <q-btn size="md" dense label="Edit" outline color="secondary" disable
                class="headerRow-fixed-width custom-button" icon="edit"></q-btn>

            <q-btn size="md" dense label="Submit" class=" headerRow-fixed-width custom-button" outline color=secondary
                @click="UpdateNextStep" icon="save"></q-btn>
        </q-card-actions>

        <q-markup-table v-if="!loader" id="table_detail" separator="horizontal" :visible-columns="visibleColumns"
            :dense="dense" :dark="dark" :flat="flat" :bordered="bordered" :square="square" :class=toggleClassFullscreen>
            <thead>
                <tr v-if="!hasHeaderSlot">

                    <th v-for="col in columns" :class="'text-' + col.align" :key="col.label">
                        <span v-if="col.customFilter && visibleColumns.indexOf(col.key) !== -1" class="text-left">
                            <span v-for="(fil, index) in customFilterArr" :key="index">
                                <q-btn flat dense size="sm" icon="fa-solid fa-filter" color="secondary"
                                    class="treeFilter1" v-if="col.key === index" :disable="search != ''">
                                    <q-menu>
                                        <span v-for="(data, key) in fil" :key="key">
                                            <q-list dense
                                                :class="data.selected ? 'selectedFilterItem' : 'NonSelectedItem'">
                                                <q-item clickable v-close-popup @click="onFilterClick(data, index, col)"
                                                    class="q-pa-none">
                                                    <q-item-section>

                                                        {{ data.name }}
                                                    </q-item-section>
                                                </q-item>
                                            </q-list>
                                        </span>
                                    </q-menu>
                                </q-btn>
                            </span>
                        </span>
                        <span v-if="visibleColumns.indexOf(col.key) != -1">
                            {{ col.label }}
                        </span>
                        <q-icon v-if="col.sortable && visibleColumns.indexOf(col.key) !== -1"
                            @click="col.sortable && this.min.value === 'all' ? sortBy(col) : null"
                            :name="col.sortDirection == 'asc' ? 'arrow_upward' : 'arrow_downward'"
                            :class="this.min.value !== 'all' ? 'cursor-not-allowed' : 'cursor-pointer'"
                            :disable="this.min.value !== 'all'"></q-icon>

                    </th>
                </tr>
                <tr v-if="hasHeaderSlot">
                    <slot name="header" :columns="columns" v-bind:sortBy="sortBy"></slot>
                </tr>
            </thead>

            <tbody v-for="(item, index)  in (arrayTreeObj) " :key="index">
                <tr :key="index" @click=" selectRow(item), toggleCloseExpanded(item)" :class="{
                    'htable-child  ': (item.level == 1 && item.children.length <= 0 && !item.selected) || (item.level == 0 && item.children.length == 0 && !item.selected),
                    'selected-row': item.selected && item.children.length <= 0,
                    'htable-sel   ': iconName(item) == 'fas fa-caret-down' && item.children.length > 0,
                    'htable-head': iconName(item) == 'fas fa-caret-right' && item.children.length > 0,
                }">
                    <td data-th="Name" v-for="col, col_index in columns " :key="col_index"
                        style="border-bottom: 1px solid rgba(0, 0, 0, 0.12);"
                        :class="filterClr(col.field === 'actions' ? ActionColour(item, col.field) : col.field, item.selected, col.align)"
                        @click="item.children.length > 0 ? toggle(item) : ''">
                        <div v-bind:style="col_index == 0 ? setPadding(item) : { 'padding-left': '0px' }"
                            @click="toggle(item)" :class="iconName(item) != 'done' ? 'text-left ' : ''"
                            v-if="item.children.length > 0">
                            <q-btn @click="toggle(item)" v-if="iconName(item) != 'done' && col_index == 0"
                                :icon="iconName(item)" flat dense>
                            </q-btn>
                            <span v-if="col.field === 'label'" @click="toggle(item)"
                                :class="item.children.length > 0 ? '' : ''">
                                <!-- <q-btn v-if="iconName(item) != 'done' && col_index == 1" :icon="iconName(item)" flat
                                        dense>
                                    </q-btn> -->
                                <span v-if="GroupValue === 'ppn'">PPN : {{ item['ppn'] !== null ? item['ppn'] : '-' }}
                                    &nbsp;:&nbsp;
                                    &nbsp; ({{ item.children.length }} rows)

                                </span>
                                <span v-if="GroupValue === 'sourcing_status'">Sourcing Status :&nbsp;&nbsp;{{
                                    item['sourcing_status']
                                }} &nbsp;:&nbsp;
                                    &nbsp; ({{ item.children.length }} rows)

                                </span>
                                <span v-if="GroupValue === 'source_name'">Source :&nbsp;&nbsp;{{
                                    item['source_name'] != null ?
                                        item['source_name'] : '-' }}
                                    &nbsp;:&nbsp;
                                    &nbsp; ({{ item.children.length }} rows)

                                </span>
                                <span v-if="GroupValue === 'supplier_name'">Supplier Name
                                    :&nbsp;&nbsp;{{
                                        item['supplier_name'] }} &nbsp;:&nbsp;
                                    &nbsp; ({{ item.children.length }} rows)

                                </span>
                                <span v-if="min.value !== 'all' && GroupValue === 'none'">
                                    <span v-if="max === 'total_cost' && GroupValue === 'none'">Total Cost - Top{{
                                        min.value }} %
                                        :&nbsp;&nbsp;
                                        &nbsp; ({{ item.children.length }} rows)</span>
                                    <span v-if="max === 'excess_quantity' && GroupValue === 'none'">Excess Qty Risk (>{{
                                        min.value
                                        }} %
                                        ):&nbsp;&nbsp;
                                        &nbsp; ({{ item.children.length }} rows)</span>
                                    <span v-if="max === 'lead_time' && GroupValue === 'none'">Lead Time Risk - {{ min.id
                                        === 1 ?
                                        'Yes' : 'No'
                                        }}
                                        ( {{
                                            min.id === 1 ? '>' : '<' }} {{ min.value }} ) weeks :&nbsp;&nbsp; &nbsp; ({{
                                            item.children.length }} rows)</span>

                                    </span>
                                    <span v-if="max === 'sourcing_status' && GroupValue === 'none'">Sourcing
                                        Status:&nbsp;&nbsp;{{ min.value
                                        }}
                                        :&nbsp;&nbsp;
                                        &nbsp; ({{ item.children.length }} rows)</span>
                                </span>
                                <span class="" v-if="col.field === 'actions'">

                                    <q-btn flat dense size="13px" icon="refresh" color="negative"
                                        @click="UpdatePriceGroup(item)">
                                        <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]">Update
                                            Pricing</q-tooltip>
                                    </q-btn>
                                    <q-btn flat dense size="13px" disable icon="dashboard" color="info">
                                        <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]">Audit
                                            Log</q-tooltip>
                                    </q-btn>
                                    <q-btn v-if="GroupValue !== 'none' || min.value !== 'all'" flat dense size="13px"
                                        icon="mail" color="warning">
                                        <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]">Email
                                            Supplier</q-tooltip>
                                        <q-menu>
                                            <q-list dense v-for="(email, index) in EmailOptions.slice(0, 2)"
                                                :key="index">
                                                <q-item clickable v-close-popup
                                                    @click="EmailPopup(item.children, ['Group', max, item[GroupValue], email.value])"
                                                    class="q-pa-none">
                                                    <q-item-section> {{ email.name }}</q-item-section>
                                                </q-item>
                                            </q-list>
                                        </q-menu>
                                    </q-btn>

                                </span>
                                <span v-if="GroupValue !== 'none'">
                                    <span class="q-ml-sm" v-if="col.field == 'required_quantity'">
                                        <q-chip color="secondary" square size="sm" class="text-black">Total
                                            Qty&nbsp;:&nbsp;
                                            &nbsp;<q-badge class="q-pl-sm" color="primary" text-color="white"
                                                :label="item[col.field]" /></q-chip>
                                    </span>
                                    <span class="q-ml-sm" v-if="col.field == 'lead_time'">
                                        <q-chip color="primary" square size="sm" class="text-white"> Max Lead
                                            Time&nbsp;:&nbsp;
                                            &nbsp;<q-badge class="q-pl-sm" color="secondary" text-color="black"
                                                :label="item[col.field]" /></q-chip>
                                    </span>
                                    <span class="q-ml-sm" v-if="col.field == 'total_cost'">
                                        <q-chip color="secondary" square size="sm" class="text-black">Total
                                            Cost&nbsp;:&nbsp;
                                            &nbsp;<q-badge class="q-pl-sm" color="primary" text-color="white"
                                                :label="item[col.field]" /></q-chip>
                                    </span>
                                </span>


                        </div>
                        <div v-bind:style="col_index == 0 ? setPadding(item) : { 'padding-left': '0px' }"
                            :class="iconName(item) != 'done' ? '' : ''" v-else>
                            <!-- <q-btn @click="col_index==0?toggle(item):{}" v-if="iconName(item)!='done' && col_index==0"  :icon="iconName(item)" flat dense>  </q-btn> -->
                            <span data-th="Name" class="text-center supplierLabel" v-if="col.field == 'rowindex'"
                                @click="getSubTable(bom_name, item), showHideRow(item)">{{ item.rowIndex
                                }}</span>
                            <span v-else-if="col.field === 'label' && visibleColumns.indexOf('label') != -1"
                                :class="item.children.length > 0 ? '' : ''">
                                <span v-if="item.recommendation_flag === null">
                                    -
                                </span>
                                <span v-else>
                                    <span v-for="(flag, key) in item.recommendation_flag" :key="key" class="recomm ">
                                        <q-badge :class="FlagClr(flag.best, flag.filtered)" rounded dense size="xs"
                                            class="q-mr-xs" v-if="key === 'deviation'">DEV
                                        </q-badge>
                                        <q-badge :class="FlagClr(flag.best, flag.filtered)" rounded dense size="xs"
                                            class="q-mr-xs" v-if="key === 'stock'">ST</q-badge>
                                        <q-badge :class="FlagClr(flag.best, flag.filtered)" rounded dense size="xs"
                                            class="q-mr-xs" v-if="key === 'nc_nr'">NCNR</q-badge>
                                        <q-badge :class="FlagClr(flag.best, flag.filtered)" rounded dense size="xs"
                                            class="q-mr-xs" v-if="key === 'lead_time'">LT</q-badge>
                                        <q-badge :class="FlagClr(flag.best, flag.filtered)" rounded dense size="xs"
                                            class="q-mr-xs" v-if="key === 'moq'">MOQ </q-badge>
                                        <q-badge :class="FlagClr(flag.best, flag.filtered)" rounded dense size="xs"
                                            class="q-mr-xs" v-if="key === 'excess_qty'">EXS</q-badge>
                                        <q-badge :class="FlagClr(flag.best, flag.filtered)" rounded dense size="xs"
                                            class="q-mr-xs" v-if="key === 'unit_price'">UP</q-badge>
                                        <q-badge :class="FlagClr(flag.best, flag.filtered)" rounded dense size="xs"
                                            class="q-mr-xs" v-if="key === 'preferred_supplier'">SUP</q-badge>
                                        <q-badge :class="FlagClr(flag.best, flag.filtered)" rounded dense size="xs"
                                            class="q-mr-xs" v-if="key === 'total_cost'">TC</q-badge>
                                        <q-tooltip anchor="bottom middle" self="top middle" :offset="[0, 0]">
                                            {{ key.charAt(0).toUpperCase() + key.slice(1) }}
                                        </q-tooltip>
                                    </span>
                                </span>
                            </span>

                            <span
                                v-if="col.field === 'sourcing_status' && visibleColumns.indexOf('sourcing_status') != -1">
                                <span>
                                    <q-spinner-dots v-if="item.sourcing_status == 'in_progress'" size="1em"
                                        color="primary" class="SourcingStatus"></q-spinner-dots>

                                    <q-icon v-if="item.sourcing_status == 'sourced_no_bid'" name="close"
                                        color="negative" size="20px" class="SourcingStatus"></q-icon>
                                    <q-icon v-if="item.sourcing_status == 'sourced_1_bid'" name="done" color="positive"
                                        size="20px" class="SourcingStatus"></q-icon>
                                    <q-icon v-if="item.sourcing_status == 'sourced_multi_bid'"
                                        name="fa-solid fa-check-double" size="15px" color="positive"
                                        class="SourcingStatus"></q-icon>

                                    <q-tooltip anchor="bottom middle" self="top middle" :offset="[0, 0]">
                                        {{ item.sourcing_status.charAt(0).toUpperCase() +
                                            item.sourcing_status.slice(1) }}
                                    </q-tooltip>
                                </span>
                                <span>
                                    <q-btn flat rounded dense size="xs" class="userRecommend">
                                        <q-badge v-if="item.is_user_recommendation && item.source_name == 'API'"
                                            color="primary" rounded dense size="xs" outline>U
                                            <q-tooltip anchor="bottom middle" self="top middle" :offset="[0, 0]">
                                                User Selected Sourcing
                                            </q-tooltip>
                                        </q-badge>
                                        <q-badge v-if="item.is_user_recommendation && item.source_name == 'Manual'"
                                            color="primary" rounded dense size="xs" outline>M
                                            <q-tooltip anchor="bottom middle" self="top middle" :offset="[0, 0]">
                                                Manually Updated Sourcing
                                            </q-tooltip>
                                        </q-badge>
                                        <q-badge
                                            v-if="item.is_user_recommendation == false && item.source_name === 'API'"
                                            color="secondary" rounded dense size="xs" outline>A
                                            <q-tooltip anchor="bottom middle" self="top middle" :offset="[0, 0]">
                                                Auto Selected Sourcing
                                            </q-tooltip>
                                        </q-badge>


                                    </q-btn>
                                </span>
                            </span>
                            <span
                                v-else-if="col.field === 'description' && visibleColumns.indexOf('description') != -1">
                                <span>{{ cleanupWords(item.description) }}...
                                    <q-tooltip anchor="bottom middle" self="top middle" :offset="[-5, -5]">
                                        {{ item.description }}
                                    </q-tooltip>
                                </span>
                            </span>
                            <span v-else-if="col.field === 'actions' && visibleColumns.indexOf('actions') != -1">

                                <q-btn flat dense size="13px" icon="refresh" color="negative"
                                    @click="UpdatePriceRowLevel(item.ipn)"
                                    :disable="item.sourcing_status === 'in_progress'">
                                    <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]">Update
                                        Pricing</q-tooltip>
                                </q-btn>
                                <q-btn flat dense size="13px" disable icon="dashboard" color="info">
                                    <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]">Audit
                                        Log</q-tooltip>
                                </q-btn>
                                <q-btn flat dense size="13px" icon="mail" color="warning">
                                    <q-menu>
                                        <q-list dense v-for="(email, index) in EmailOptions" :key="index">
                                            <q-item clickable v-close-popup
                                                @click="EmailPopup(item, ['Single', email.value]), getSubTable(bom_name, item)"
                                                class="q-pa-none" :disable="email.hide">
                                                <q-item-section> {{ email.name }}</q-item-section>
                                            </q-item>
                                        </q-list>
                                    </q-menu>
                                    <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]">Email
                                        Supplier</q-tooltip>
                                </q-btn>
                            </span>
                            <div v-else-if="col.field === 'supplier_name' && visibleColumns.indexOf('supplier_name') != -1"
                                @click="getSubTable(bom_name, item)">
                                <div v-if="item.supplier_name">
                                    <q-icon v-show="item.is_asl_available" name="factory" @click=" showHideRow(item)"
                                        class="cursor-pointer supplier_icon" />
                                    <!-- <q-btn key="itemIndex" flat dense v-if="item.is_asl_available == true"    @click="props.expand = !props.expand, tableStatus = !tableStatus">  {{ item.supplier_name || "Null" }}</q-btn> -->
                                    <q-label class="supplierLabel" key="itemIndex" flat dense
                                        v-if="item.is_asl_available == true" @click="showHideRow(item)">
                                        {{
                                            item.supplier_name || "Null" }}</q-label>
                                    <q-label key="itemIndex" flat dense v-if="item.is_asl_available == false">
                                        {{ item.supplier_name || "Null" }}
                                    </q-label>
                                </div>
                                <q-spinner-dots
                                    v-else-if="item.supplier_name == null && item.sourcing_status == 'in_progress'"
                                    size="2em" color="primary" />
                                <span v-else>{{ "-" }}</span>

                            </div>
                            <span
                                v-else-if="(col.field == 'source_name' || col.field == 'ppn') && visibleColumns.indexOf(col.key) != -1">{{
                                    item[col.field] !== null ?
                                        item[col.field] : '-' }}
                            </span>
                            <span class="ppn-position"
                                v-else-if="(col.field == 'ipn') && visibleColumns.indexOf(col.key) != -1">
                                <span class="popover-container">
                                    <q-icon v-if="item['ipn'] == myStore.notifyData.more_info.ipn" name="fa fa-bell"
                                        class="rotate popover-button" color="info" @mouseover="showIcon"
                                        @mouseout="hideIcon">
                                        <span v-if="myStore.notifyData.more_info.details.length > 0">
                                            <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]">
                                                <span v-for="data, index in myStore.notifyData.more_info.details"
                                                    :key="index">
                                                    <p> Change : {{ data.change }} / Variance Field : {{
                                                        data.variance_field }}
                                                    </p>
                                                </span></q-tooltip></span>
                                    </q-icon>
                                    <!-- <div class="popover-content">
                                        <q-card class=" card-outline-primary1">
                                            <q-card-section align="between" class="bg-grey q-pa-sm">
                                                <div class="text-bold">Details</div>
                                            </q-card-section>
                                            <q-card-section class="card-header-section1">
                                                <span v-for="data, index in myStore.notifyData.more_info.details"
                                                    :key="index">
                                                    <p> Change : {{ data.change }} / Variance Field : {{
                                                        data.variance_field }}
                                                    </p>
                                                </span>
                                            </q-card-section>
                                            <q-card-actions align="right" class="card-footer-section">

                                                <q-btn @click="NotifyRefresh" dense outline color=secondary
                                                    label="Refresh" size="sm"></q-btn>
                                            </q-card-actions>
                                        </q-card>
                                    </div> -->
                                </span>
                                {{ item[col.field] !== null ? item[col.field] : '-' }}
                            </span>
                            <span v-else>
                                <span v-if="visibleColumns.indexOf(col.key) != -1">{{ item[col.field] }}
                                    <span v-if="myStore.notifyData.more_info.details.length > 0">
                                        <span v-for="data, index in myStore.notifyData.more_info.details" :key="index">
                                            <span class="popover-container"
                                                v-if="col.field == data.variance_field && item['ipn'] == myStore.notifyData.more_info.ipn">
                                                <q-icon
                                                    :name="data.change == 'up' ? 'fas fa-up-long' : 'fas fa-down-long'">
                                                    <q-tooltip anchor="bottom middle" self="top middle"
                                                        :offset="[-2, -2]">
                                                        A {{ data.variance_field }} Update is Available </q-tooltip>
                                                </q-icon>
                                                <!-- <div class="popover-content">
                                        <q-card class=" card-outline-primary">
                                            <q-card-section align="between" class="bg-grey q-pa-sm">
                                                <div class="text-bold">Details</div>
                                            </q-card-section>
                                            <q-card-section class="card-header-section1">
                                                <q-icon
                                                :name="data.change=='up'?'fas fa-up-long':'fas fa-down-long'"></q-icon>  A {{ data.variance_field }} Update is Available
                                            </q-card-section>
                                            <q-card-actions align="right" class="card-footer-section">

                                                <q-btn @click="NotifyRefresh" dense outline color=secondary
                                                    label="Refresh" size="sm"></q-btn>
                                            </q-card-actions>
                                        </q-card>
                                    </div> -->
                                            </span>
                                        </span>
                                    </span>
                                </span>


                            </span>

                        </div>
                    </td>
                </tr>
                <q-tr v-if="item.SubTableOpen && item.children.length === 0" id="hidden_row12" key="supplier_name">
                    <q-td colspan="21" class="bg-Accordion ">
                        <div style="padding: 1rem 1rem;width: 50%; ">
                            <div class=" row items-start ">

                                <asl-table :bom_name="bom_name" :subtabledata="subtabledata" @toggleClose="toggleClose"
                                    @updatepriceemit="updatepriceemit"></asl-table>


                            </div>

                        </div>
                    </q-td>
                </q-tr>
            </tbody>

        </q-markup-table>
        <div v-if="data.length === 0 && !loader">
            <div class="full-width row flex-center text-black q-gutter-sm">
                <q-icon size="1.5em" name="report_problem" />
                <span>
                    No Data Found
                </span>
            </div>
        </div>
        <div class="row justify-between q-mt-sm"
            style=" border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;"
            v-if="!loader && data.length > 0">
            <div class="q-ml-lg text-grey-8 hint-fullscreen"><b>Tip: &nbsp;</b> Press &nbsp;<span
                    class="fullscreenHintText">
                    Shift + F</span> &nbsp; {{ toggleFullscreenTip }}</div>
            <div class="flex" style="font-size: 12px;margin-top:-3px">
                <div v-if="GroupValue == 'none' && min.value == 'all'" class=" cursor-pointer q-mr-sm q-pa-xs"
                    style="font-size: 12px; " dense color="primary"> Records per page&nbsp;:&nbsp; <q-btn
                        style="font-size: 12px;" class="btn--no-hover q-pa-none" flat dense
                        :label="pagination.rowsPerPage === -1 ? 'All' : pagination.rowsPerPage"
                        icon-right="fa-solid fa-caret-down" color="primary">
                        <q-menu>
                            <q-list dense v-for="(pag, index) in paginationOptions" :key="index">
                                <q-item clickable v-close-popup @click="selectpagination(pag)" class="q-pa-none">
                                    <q-item-section> {{ pag }}</q-item-section>
                                </q-item>
                            </q-list>
                        </q-menu>

                    </q-btn>
                </div>
                <q-pagination v-model="pagination.page" :max="pagesNumber" input class="paginationclass q-mr-sm"
                    size="sm" direction-links boundary-links icon-first="skip_previous" icon-last="skip_next"
                    icon-prev="fast_rewind" icon-next="fast_forward" />

            </div>
        </div>


    </q-card>
    <q-dialog v-model="email" persistent>
        <q-card class="gdialog card-outline-primary confirm-popup" v-if="resetpopup">

            <q-card-section class="card-header-section1">
                <div class="text-h6 q-ml-sm"><q-icon name="report_problem" flat dense color="warning" size="25px"
                        class="q-mb-xs"></q-icon><span class="q-ml-sm ">Refresh Sourcing </span></div>
            </q-card-section>

            <q-card-section class="">
                <span class=""> Are you sure you want to Refresh the Sourcing ?</span>
            </q-card-section>
            <q-card-actions align="right" class="card-footer-section">
                <q-btn label="Ok" outline color="secondary" @click="resetQuotes()">
                </q-btn>
                <q-btn label="Cancel" outline color="secondary" v-close-popup />
            </q-card-actions>
        </q-card>
        <q-card v-else class="gdialog emailpopup" flat>
            <q-separator />

            <q-card-section
                :class="{ 'q-pa-none scroll ': EmailType[0] === 'Single', 'scroll q-pa-none': EmailType[0] === 'Group' }">

                <EmailComponent :EmailRow="EmailRow" :EmailType="EmailType" @closeEmail="closeEmail"
                    :GroupValue="GroupValue" :EmailContent="EmailContent" />
            </q-card-section>

            <q-separator />
        </q-card>
    </q-dialog>
</template>


<script>
import { SpinnerComponent, commonFunction } from "@/utils"
import { ref, defineAsyncComponent } from 'vue';
import { QuotesService, SettingService, AlertService, EmailService } from '@/services';
import { useMyStore } from '@/store';
import { Loading, AppFullscreen } from 'quasar'
export default {
    name: 'QuotationTab',
    props: ['bom_name', 'AlifeCycle'],
    components: {
        SpinnerComponent,
        // AslTable,
        AslTable: defineAsyncComponent(() => import('./subtable/AslTable.vue')),
        EmailComponent: defineAsyncComponent(() => import('@/components/EmailComponent.vue')),
        // ManualPopup
    },
    setup() {
        const pagination = ref({
            sortBy: 'desc',
            descending: false,
            page: 1,
            rowsPerPage: 25,
        });
        const paginationOptions = ref([5, 7, 10, 15, 20, 25, 50, 'All']);
        const EmailOptions = ref([]);
        const EmailBody = ref({});
        const EmailContent = ref({});
        var qp = JSON.parse(localStorage.getItem('quotespage'))
        const min = ref(qp.Filter);
        const GroupValue = ref(qp.GroupBy);
        const GroupName = ref(qp.GroupName);
        const max = ref(qp.Filter_Field);
        const myStore = useMyStore();
        const QuotationRow = ref([]);
        const search = ref("");
        const isHovered = ref(false);
        return {
            // hierarchy Var
            GroupValue,
            GroupName,
            isExpanded: ref(true),
            selectedRowID: ref({}),
            itemId: ref(null),
            temp_data: ref([]),
            customFilterArr: ref([]),
            temp_index: ref(1),
            first_call: ref(false),
            SubTableOpen: ref(false),
            EmailOptions,
            EmailBody,
            EmailContent,
            // ------------------------
            paginationOptions,
            isHovered,
            pagination,
            myStore,
            mpnIndex: ref(''),
            expanded: ref([]),
            icons: ref({}),
            visibleColumns: ref(['manufacturer', 'rowindex', 'label', 'mpn', 'ppn', 'bom_quantity', 'bom_part_quantity', 'required_quantity', 'recommended_quantity', 'recommended_price', 'stock_inventory', 'total_cost', 'lead_time', 'attrition', 'qty_with_attrition', 'description', 'spn', 'moq', 'deviation', 'price', 'ipn', 'supplier_stock', 'supplier_name', 'source_name', 'sourcing_status', 'total_quantity', 'excess_quantity', 'actions']),
            search,
            QuotationRow,
            fullScreenMode: ref(false),
            min,
            max,
            loader: ref(true),
            selectedRow: ref({}),
            email: ref(false),
            ipn: ref(''),
            subtabledata: ref([]),
            QuotationColors: ref({}),
            isSearchOpen: ref(false),
            isColumnOpen: ref(false),
            QlifeStatus: ref('In Quoting'),
            EmailRow: ref([]),
            closeEmit: ref(false),
            resetpopup: ref(false),
            EmailType: ref(''),

        }
    },
    watch: {
        // hierarchy Watch
        selectedIDRow: function (val) {
            if (this.selectedRowID != null) {
                this.$set(this.selectedRowID, "_index", val);
            }
        },
        // ---------------------
        bom_name(newBomName, oldBomName) {
            if (newBomName !== oldBomName) {
                this.GroupValue = 'none'
                this.GroupName = 'None'
                if (this.myStore.bom_name == newBomName) {
                    localStorage.setItem('ipn', this.myStore.GlobalIpn)
                } else { localStorage.setItem('ipn', null) }
                this.selectedQty = ''
                this.min = { "name": "All", "value": "all", "id": 1, "selected": false }
                var quotes = { 'GroupBy': 'none', 'GroupName': 'None', 'Filter': { "name": "All", "value": "all", "id": 1, "selected": false }, 'Filter_Field': "" }
                localStorage.setItem("quotespage", JSON.stringify(quotes));
                this.visibleColumns = this.hidecolumn.map(k => k.key)
                this.isSearchOpen = false
                this.isColumnOpen = false
                this.search = ""
                this.pagination.page = 1
                for (let arr in this.customFilterArr) {
                    this.customFilterArr[arr].forEach((array) => {
                        array.selected = false
                    })
                }
                this.getBomName();
            }
        },
        ipn(newVal, old) {
            if (newVal !== old) {
                this.myStore.disablebuttons = false
                this.myStore.ManualInputFields = ({ manual: false, inputValue: '', mpnInput: '', SupplierInput: '', spnInput: '', mult: 0, price: 0, Moq: 0, leadTime: 0 })
                this.myStore.pbQty = ''
            }
        },
        selectedQty(newqty, oldqty) {
            if (newqty !== oldqty) {
                this.getBomName();
                // this.getSubTable();
            }
        },
        refreshpricing() {
            this.getBomName();
        },
        GroupValue(newval) {
            if (newval !== 'none') {
                this.min = {
                    "name": "All",
                    "value": "all",
                    "id": 1,
                    "selected": false
                }
                this.max = ''
                for (let arr in this.customFilterArr) {
                    this.customFilterArr[arr].forEach((array) => {
                        if (this.max == arr) {
                            array.selected = true;
                        } else {
                            array.selected = false
                        }
                    })
                }
                this.arrayTreeObj.map((item) => {
                    item.SubTableOpen = false
                })
            }
        },
    },
    computed: {
        titledata() {
            var oemExpand = []
            oemExpand = JSON.parse(localStorage.getItem('expandedNodes'))
            oemExpand.push(this.bom_name);
            return oemExpand;
        },
        //custom table header
        rfq() {
            return this.myStore.newArray;
        },
        QuotationColumn() { return this.myStore.fields.quotation_fields; },
        columns() {
            let datav = this.QuotationColumn.map(obj => {
                obj.field = obj.key
                obj.format = val => `${val}`;
                obj.name = obj.key;
                obj.align = 'center';
                obj.group = "starred"
                obj.filterable = false
                // obj.sortable = true;

                return obj
            });

            return datav;
        },
        hidecolumn() {           //column Hide
            let colfilter = this.columns.filter((k) => (k.key !== 'rowindex'))
            return colfilter
        },
        // hierarchy Row
        newQuoteRow() {
            let datav = this.QuotationRow.map((obj, index) => {
                obj.mpnIndex = obj.mpn + index;
                return obj
            });
            return datav;
        },
        pagesNumber() {
            if (this.pagination.rowsPerPage != -1 && this.search == "") {
                const newpage = Math.ceil(this.filteredRows.length / this.pagination.rowsPerPage)
                return newpage
            } else if (this.pagination.rowsPerPage != -1 && this.search !== '') {
                const newpage = Math.ceil(this.paginatedData.length / this.pagination.rowsPerPage)
                return newpage
            }
            else { return 1 }
        },
        data() {
            if (this.pagination.rowsPerPage == -1) {
                return this.paginatedData;
            } else {
                const startIndex = (this.pagination.page - 1) * this.pagination.rowsPerPage;
                const endIndex = startIndex + this.pagination.rowsPerPage;
                return this.paginatedData.slice(startIndex, endIndex);
            }

        },
        paginatedData() {
            const searchTerm = this.search.toLowerCase();
            if (searchTerm !== null && searchTerm !== undefined && searchTerm != "") {
                return this.filteredRows.filter((row) => {
                    this.pagination.page = 1;
                    const mpnCheck = row.mpn && row.mpn.toLowerCase().includes(searchTerm);
                    const manufacturerCheck = row.manufacturer && row.manufacturer.toLowerCase().includes(searchTerm);
                    const descriptionCheck = row.description && row.description.toLowerCase().includes(searchTerm);
                    const supplierCheck = row.supplier_name && row.supplier_name.toLowerCase().includes(searchTerm);
                    const ipnCheck = row.ipn && row.ipn.toLowerCase().includes(searchTerm);

                    return mpnCheck || manufacturerCheck || descriptionCheck || supplierCheck || ipnCheck;
                });
            } else {
                return this.filteredRows
            }
        },
        // hierarchy array
        filteredRows() {
            return this.newhierarchyarray(this.newQuoteRow);
        },
        arrayTreeObj() {
            let vm = this;
            let newObj = [];
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.temp_data = [];
            vm.temp_index = 1;
            vm.recursive(vm.data, newObj, 0, vm.itemId, vm.isExpanded, vm.first_call);
            vm.first_call = ref(false);
            if (this.filter && this.filter.length >= 3) {
                let data = this.filter_data(newObj, this.filter, true, this.columns)
                return data
            }

            return newObj;
        },
        //Disable Update Pricing 
        disupdatePrice() {
            // var disablePrice=''
            let resultd = this.data.filter(x => x.sourcing_status === 'in_progress'); var disablePrice = true;
            if (resultd.length > 0) { disablePrice = false; }
            //alert(hideextract)
            this.$emit("disablePrice", disablePrice);
            return disablePrice;
        },
        hasHeaderSlot() {
            return null;
            //return this.$slots.hasOwnProperty("header");
        },
        grouplabel() {
            // var group = JSON.parse(localStorage.getItem('quotespage'))
            if (this.GroupName !== 'ppn') {
                const format = this.GroupName.charAt(0).toUpperCase() + this.GroupName.slice(1)
                return `Group By : ${format}`
            } else {
                return `Group By : ${this.GroupName.toUpperCase()}`
            }

        },
        toggleClassFullscreen() {
            if (AppFullscreen.isActive) { return 'fullscreenHeight my-sticky-header-table my-sticky-header-last-column-table'; }
            else { return 'TableHeight my-sticky-header-table my-sticky-header-last-column-table' }
        },
        toggleFullscreenTip() {
            if (AppFullscreen.isActive) { return 'to exit FullScreen '; }
            else { return 'to enter Fullscreen' }
        },
        // ---------------------------------
        // --------------------------------------------------
        selectedQtyLabel() {
            return `Qty:${this.myStore.GlobalQty}`;
        },
        selectedQty() {
            return this.myStore.GlobalQty;
        },
        refreshpricing() {
            return this.myStore.refreshpricing;
        },

    },
    created() {
        if (this.defaultExpandAll) {
            this.first_call = ref(true)
        }
    },
    unmounted() {
    },
    beforeUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    },
    methods: {
        showIcon() {
            this.isHovered = true;
        },
        NotifyRefresh() {
            // this.myStore.refreshpricing = Math.random();
            this.getBomName()
        },
        showTree() {
            this.myStore.isCardVisible = true;
            this.myStore.isToastVisible = true;
        },
        hideIcon() {
            this.isHovered = false;
        },
        selectpagination(value) {   //Changing the Pagination variable
            if (value == 'All') {
                this.pagination.rowsPerPage = -1
            } else {
                this.pagination.rowsPerPage = parseInt(value);
            }
            this.pagination.page = 1
        },
        handlechange(val) {
            this.search = val
            this.pagination.page = 1
        },
        handleKeyDown(event) {       //Keyboard shortcuts
            if (event.shiftKey && event.keyCode === 70) {
                event.preventDefault();
                this.toggleFullscreen1();
            }
        },
        resetQuotespopup() {
            this.email = true;
            this.resetpopup = true
        },
        resetQuotes() {                  //Reset Quotes
            this.email = false;
            for (let arr in this.customFilterArr) {
                this.customFilterArr[arr].forEach((array) => {
                    array.selected = false
                })
            }
            this.GroupValue = 'none';
            this.GroupName = "None"
            if (AppFullscreen.isActive) { this.toggleFullscreen1() }
            this.isColumnOpen = false
            this.min = { "name": "All", "value": "all", "id": 1, "selected": true }
            this.pagination = { page: 1, rowsPerPage: 25, }
            this.visibleColumns = this.hidecolumn.map(k => k.key)
            this.columns.map(c => c.sortDirection = 'desc')
            var primaryQty = this.myStore.newArray.find(qty => qty.is_primary)?.value;
            this.myStore.GlobalQty = primaryQty
            localStorage.setItem('qty', primaryQty);
            var arr = []; arr.push(this.bom_name), arr.push(primaryQty)
            localStorage.setItem("bomname-qty", JSON.stringify(arr));
            var quotes = { 'GroupBy': 'none', 'GroupName': 'None', 'Filter': { "name": "All", "value": "all", "id": 1, "selected": true }, 'Filter_Field': "" }
            localStorage.setItem("quotespage", JSON.stringify(quotes));
            this.getBomName()
        },
        // hierarchy Functions
        showHideRow(row) {               //SubTable open close
            this.newQuoteRow.forEach(row1 => {
                if (row1.mpnIndex !== row.mpnIndex) {
                    row1.SubTableOpen = false;
                } else {
                    row1.SubTableOpen = !row1.SubTableOpen;
                }
            });
            this.mpnIndex = row.mpnIndex

        },
        ActionColour(item, field) {         //Action Fields selected Row
            if (field === 'actions' && this.iconName(item) == 'fas fa-caret-down' && item.children.length > 0) {
                return 'htable-sel'
            } else { return '' }

        },
        async getFilters() {           //Get Custom Filter options
            await SettingService.customFilter().then((x) => {
                this.customFilterArr = x;
            }).catch((er) => {
                console.log(er);
            })
        },
        onItemClickgroup(value, name) {          //Click Group By Function
            for (let arr in this.customFilterArr) {
                this.customFilterArr[arr].forEach((array) => {
                    array.selected = false
                })
            }
            this.min = { "name": "All", "value": "all", "id": 1, "selected": true }
            this.pagination.page = 1
            this.GroupValue = value
            this.GroupName = name
            var quotes = { 'GroupBy': value, 'GroupName': name, 'Filter': { "name": "All", "value": "all", "id": 1, "selected": true }, 'Filter_Field': "" }
            localStorage.setItem("quotespage", JSON.stringify(quotes));
        },
        async getGroupOptions() {   //Get Group By Fields
            try {
                await SettingService.hierarchyGroup().then((x) => {
                    this.GroupByJson = x
                }).catch((er) => {
                    console.log('Error', er);
                })
            } catch (err) {
                console.log("Error in Component", err);
            }
        },
        /* Hierarchy FUnctions Start*/
        recursive(obj, newObj, level, itemId, isExpend, first_call) {
            let vm = this;
            obj.forEach(function (o) {
                if (o.children && o.children.length != 0) {
                    o.level = level;
                    o.leaf = false;
                    o.sortDirection = "none";
                    o.sorted = ref(false);
                    o['_index'] = vm.temp_index;
                    vm.temp_index = vm.temp_index + 1;
                    if (first_call) {
                        o.expend = true
                    }
                    newObj.push(o);
                    if (o._index == itemId) {
                        o.expend = isExpend;
                    }
                    if (o.expend == true) {
                        vm.recursive(o.children, newObj, o.level + 1, itemId, isExpend, first_call);
                    }
                } else {
                    o.level = level;
                    o.leaf = ref(true);
                    o.sortDirection = "none";
                    o.sorted = ref(false);
                    o['_index'] = vm.temp_index;
                    vm.temp_index = vm.temp_index + 1;
                    newObj.push(o);
                    return false;
                }
            });
        },
        iconName(item) {
            if (item.expend == true) {
                return "fas fa-caret-down";
            }
            if (item.children && item.children.length > 0) {
                return "fas fa-caret-right";
            }
            return "done";
        },
        toggle(item) {
            let vm = this;
            vm.itemId = item._index;
            item.leaf = ref(false);
            //show  sub items after click on + (more)
            if (
                item.leaf == false &&
                item.expend == undefined &&
                item.children != undefined
            ) {
                if (item.children.length != 0) {
                    vm.recursive(item.children, [], item.level + 1, item._index, item.expend, false);
                }
            }
            if (item.expend == true && item.children != undefined) {
                //let __subindex = 0;
                item.children.forEach(function (o) {
                    o.expend = undefined;
                    vm.setUndefined(o)
                });
                item["expend"] = ref(undefined);
                item["leaf"] = ref(false);
                vm.itemId = null;
            }
        },
        setPadding(item) {
            return `padding-left: ${item.level * 0}px;`;
        },
        setUndefined(item) {
            let vm = this;
            // if (item.hasOwnProperty('children') && item.children) {
            if (item.children) {
                item.children.forEach(function (o) {
                    o.expend = ref(undefined);
                    vm.setUndefined(o)
                });
            }
        },
        sortBy(col) {
            this.newQuoteRow.forEach(row1 => {
                row1.SubTableOpen = false;
            });
            if (col.sortDirection === "asc") {
                this.sort_recursive(this.QuotationRow, col, true);
                col.sortDirection = "desc";
                // col.arrow_downward = 'arrow_downward';
            }
            // else if (col.sortDirection === "desc") {
            //     this.sort_recursive(this.data, col, null);
            //     col.sortDirection = null
            // }
            else {
                this.sort_recursive(this.QuotationRow, col, false);
                col.sortDirection = "asc";
            }
            col.sorted = true;
            this.$emit('sort_column', col)
            this.resetSorting(col);
        },
        sort_recursive(elements, col, descending) {
            console.log("sort", descending);
            if (descending !== null) {
                elements.sort(this.sort_by(col, descending));
                for (let i = 0; i < elements.length; i++) {
                    // if (elements[i].hasOwnProperty('children')) {
                    if (typeof elements[i].children != "undefined") {
                        this.sort_recursive(elements[i].children, col, descending);
                    }
                }
            } else {
                const defRow = this.newQuoteRow[0]
                return this.selectRow(defRow)
            }
        },
        sort_by(col, descending) {
            if (descending === null) { return this.data }
            let direction = !descending ? 1 : -1;
            if (col.sortingType === "custom" && typeof col.sortingFunc === "function") {
                return function (a, b) {
                    return col.sortingFunc(a, b) * direction;
                };
            }
            let key = function (x) {
                // if (!x.hasOwnProperty(col.field)) {
                //   return ""
                // }
                return (x[col.field] === null ? "" : x[col.field]);
            };
            switch (col.sortingType) {
                case "number":
                    key = function (x) {
                        return parseFloat(x[col.field]);
                    };
                    break;
                case "date":
                    key = function (x) {
                        return new Date(x[col.field]);
                    };
                    break;
            }
            return function (a, b) {
                return a = key(a), b = key(b), direction * ((a > b) - (b > a));
            };
        },
        resetSorting(sortedCol) {
            let arraySize = this.columns.length;
            for (let i = 0; i < arraySize; i++) {
                let col = this.columns[i];
                if (col.field != sortedCol.field) {
                    col.sorted = ref(false);
                    col.sortDirection = "none";
                }
            }
        },
        filter_data(arr, filterString, expandingProperty, colDefinitions, expand) {
            let filtered = [];
            let addedItems = new Set();

            // Function to check if any child of an item matches the filter criteria
            const hasMatchingChild = (item) => {
                if (!item.children || item.children.length === 0) return false;
                return item.children.some(child => this.include(child, filterString, expandingProperty, colDefinitions) || hasMatchingChild(child));
            };

            for (let i = 0; i < arr.length; i++) {
                let item = arr[i];

                // Check if the item or any of its children match the filter criteria
                if (this.include(item, filterString, expandingProperty, colDefinitions) || hasMatchingChild(item)) {
                    if (!addedItems.has(item)) {
                        filtered.push(item);
                        addedItems.add(item);
                    }

                    // If expanding, ensure parent nodes are expanded
                    if (expand) {
                        let parent = item;
                        while (parent) {
                            parent.expend = true;
                            parent = parent.parent; // Assuming each item has a reference to its parent
                        }
                    }
                }
            }

            return filtered;
        },

        include(item, filterString, expandingProperty, colDefinitions) {
            let includeItem = false;
            let filterApplied = false;
            //first check the expandingProperty
            filterApplied = true;
            if (this.checkItem(item, filterString, expandingProperty)) {
                includeItem = true;
            }
            //then check each of the other columns
            let arraySize = colDefinitions.length;
            for (let i = 0; i < arraySize; i++) {
                let col = colDefinitions[i];
                if (col.filterable) {
                    filterApplied = true;
                    if (this.checkItem(item, filterString, col)) {
                        includeItem = true;
                    }
                }
            }
            if (filterApplied) {
                return includeItem;
            } else {
                return true;
            }
        },
        checkItem(item, filterString, col) {
            if (col.sortingType === "number") {
                if (item[col.field] != null
                    && parseFloat(item[col.field]) === parseFloat(filterString)) {
                    return true;
                }
            } else {
                if (item[col.field] != null
                    && item[col.field].toLowerCase().indexOf(filterString.toLowerCase()) !== -1) {
                    return true;
                }
            }
        },
        newhierarchyarray(arr) {
            const resultArray = [];
            const parentMap = new Map();
            for (const item of arr) {
                if (this.GroupValue === 'ppn') {
                    const ppm = item.ppn;
                    if (!parentMap.has(ppm)) {
                        const parent = { ...item, children: [] };
                        resultArray.push(parent);
                        parentMap.set(ppm, parent);
                    } else {
                        const parent = parentMap.get(ppm);
                        let newNode = { ...parent, children: [] };
                        if (!parent.newNodeAdded) {
                            parent.children.push({ ...newNode, children: [] });
                            parent.newNodeAdded = true;
                        }
                        parent.children.push({ ...item, children: [] });
                    }
                } else if (this.GroupValue === 'sourcing_status') {
                    const ppm = item.sourcing_status;
                    if (!parentMap.has(ppm)) {
                        const parent = { ...item, children: [] };
                        resultArray.push(parent);
                        parentMap.set(ppm, parent);
                    } else {
                        const parent = parentMap.get(ppm);
                        let newNode = { ...parent, children: [] };
                        if (!parent.newNodeAdded) {
                            parent.children.push({ ...newNode, children: [] });
                            parent.newNodeAdded = true;
                        }
                        parent.children.push({ ...item, children: [] });
                    }
                } else if (this.GroupValue === 'source_name') {
                    const ppm = item.source_name;
                    if (!parentMap.has(ppm)) {
                        const parent = { ...item, children: [] };
                        resultArray.push(parent);
                        parentMap.set(ppm, parent);
                    } else {
                        const parent = parentMap.get(ppm);
                        let newNode = { ...parent, children: [] };
                        if (!parent.newNodeAdded) {
                            parent.children.push({ ...newNode, children: [] });
                            parent.newNodeAdded = true;
                        }
                        parent.children.push({ ...item, children: [] });
                    }
                } else if (this.GroupValue === 'supplier_name') {
                    const ppm = item.supplier_name;
                    if (!parentMap.has(ppm)) {
                        const parent = { ...item, children: [] };
                        resultArray.push(parent);
                        parentMap.set(ppm, parent);
                    } else {
                        const parent = parentMap.get(ppm);
                        let newNode = { ...parent, children: [] };
                        if (!parent.newNodeAdded) {
                            parent.children.push({ ...newNode, children: [] });
                            parent.newNodeAdded = true;
                        }
                        parent.children.push({ ...item, children: [] });
                    }
                } else if (this.min.value !== 'all') {
                    const sumData = this.newQuoteRow.map((obj) => {
                        if (this.max === 'total_cost') {
                            if (obj.total_cost !== null && obj.total_cost !== '' && obj.total_cost > 0) {
                                return obj.total_cost
                            } else {
                                const tcost = 0; return tcost
                            }
                        }
                        if (this.max === 'excess_quantity') {
                            if (obj.excess_quantity !== null && obj.excess_quantity !== '' && obj.excess_quantity > 0) {
                                return obj.excess_quantity
                            } else {
                                const exc_qty = 0; return exc_qty
                            }
                        }
                    })
                    let tqty = sumData.reduce((sum, item1) => sum + item1, 0);
                    var ppm = []
                    const percent = ((tqty / 100) * this.min.value)
                    if (this.max === 'lead_time' && this.min.id === 1 && item.lead_time > this.min.value) {
                        ppm = item.lead_time > this.min.value
                        if (!parentMap.has(ppm)) {
                            const parent = { ...item, children: [] };
                            resultArray.push(parent);
                            parentMap.set(ppm, parent);
                        } else {
                            const parent = parentMap.get(ppm);
                            let newNode = { ...parent, children: [] };
                            if (!parent.newNodeAdded) {
                                parent.children.push({ ...newNode, children: [] });
                                parent.newNodeAdded = true;
                            }
                            parent.children.push({ ...item, children: [] });
                        }
                    }
                    if (this.max === 'lead_time' && this.min.id === 2 && item.lead_time < this.min.value) {
                        ppm = item.lead_time < this.min.value
                        if (!parentMap.has(ppm)) {
                            const parent = { ...item, children: [] };
                            resultArray.push(parent);
                            parentMap.set(ppm, parent);
                        } else {
                            const parent = parentMap.get(ppm);
                            let newNode = { ...parent, children: [] };
                            if (!parent.newNodeAdded) {
                                parent.children.push({ ...newNode, children: [] });
                                parent.newNodeAdded = true;
                            }
                            parent.children.push({ ...item, children: [] });
                        }
                    }
                    if (this.max === 'total_cost' && item.total_cost <= percent && item.total_cost > 0) {
                        ppm = item.total_cost <= percent;
                        if (!parentMap.has(ppm)) {
                            const parent = { ...item, children: [] };
                            resultArray.push(parent);
                            parentMap.set(ppm, parent);
                        } else {
                            const parent = parentMap.get(ppm);
                            let newNode = { ...parent, children: [] };
                            if (!parent.newNodeAdded) {
                                parent.children.push({ ...newNode, children: [] });
                                parent.newNodeAdded = true;
                            }
                            parent.children.push({ ...item, children: [] });
                        }
                    }
                    if (this.max === 'excess_quantity' && item.excess_quantity > percent) {
                        ppm = item.excess_quantity >= percent;
                        if (!parentMap.has(ppm)) {
                            const parent = { ...item, children: [] };
                            resultArray.push(parent);
                            parentMap.set(ppm, parent);
                        } else {
                            const parent = parentMap.get(ppm);
                            let newNode = { ...parent, children: [] };
                            if (!parent.newNodeAdded) {
                                parent.children.push({ ...newNode, children: [] });
                                parent.newNodeAdded = true;
                            }
                            parent.children.push({ ...item, children: [] });
                        }
                    }
                    if (this.max === 'sourcing_status' && item.sourcing_status === this.min.value) {
                        ppm = item.sourcing_status === this.min.value;
                        if (!parentMap.has(ppm)) {
                            const parent = { ...item, children: [] };
                            resultArray.push(parent);
                            parentMap.set(ppm, parent);
                        } else {
                            const parent = parentMap.get(ppm);
                            let newNode = { ...parent, children: [] };
                            if (!parent.newNodeAdded) {
                                parent.children.push({ ...newNode, children: [] });
                                parent.newNodeAdded = true;
                            }
                            parent.children.push({ ...item, children: [] });
                        }
                    }
                } else {
                    const ppm = item.ppn;
                    const parent = { ...item, children: [] };
                    resultArray.push(parent);
                    parentMap.set(ppm, parent);
                }
            }
            let newresultArray = resultArray.map((obj, index) => {
                if (obj.children.length > 0) {
                    let tqty = obj.children.reduce(function (accumulator, curValue) {
                        const qty = curValue.required_quantity !== null ? curValue.required_quantity : 0
                        return parseInt(accumulator) + parseInt(qty)
                    }, 0)
                    let tcost = obj.children.reduce(function (accumulator, curValue) {
                        const cost = curValue.total_cost !== null ? curValue.total_cost : 0
                        // const roundoff= (accumulator) + (cost)
                        return (accumulator) + (cost)
                    }, 0)
                    let maxValue = obj.children.reduce(function (accumulator, curValue) {
                        const leadTime = curValue.lead_time !== null ? curValue.lead_time : 0
                        return (accumulator = accumulator > parseInt(leadTime) ? accumulator : parseInt(leadTime));
                    }, 0);
                    obj.lead_time = maxValue;
                    obj.required_quantity = tqty;
                    obj.total_cost = Math.round(tcost);
                }

                if (obj.children.length === 0) {
                    obj.rowIndex = index + 1
                }
                obj.children.map((obj1, index) => {
                    if (obj1.children.length === 0) {
                        obj1.rowIndex = index + 1
                    }
                })

                return obj
            });
            return newresultArray;
        },
        /* Hierarchy FUnctions End*/
        toggleicon() {
            if (AppFullscreen.isActive) { return 'fullscreen_exit'; } else { return 'fullscreen'; }
        },
        toggleFullscreen1() {
            const target = this.$refs.fullscreenTarget.$el;
            AppFullscreen
                .toggle(target).then(() => { // success!
                }).catch((err) => { alert(err); });
        },
        // -----------------------------------------------------------------
        // Format the header row Breadcrumbs
        formattedString(string) {
            const res = commonFunction.BreadcrumbFormat(string)
            return res
        },
        //open Email popup
        EmailPopup(row, type) {
            // var temp = ""
            this.email = true
            this.resetpopup = false
            this.EmailRow = row
            this.EmailType = type
            // temp = this.GroupValue !== 'none' ? this.GroupValue : this.max
            if (type[0] === 'Group') { this.EmailContent = this.EmailBody.GroupBy.Body[type[3]] }
            else { this.EmailContent = this.EmailBody.Individual.Body[type[1]] }
        },
        closeEmail(close) {
            this.email = close
            this.EmailOptions.map((email) => {
                if (email.value === 'blank') { email.selected = true }
                else { email.selected = false }
            })
        },
        toggleClose(value) {          //Toggle close subtable
            this.closeEmit = value
            if (this.closeEmit === true) {
                const ipn = localStorage.getItem('ipn')
                this.arrayTreeObj.map((item) => {
                    if (item.ipn === ipn) {
                        item.SubTableOpen = false
                    }
                })
                this.expanded = []
            }
            else {
                this.expanded = []
                this.getBomName()
            }
        },
        FlagClr(best, filtered) {      //Recommended Badge colours
            const res = commonFunction.labelColors(best, filtered);
            return res
        },
        updatepriceemit(ipn) {
            this.UpdatePriceRowLevel(ipn)
        },
        async UpdatePriceRowLevel(ipn) {
            await QuotesService.updatePricingRow(this.bom_name, ipn).then(() => {
                Loading.show();
                this.myStore.refreshpricing = Math.random();
                setTimeout(() => {
                    this.getBomName();
                    Loading.hide();
                }, 2000);


            }).catch(err => console.log(err))
        },
        async UpdatePriceGroup(row) {
            let resultd = row.children.filter(x => x.sourcing_status === 'in_progress');
            if (resultd.length > 0) {
                AlertService.Error('Update pricing cannot be updated, sourcing is in progress')
            } else {
                var group_name = this.GroupValue != 'none' ? this.GroupValue : 'Filter'
                var group_value = this.GroupValue != 'none' ? row[this.GroupValue] : this.min.value
                const body = {
                    ipn_list: row.children.map(i => i.ipn)
                }
                await QuotesService.UpdatePriceGroup(this.bom_name, group_name, group_value, body)
                    .then(() => {
                        Loading.show();
                        this.myStore.refreshpricing = Math.random();
                        setTimeout(() => {
                            this.getBomName();
                            Loading.hide();
                        }, 2000);


                    }).catch(err => console.log(err))
            }

        },
        toggleCloseExpanded(val) {
            this.selectRow(val)
            if (this.mpnIndex != "" && this.mpnIndex != val.mpnIndex) {
                this.newQuoteRow.forEach(row1 => {
                    row1.SubTableOpen = false;
                });

                this.expanded = [];
            }

        },
        toggleExpanded(val) {
            this.mpnIndex = val;
            this.expanded = this.expanded[0] === val ? [] : [val]
        }, toggleSearch() {
            if (this.isColumnOpen) {
                this.isColumnOpen = false
            }
            this.isSearchOpen = !this.isSearchOpen
        },
        closeSearch() {
            this.search = ""
            this.isSearchOpen = false;
        },
        closeColumn() {
            this.isColumnOpen = false;
        },
        toggleColumn() {
            this.isColumnOpen = !this.isColumnOpen
        },
        UpdateNextStep() {
            if (this.AlifeCycle == 'In Sourcing') {
                QuotesService.UpdateLifeCycle(this.bom_name, this.QlifeStatus).then((x) => {
                    console.log(x)
                    AlertService.Success('Sourcing Submitted Successfully');
                })
                this.$emit('updateSource', 5, this.QlifeStatus)
            }
            else {
                this.$emit('updateSource', 5)
            }

        },
        getIcons() {
            SettingService.Viewicons().then((x) => {
                this.icons = x.NavIcons;
                this.QuotationColors = x.QuotationColors;
            });
        },
        async getSubTable(bom_name, item) {
            // this.SubTableOpen = true
            const aslqty = this.selectedQty ? this.selectedQty : this.myStore.GlobalQty
            // const aslqty = localStorage.getItem('qty')
            await QuotesService.SubTab(bom_name, item.ipn, aslqty).then((x) => {
                const data = x;
                data.map(async (row, index) => {
                    row.defaultPrice = `Quantity: ${row.pb_quantity !== null ? row.pb_quantity : ''}, Price: ${row.pb_price !== null ? row.pb_price : ''}`
                    row.selectedValue = row.pb_quantity,
                        row.uniqueindex = row.spn + index;

                    if (row.is_primary) {
                        this.myStore.pbQty = row.pb_quantity !== null ? row.pb_quantity : ''
                    }
                    if (row.source_name === 'Manual') {
                        this.myStore.ManualInputFields.manual = row.is_primary
                        this.myStore.ManualInputFields.RequiredQty = row.required_quantity
                        this.myStore.ManualInputFields.stock = row.supplier_stock
                        this.myStore.ManualInputFields.SupplierInput = row.supplier_name,
                            this.myStore.ManualInputFields.spnInput = row.spn,
                            this.myStore.ManualInputFields.mpnInput = row.mpn,
                            this.myStore.ManualInputFields.inputValue = row.manufacturer,
                            this.myStore.ManualInputFields.leadTime = row.lead_time,
                            this.myStore.ManualInputFields.price = row.pb_price,
                            this.myStore.ManualInputFields.Moq = row.moq,
                            this.myStore.ManualInputFields.mult = row.mult
                    }
                })
                let resultd = data.filter(x => x.is_primary == true); this.myStore.disablebuttons = false;
                if (resultd.length > 0) { this.myStore.disablebuttons = true; }
                this.myStore.ManualInputFields.RequiredQty = item.required_quantity
                this.subtabledata = data.filter(row => row.source_name !== 'Manual');

            });
        },

        options(type) {
            var arr = []; arr.push(this.bom_name), arr.push(type)
            localStorage.setItem("bomname-qty", JSON.stringify(arr));
            localStorage.setItem("qty", type)
            this.myStore.GlobalQty = type;
        },
        filterClr(keyvalue, rowselect = false, align) {
            if (this.QuotationColumn && !rowselect) {
                let output = this.QuotationColumn.filter((user) => {
                    return user.key === keyvalue;
                });
                var clr = "";
                if (output[0] !== undefined) {
                    if (output[0].datasource !== undefined) {
                        clr = this.QuotationColors[output[0].datasource]
                    } if (output[0].datasource === 'bom') {
                        clr = `QuotationColorsBom text-${align} `
                    } else if (output[0].datasource === 'computed') {
                        clr = `QuotationColorsComputed text-${align} `
                    } else if (output[0].datasource === 'internal') {
                        clr = `QuotationColorsInternal text-${align} `
                    } else if (output[0].datasource === 'supplier') {
                        clr = `QuotationColorsSupplier text-${align} `
                    } else if (output[0].datasource === 'recommendation') {
                        clr = `QuotationColorsRecommendation text-${align} `
                    } else {
                        clr = `QuotationColorsgeneral text-center`
                    }
                }
                return clr;
            }
        },
        onFilterClick(data, index, col) {
            this.isSearchOpen = false
            this.max = index
            if (data.value !== 'all') { col.sortDirection = 'none' } else { col.sortDirection = 'asc' }
            this.GroupValue = 'none'
            this.GroupName = 'None'
            for (let arr in this.customFilterArr) {
                this.customFilterArr[arr].forEach((array) => {
                    if (array.id === data.id && this.max == arr) {
                        array.selected = true;
                    } else {
                        array.selected = false
                    }
                })
            }
            this.min = data
            var quotes = { 'GroupBy': 'none', 'GroupName': 'None', 'Filter': { "name": data.name, "value": data.value, "id": data.id, "selected": data.selected }, 'Filter_Field': index }
            localStorage.setItem("quotespage", JSON.stringify(quotes));
        },
        selectRow(row) {
            this.loader = true
            this.ipn = row.ipn
            // Unselect previously selected row
            localStorage.setItem("ipn", row.ipn)
            this.$emit("bom-ipn", row.ipn)

            if (this.selectedRow) {
                this.selectedRow.selected = false;
            }
            // Select the clicked row
            row.selected = true;
            this.selectedRow = row;
            this.newQuoteRow.forEach(row1 => {
                if (row1.mpnIndex !== row.mpnIndex) {
                    row1.selected = false;
                } else {
                    row1.selected = true;
                }
            });
            this.loader = false
        },
        async getBomName() {
            try {
                this.loader = true;
                if (this.fullScreenMode) { this.loader = false; }
                const E = await EmailService.EmailContent()
                this.EmailOptions = E.options
                this.EmailBody = E
                // const QuoteQty = await this.selectedQty ? this.selectedQty : this.myStore.GlobalQty
                const QuoteQty = JSON.parse(localStorage.getItem('bomname-qty')) || []
                    const x = await QuotesService.getQuotationRow(this.bom_name, QuoteQty[1]);
                    if (x.length > 0) {
                        this.QuotationRow = x;
                        this.expanded = []
                        const firstRow = localStorage.getItem('ipn')
                        if (firstRow !== 'null' && firstRow !== undefined) {
                            const Qrow = this.QuotationRow.find(row => row.ipn === firstRow) || null;

                            if (Qrow) {
                                this.selectRow(Qrow);
                            } else {
                                const SRow = this.QuotationRow[0];
                                this.selectRow(SRow);
                            }

                        } else {
                            const SRow = this.QuotationRow[0];
                            this.selectRow(SRow);
                        }

                    } else {
                        this.QuotationRow = [];
                    }
                    this.loader = false; 

            } catch (err) {

                this.loader = false;
                this.QuotationRow = [];
                console.error("err:-", err);
            }
        },
        cleanupWords(description) {
            const words = commonFunction.descriptionFormat(description);
            return words
        },

    },
    mounted() {
        try {
            setTimeout(() => {
                this.getBomName();
                this.getGroupOptions()
                this.getIcons();
                this.getFilters();
                document.addEventListener("keydown", this.handleKeyDown);
            }, 1000)
        } catch (error) {

            console.error('Error in component:', error);
        }
    },
}
</script>

<style scoped>
.hierarchyCard .q-pagination {
    margin-top: -5px !important;
    font-size: 12px !important;
}

.q-card.hierarchyCard {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

:deep(.q-btn.btn--no-hover .q-focus-helper) {
    display: none;
}

.rotate {
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    20% {
        transform: rotate(50deg);
    }

    40% {
        transform: rotate(0deg);
    }

    80% {
        transform: rotate(-50deg);
    }
}

.ppn-position {
    position: relative;
}

.popover-container {
    position: absolute;
    display: inline-block;
}

.popover-button {
    /* background-color: #007BFF; */
    color: white;
    border: none;
    margin-top: 3px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: -15px;
}

.popover-content {
    visibility: hidden;
    /* width: 200px; */
    background-color: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: 0px solid grey;
    /* padding: 10px; */
    position: absolute;
    /* bottom: 120%; */
    /* Position above the button */
    left: 50%;
    transform: translateX(-50%);
    z-index: 99999;
    transition: visibility 0.2s ease, opacity 0.2s ease;
    opacity: 0;
}

.popover-content::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

/* Show the popover on hover */
.popover-container:hover .popover-content {
    visibility: visible;
    opacity: 1;
}
</style>
