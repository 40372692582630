<script>

import { QuotesService } from '@/services'
import { ref } from 'vue';
import { useMyStore } from '@/store';
export default {
    props: ['type', 'bom_name'],
    setup() {
        const myStore = useMyStore();
        return {
            myStore,
            SourcedCount: ref(""),
            notSourcedCount: ref(0),
            lastUpdated: ref(' - '),
            duedate: ref(''),
            current_date: ref('')
        }
    },
    watch: {
        bom_name(newBomName, oldBomName) {
            if (newBomName != oldBomName)
                this.getQuotesToasterData()
        },
        refreshpricing(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getQuotesToasterData();
            }
        },
    },
    computed: {
        refreshpricing() {
            return this.myStore.refreshpricing;
        }
    },
    methods: {
        async getQuotesToasterData() {
            try {
                if (this.bom_name != null) {
                const res = await QuotesService.getToaster(this.bom_name)
                    this.SourcedCount = res.sourced_count
                    this.notSourcedCount = res.not_sourced_count

                    this.lastUpdated = res.last_sourced_date
                    this.current_date = res.current_date
                    this.duedate = res.due_date
                }
            }
            catch (err) {
                console.log(err)
                if (err) {
                    this.lastUpdated = null
                    this.current_date = null
                    this.duedate = null
                    this.SourcedCount = 0
                    this.notSourcedCount = 0
                }
            }
        }
    },
    mounted() {
        this.getQuotesToasterData()
    }
}

</script>
<template>
    <q-card class="bg-transparent no-shadow no-border q-mr-md toaster" bordered>
        <q-card-section class="q-pa-none">

            <div class="row q-col-gutter-sm ">
                <div class="col-md-2 col-sm-12 col-xs-12 ">
                    <q-item class="q-pa-none  tarea flat ">
                        <q-item-section class=" q-pa-xs q-ml-none  text-right">
                            <q-item-label class="  text-h6 text-weight-bolder">{{ SourcedCount + notSourcedCount
                                }}</q-item-label>
                            <q-item-label class="text-bottom">Bom Parts</q-item-label>
                        </q-item-section>
                    </q-item>
                </div>

                <div class="col-md-2 col-sm-12 col-xs-12  ">
                    <q-item class="q-pa-none  tarea flat ">
                        <q-item-section class=" q-pa-xs q-ml-none  text-right">
                            <q-item-label class=" text-h6 text-weight-bolder">{{
                                $filters.QuotesToaster(lastUpdated)}}</q-item-label>
                            <q-item-label class="text-bottom">Last updated date</q-item-label>
                        </q-item-section>
                    </q-item>
                </div>
                <div class="col-md-2 col-sm-12 col-xs-12 ">
                    <q-item class="q-pa-none tarea tarea-success   flat ">
                        <q-item-section class=" q-pa-xs q-ml-none  text-right">
                            <q-item-label class=" text-h6 text-weight-bolder">{{ notSourcedCount }} / {{ SourcedCount +
                                notSourcedCount }}</q-item-label>
                            <q-item-label class=" text-bottom">Sourcing Pending</q-item-label>
                        </q-item-section>
                    </q-item>
                </div>

                <div class="col-md-2 col-sm-12 col-xs-12  ">
                    <q-item class="q-pa-none  tarea tarea-danger flat ">
                        <q-item-section class=" q-pa-xs q-ml-none  text-right">
                            <q-item-label class=" text-h6 text-weight-bolder"
                                v-if="$filters.overDueDateFormat(duedate, current_date) == 0">-</q-item-label>
                            <q-item-label class=" text-h6 text-weight-bolder" v-else>{{
                                $filters.overDueDateFormat(duedate, current_date)
                                }}</q-item-label>
                            <q-item-label class=" text-bottom" v-if="$filters.overDueDate(duedate, current_date) > 0">Days
                                to go</q-item-label>
                            <q-item-label class=" text-bottom"
                                v-else-if="$filters.overDueDate(duedate, current_date) < 0">Days OverDue</q-item-label>
                            <q-item-label class=" text-bottom" v-else>Day</q-item-label>
                        </q-item-section>
                    </q-item>
                </div>
            </div>

        </q-card-section>
    </q-card></template>