import { defineStore } from 'pinia';
import axios from 'axios';
import { ref } from 'vue'
import { QuotesService, SettingService } from './services';


export const useMyStore = defineStore('myStore', {
  state: () => ({
    fields: ref([]),
    isCardVisible:ref(true),
      isToastVisible:ref(true),
      disablebuttons:ref(false),
    dashboardlist: ref([]),
    settingsList: ref([]),
    rfqDetails: ref([]),
    GlobalQty: ref(0),
    treeLabels: ref({}),
    newArray: ref([]),
    bom_name: ref(''),
    GlobalIpn: ref(''),
    notifyData:ref({ "uid": "",
      "type": "",
      "message": "",
      "more_info": {
        "ipn": "",
        "details":  [],
        "bom_name": ""
      },
      "created_at": "" }),
    unreadcount:ref(0),
    copyerrmsg: ref(''),
    pbQty: ref(0),
    userdata: ref({ "access_token": null, "email": null, "first_name": null, "last_name": null }),
    refreshpricing: ref("star"),
    MenuUrl: ref(''),
    NOEM: ref(''),
    NProduct: ref({ product: '' }),
    NIter: ref({ iteration: '' }),
    MenuList: ref([]),
    ManualInputFields: ref({ manual: false, inputValue: '', mpnInput: '', SupplierInput: '', spnInput: '', mult: 0, price: 0, Moq: 0, stock: 0, leadTime: 0, RequiredQty: '', qty: '' })
  }),
  initialize() {
    this.loadPosts();
  },

  actions: {
    async loadPosts() {
      try {
        this.userdata = JSON.parse(localStorage.getItem('cmuser')) || { "access_token": null, "email": null, "first_name": null, "last_name": null };
        if (this.userdata.access_token) {
          this.bom_name = localStorage.getItem('bom_name')
          if (this.bom_name != null) {
            const PopupNodes = JSON.parse(localStorage.getItem('expandedNodes'))
            this.NOEM = PopupNodes[0];
            this.NProduct.product = PopupNodes[1]
            this.NIter.iteration = localStorage.getItem('bom_name')
          }
          //View Settings for OEM,PRoduct,Iteration
          SettingService.ViewSettingData().then((x) => {
            this.treeLabels = Object.values(x.treeLabels);

          });
          //help
          //help
          const helpresponse = await axios.get('api/helpMenu.json');
          this.MenuList = helpresponse.data;
          this.MenuUrl = this.MenuList['Help'][0].url
          //popup fields changed on type

          //fields json make Global
          const response = await axios.get('api/fields.json');
          this.fields = response.data;
          // settings module json
          const SettingsRes = await axios.get('setting/settingsJson.json');
          this.settingsList = SettingsRes.data;

          //set dahsboard id Dynamic (First id)
          const dresponse = await axios.get('api/dashboard.json');
          this.dashboardlist = dresponse.data;
          const Qid = this.findFirstSectionId('Quotes')
          const Did = this.findFirstSectionId('Dashboard')
          const Rid = this.findFirstSectionId('Reports')
          const Sid = this.findFirstSectionId('Suppliers')
          const orderId = this.findFirstSectionId('Orders')
          const Oemid = this.findFirstSectionId('OEM')
          var DashData = { 'Qid': Qid, 'Did': Did, 'Rid': Rid, 'Sid': Sid, 'Oid': orderId, 'Customer': Oemid }
          localStorage.setItem("DashID", JSON.stringify(DashData))
        }



      } catch (error) {
        console.error('Error loading posts:', error);
        throw error;
      }
    },
    findFirstSectionId(module) {
      const moduleData = this.dashboardlist[module];
      if (moduleData) {
        const sections = Object.keys(moduleData);
        if (sections.length > 0) {
          const firstSection = sections[0];
          const firstSectionData = moduleData[firstSection];
          if (firstSectionData && firstSectionData.length > 0) {
            return firstSectionData[0].id;
          }
        }
      }
      return null;
    },

    async loadQuotes() {
      try {
        this.bom_name = localStorage.getItem('bom_name');
        if(this.bom_name!=null){
          
        await QuotesService.getRfqDetails(this.bom_name).then((x) => {
          this.rfqDetails = x;
          if (x.required_quantity !== null && x.required_quantity !== undefined) {
            this.newArray = Object.keys(x.required_quantity).map(function (key) {
              return {
                value: key,
                is_primary: x.required_quantity[key].is_primary,
                details: x.required_quantity[key].details
              };
            });
            if (localStorage.getItem('qty') !== 'null') {
              this.GlobalQty = localStorage.getItem('qty');
            } else {
              this.GlobalQty = this.newArray.find(qty => qty.is_primary)?.value;
              localStorage.setItem('qty', this.GlobalQty);
              var arr = []; arr.push(this.bom_name), arr.push(this.GlobalQty)
              localStorage.setItem("bomname-qty", JSON.stringify(arr));
            }
          }
          else {
            this.newArray = [{ value: '-', is_primary: false, details: '-' }]
          }
        }).catch((err) => {
          console.log("err...", err);
        });
      }

      } catch (error) {
        console.error('Error loading posts:', error);
        throw error;
      }
    },

  },
  watch: {
    bom_name(newBom, oldBom) {
      if (newBom != oldBom) {
        this.loadPosts();
      }

    }
  }
});
