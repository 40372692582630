import { fetchWrapper } from '@/helpers';
import { API_BASE_URL } from '@/api.config';
const apiUrl2 = `${API_BASE_URL}`;

export const NotifyService = {
   Notificationcount,
   getNotification,
   UnreadNotification,
   markasAllRead,
   markasRead
};
// get Unread Notification Count
function Notificationcount() {
    return fetchWrapper.get(`${apiUrl2}/notifications/unread/count`)
}
// get All Notification 
function getNotification() {
    return fetchWrapper.get(`${apiUrl2}/notifications/all`)
}
// get Unread Notification 
function UnreadNotification() {
    return fetchWrapper.get(`${apiUrl2}/notifications/unread`)
}
//  Mark all as read
function markasAllRead(body) {
    return fetchWrapper.post(`${apiUrl2}/notifications/mark_all/as_read`,body)
}
//  Mark all as read
function markasRead(uid) {
    return fetchWrapper.post(`${apiUrl2}/notifications/mark/as_read/${uid}`)
}