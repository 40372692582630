<template>
    <q-form @submit="copyButton('copy')">
    <q-card class="gdialog">

        <q-card-section class="q-pa-none">
            <q-bar class="bg-primary text-white">
                <div>Copy {{ myStore.treeLabels[2] }}</div>
                <q-space />

                <q-btn dense flat icon="close" v-close-popup>
                    <q-tooltip class="bg-white text-primary">Close</q-tooltip>
                </q-btn>
            </q-bar>

        </q-card-section>

        <q-card-section class="q-px-md">
            <q-breadcrumbs class="newQuoteBread">
                <template v-slot:separator>
                    <q-icon size="10px" name="fa-solid fa-angles-right" color="primary" />
                </template>

                <q-breadcrumbs-el :label="myStore.NOEM" icon="factory" class="newQuoteBread-text" />
                <q-breadcrumbs-el :label="myStore.NProduct.product" icon="book" class="newQuoteBread-text" />

            </q-breadcrumbs>
            <q-input class="q-py-md" @update:model-value="handleChange" filled :model-value="myStore.NIter.iteration"
                :rules="QuoteNameValidation" placeholder="New RFQ *" label=" RFQ *" />
            <div v-if="myStore.copyerrmsg && myStore.copyerrmsg  !== '' && myStore.NIter.iteration !== ''" class="text-negative blinking-text">{{
               myStore.copyerrmsg 
                }}</div>

        </q-card-section>
        <q-card-actions align="right">
            <!-- <q-btn class=" text-black" @click="newButton()" color="secondary" label="Create New" icon="add" /> -->
            <q-btn type="submit" class=" custom-button" outline color="secondary" label="Create Copy"
                icon="fa-regular fa-copy" />
            <q-btn label="Cancel" outline color="secondary" v-close-popup />
        </q-card-actions>

    </q-card>
</q-form>
</template>
<script>
import { useMyStore } from '@/store';
// import { AlertService, UploadService } from '@/services'
export default {
    setup() {
        const myStore = useMyStore();
        const QuoteNameValidation = [
        (v) => !!v || 'Field is required',
            (v) => (v && v.length >= 3 && v.length <= 20) || 'Must be between 3 and 20 characters',
            (v) => /^[^\s]+$/.test(v) || 'No spaces allowed',
            (v) => /^[a-zA-Z0-9_-]+$/.test(v) || 'No special characters allowed'
        ]
        return {
            myStore,
            QuoteNameValidation,
        }
    },

    methods: {
        handleChange(value) {
            this.myStore.NIter.iteration = value
            this.myStore.copyerrmsg =''
        },
        copyButton(type) {
            this.$emit('copyButton', type)
        }
    }
}
</script>