<template>
    <!-- {{ bom_name }}
 ... {{ qtylist }}...  -->
  <RouterView />
</template>

<script>
import { UploadService } from '@/services';
import { useMyStore } from '@/store';
import { commonFunction } from '@/utils';
// import {ref} from 'vue'
export default {
  name: 'LayoutDefault',


  setup() {
    const store = useMyStore();
    return {
      store
    }
  },
  computed: {
    
    qtylist(){
      return this.store.newArray;
    },
    bom_name(){
      return this.store.bom_name;
    }

  },
  watch:{
    bom_name(newBom,oldBom){
      if(newBom!=oldBom){
        this.store.loadQuotes();
      }

    }
  },
methods: {
    async findLocalData() {
    let myData = localStorage.getItem("bom_name");
    if (myData == null)
      await UploadService.getStaticTree().then((x) => {
        const treeData = x;
        if(treeData.length>0){
        // Starting from the root of the tree
        for (const rootNode of treeData) {
          if (rootNode.type === "oem") {
            const iterationName = commonFunction.findFirstIterationInOEM(rootNode);
            if (iterationName) {
              console.log("Found First Iteration Name:", iterationName);
              localStorage.setItem("bom_name", iterationName)
              break;  // once node found
            }
          }
        }
        const filteredOemProductNames = commonFunction.filterOemProductNamesWithIteration(treeData);
        console.log(filteredOemProductNames);
        var r = [];
        console.log("app.vue r ", r)
        localStorage.setItem("expandedNodes", JSON.stringify(filteredOemProductNames.slice(0, 2)));
        localStorage.setItem('ipn',null)
        localStorage.setItem('qty',null)
        var quotes = {'GroupBy':'none','GroupName':'None','Filter':{ "name": "All",  "value": "all",  "id": 1, "selected": false  },'Filter_Field':""}
          localStorage.setItem("quotespage",JSON.stringify(quotes));
        location.reload();
      }else{return  document.getElementById('No-data').innerHTML="No Data Available"}
      }).catch(err=>console.log(err))
    else {
      return
    }
  },
},
mounted() {
  //const myStore = useMyStore();
  try {
    this.store.loadPosts();
  } catch (error) {
    console.error('Error in component:', error);
  }
  window.onerror = function (err) {
    console.log("err:", err);
  };


  if (this.store.userdata.access_token) {
    this.findLocalData();
  }
},
}
</script>
<style>
:root {
  iframe#webpack-dev-server-client-overlay {
    display: none !important
  }
}

iframe#webpack-dev-server-client-overlay {
  display: none !important
}
</style> 
