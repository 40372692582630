<template>
  <q-page class="q-pa-sm layout">
    <div class="page-heading q-pa-sm">HELP</div>
    <div class="col ">
      <div class="  row flex  ">

        <div v-if="isCardVisible && $q.screen.gt.xs" class='column-height-help  col-3 '>


          <q-card class="   " flat>
            <q-tabs class="no-shadow flat" v-model="LeftTab" :switch-indicator="true" align="justify" narrow-indicator
              active-bg-color="primary text-white" indicator-color="primary">
              <q-tab name="filters" label="Explorer" class="curved-tabs" />
            </q-tabs>
            <q-separator />
            <q-tab-panels v-model="LeftTab" animated class="text-dark  ">
              <q-tab-panel name="filters" class="q-pa-none  ">
                <HelpMenu  />
              </q-tab-panel>
            </q-tab-panels>
          </q-card>
        </div>
        <div
          :class="{ 'col-8   column-height-help': isCardVisible == true, ' col-12    column-height-help': isCardVisible == false || !$q.screen.gt.xs, }">
          <q-card class="   ">

            <q-tabs class=" " v-model="LeftTab" :switch-indicator="true" align="justify" narrow-indicator
              active-bg-color="primary text-white" indicator-color="primary">
              <q-tab name="filters" label="Video" class="curved-tabs" />
            </q-tabs>

            <q-card-section class="helpd"><video width="600" controls :src="myStore.MenuUrl"
                ref="video"></video></q-card-section>

          </q-card>
        </div>
      </div>



    </div>
  </q-page>
</template>

<script>

import { ref } from 'vue';
import HelpMenu from '@/components/HelpMenu.vue'
import { useMyStore } from '@/store';

export default {
  name: 'HelpPage',

  setup() {
    const isCardVisible = ref(true);
    const closeCard = () => {
      isCardVisible.value = false;
    };
    const myStore = useMyStore();
    return {
      myStore,
      isCardVisible,
      closeCard,
      LeftTab: ref('filters'),
      RightTab: ref('video'),
    

    }
  }, components: {
    HelpMenu,
  },
}
</script>