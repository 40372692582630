<template>
  
     
      <q-select class="filter-multidropdown" outlined :disable="Enable==false" :model-value="(model=Values)" @update:model-value="model = $event" :options="Options"  dense options-dense multiple emit-value map-options>
        <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
          <q-item v-bind="itemProps">
            <q-item-section side>
              <q-checkbox  size="xs" :model-value="selected" @update:model-value="toggleOption(opt)" />
            </q-item-section>
            <q-item-section>
              {{ opt }}
            </q-item-section>
          </q-item>
        </template>
      </q-select>
</template>
  
<script>
import { ref } from "vue";

export default {
  props: ["Values", "Options", "multiSelectKey",'Enable'],
  setup(props) {
    return {
      model: ref(props.Values),
      labelKey: ref(props.multiSelectKey)
    };
  },
  watch: {
    model(newVal) {
      if (newVal && this.labelKey) {
        this.getChange(newVal, this.labelKey);
      }
    },
  },
  methods: {
    getChange(value, labelKey) {
      this.$emit("change-checkbox-dropdown", [value, labelKey]);
    },
  }
};
</script>
  