import { createRouter, createWebHistory } from 'vue-router';
import { HomeView, QuotesView,UploadPage,ReportsPage,SettingsPage,OemPage,SupplierPage,OrdersPage,HelpPage } from '@/views'; 

const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: () => import('../layouts/MainLayout.vue'),
        children: [
            //{path: '/quotes', component: () => QuotesView},
            {path: '/', component: HomeView},
            {path: '/quotes', component: QuotesView},
            {path:'/uploads',component:UploadPage},
            {path:'/reports',component:ReportsPage},
            {path:'/suppliers',component:SupplierPage},
            {path:'/settings',component:SettingsPage},
            {path:'/oem',component:OemPage},
            {path:'/orders',component:OrdersPage},
            {path:'/help',component:HelpPage},
            {path:'/demo',component: () => import('../pages/DemoPage.vue')},
            {path:'/notification',component: () => import('../pages/NotifyPage.vue')},
           // {path: '/login', component: () => import('../pages/LoginPage.vue')  },
            {path: '/:catchAll(.*)*', component: () => import('../pages/ErrorPage.vue')  }

        ]
    } ,
    {path: '/login', component: () => import('../pages/LoginPage.vue')  }
]

export const router = createRouter({ history: createWebHistory(), routes })

 

router.beforeEach(async (to) => {
    console.log("before:",to);  console.log("path:",to.fullPath)
    const cmuser = JSON.parse(localStorage.getItem('cmuser')) || {"access_token":null};
    console.log("beforeEach cmuser:",cmuser.access_token);

        // redirect to login page if not logged in and trying to access a restricted page
        const publicPages = ['/login'];
        const authRequired = !publicPages.includes(to.path);
       if (authRequired && !cmuser.access_token) {
          return '/login';
       }else if (to.fullPath=="/login" && cmuser.access_token) {
          return '/';
       }

    // redirect to login page if not logged in and trying to access a restricted page
    // const publicPages = ['/login'];
    // const authRequired = !publicPages.includes(to.path);
    // const auth = useAuthStore();

    // if (authRequired && !auth.user) {
    //     auth.returnUrl = to.fullPath;
    //     return '/login';
    // }
});
