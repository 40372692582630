

<!-- sort ,Filter,Column hide -->
<template>
  <div class="flex row">
    <div class="col-10 flex row justify-between">
      <spinner-component v-if="loader" type="Table"></spinner-component>
      <q-table v-if="!loader" flat :rows="filteredRows" :columns="newquotescolm" row-key="mpn"
        :visible-columns="visibleColumns">
        <template v-slot:top-right="props">
          <q-btn flat round dense :icon="props.inFullscreen ? 'fullscreen_exit' : 'fullscreen'"
            @click="props.toggleFullscreen" class="q-ml-md" />
          <q-select v-model="visibleColumns" :options="newquotescolm" multiple outlined dense options-dense
            :display-value="$q.lang.table.columns" emit-value map-options option-value="key" options-cover
            style="min-width: 150px">

            <template v-slot:option="{ opt, selected, toggleOption }">
              <q-item :clickable="true" @click="toggleOption(opt)">
                <q-item-section>

                  {{ opt.label }}
                </q-item-section>
                <q-item-section side>
                  <q-item-label>
                    <q-icon :name="selected ? 'fa fa-eye ' : 'fa fa-eye-slash'"
                      :color="selected ? 'positive' : 'negative'" v-if="opt.group === 'starred'" />
                  </q-item-label>
                </q-item-section>
              </q-item>
            </template>
          </q-select>

        </template>
        <template v-slot:top-left>

          <q-input v-model="search" label="Search" outlined clearable dense />
        </template>
        <template v-slot:header="props">
          <q-tr :props="props" >
            <q-th>#</q-th>
            <q-th v-for="col in props.cols" :key="col.key" :props="props" :style="{ backgroundColor: col.color }"  >
              {{ col.label }}


              <div v-if="col.key == 'required_quantity'" class="text-left">
                <q-btn-dropdown color="primary" dense size="xs" dropdown-icon="change_history">
                  <q-list>
                    <q-item clickable v-close-popup @click="onItemClick(200)">
                      <q-item-section>
                        <q-item-label>200</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="onItemClick(300)">
                      <q-item-section>
                        <q-item-label>300</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="onItemClick(4000)">
                      <q-item-section>
                        <q-item-label>4000</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-btn-dropdown>
              </div>
            </q-th>
          </q-tr>

        </template>
        <!-- <template v-slot:body="props">
          <q-tr :props="props" :key="`m_${props.row.index}`">
            <q-td style="width:3%">{{ props.rowIndex + 1 }}</q-td>

            <q-td v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.value }}
              <div v-if="col.name == 'status'">
                <div v-if="props.row.status == 1">
                  <q-badge color="white" flat dense>
                    <q-icon name="error" color="negative"></q-icon>
                  </q-badge>

                  <q-linear-progress :value="props.row.status" color="positive" />
                </div>
                <div v-else>
                  {{ props.row.status }}
                </div>
              </div>
            </q-td>


          </q-tr>
        </template> -->
        <template v-slot:body="props">
          <q-tr :props="props"  @click="selectRow(props.row)" :class="{ 'selected-row': props.row.selected }">
            <td>{{ props.rowIndex }}</td>
            <q-td key="manufacturer"  :style="{ backgroundColor: filterClr('manufacturer') }"
                    v-if="visibleColumns.indexOf('manufacturer') != -1">
              {{ props.row.manufacturer || 'null' }}

            </q-td>

            <q-td key="mpn" :style="{ backgroundColor: filterClr('mpn') }"
                    v-if="visibleColumns.indexOf('mpn') != -1">
              {{ props.row.mpn }}

            </q-td>

            <q-td key="bom_quantity" :style="{ backgroundColor: filterClr('bom_quantity') }"
                    v-if="visibleColumns.indexOf('bom_quantity') != -1">
              {{ props.row.bom_quantity || 111 }}

            </q-td>

            <q-td key="required_quantity" :style="{ backgroundColor: filterClr('required_quantity') }"
                    v-if="visibleColumns.indexOf('required_quantity') != -1">
              {{ props.row.required_quantity }}

            </q-td>
            <q-td key="stock_inventory" :style="{ backgroundColor: filterClr('stock_inventory') }"
                    v-if="visibleColumns.indexOf('stock_inventory') != -1">
              {{ props.row.stock_inventory }}

            </q-td>
            <q-td key="total_cost" :style="{ backgroundColor: filterClr('total_cost') }"
                    v-if="visibleColumns.indexOf('total_cost') != -1">
              {{ props.row.total_cost }}

            </q-td>
            <q-td key="lead_time" :style="{ backgroundColor: filterClr('lead_time') }"
                    v-if="visibleColumns.indexOf('lead_time') != -1">
              {{ props.row.lead_time }}

            </q-td>

            <q-td key="deviation" :style="{ backgroundColor: filterClr('deviation') }"
                    v-if="visibleColumns.indexOf('deviation') != -1">
              {{ props.row.deviation }}

            </q-td>
            <q-td key="price" :style="{ backgroundColor: filterClr('price') }"
                    v-if="visibleColumns.indexOf('price') != -1">
              {{ props.row.total_cost }}

            </q-td>
            <q-td key="supplier_stock" :style="{ backgroundColor: filterClr('supplier_stock') }"
                    v-if="visibleColumns.indexOf('supplier_stock') != -1">
              {{ props.row.total_cost }}

            </q-td>

            <q-td key="supplier_name" :style="{ backgroundColor: filterClr('supplier_name') }"
                    v-if="visibleColumns.indexOf('supplier_name') != -1">
              {{ props.row.supplier_name }}

            </q-td>

            <q-td key="source_name" :style="{ backgroundColor: filterClr('source_name') }"
                    v-if="visibleColumns.indexOf('source_name') != -1">
              {{ props.row.source_name }}

            </q-td>


            <q-td key="total_quantity" :style="{ backgroundColor: filterClr('total_quantity') }"
                    v-if="visibleColumns.indexOf('total_quantity') != -1">
              {{ props.row.total_quantity || '-' }}

            </q-td>

            <q-td key="excess_quantity" :style="{ backgroundColor: filterClr('excess_quantity') }"
                    v-if="visibleColumns.indexOf('excess_quantity') != -1">
              {{ props.row.excess_quantity || '-' }}

            </q-td>
            <q-td key="actions" :style="{ backgroundColor: filterClr('actions') }"
                    v-if="visibleColumns.indexOf('actions') != -1">
              <q-btn flat dense size="sm">
                <q-tooltip>Update Pricing</q-tooltip>
                <q-icon name="edit" color="negative"></q-icon>
              </q-btn>
              <q-btn flat dense size="sm">
                <q-tooltip>Audit Log</q-tooltip>
                <q-icon name="dashboard" color="info"></q-icon>
              </q-btn>
              <q-btn flat dense size="sm">
                <q-tooltip>Manual Override</q-tooltip>
                <q-icon name="description" color="positive"></q-icon>
              </q-btn>
              <q-btn flat dense size="sm">
                <q-tooltip>Email Supplier</q-tooltip>
                <q-icon name="mail" color="warning"></q-icon>
              </q-btn>

            </q-td>
          </q-tr>
        </template>
        <template v-slot:bottom-row>
          <q-tr>
            <q-td colspan="0">
              <div class="text-right">
                Total
              </div>
            </q-td>
            <q-td colspan="0">
              <div class="text-right">
                {{ totaltypeNo }}
              </div>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>

  </div>
</template>
 
  
<script>
import { SpinnerComponent } from "@/utils"
import { ref, computed } from 'vue';
import { QuotesService } from '@/services';
export default {
  name: 'StaticTable',
  components: {
    SpinnerComponent
  },
  setup() {
    const filter = ref([]);
    const visible = ref([100, 200, 300]);



    const customSort = (rows, sortBy, ascending) => {
      console.log("hvsjh");
      // Implement your custom sorting logic here
      return rows.sort((a, b) => {
        const aValue = a[sortBy];
        const bValue = b[sortBy];
        console.log("datsttst", aValue, bValue);

        // Check if the values are numeric
        const isNumeric = !isNaN(parseFloat(aValue)) && isFinite(aValue) &&
          !isNaN(parseFloat(bValue)) && isFinite(bValue);

        if (isNumeric) {
          // If numeric, subtract one value from the other for sorting
          return ascending ? bValue - aValue : aValue - bValue;
        } else {
          // If not numeric, use localeCompare
          return ascending ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
        }
      });
    };

    // filtered



    // const filteredRows = computed(() => {
    //   if (filter.value > 0) {
    //     alert("Filter")
    //     console.log("filter.value", filter.value)
    //     // console.log("dgfs",filter.value);
    //     // const data = filter.value
    //     // const [min, max] = data.split("-");
    //     // console.log("min",min,max);
    //     return QuotationRow.value.filter(row => row.required_quantity >= filter.value);
    //     // return rows.filter(row => row.typeNo >= min && row.typeNo <= max);
    //   } else {
    //     return QuotationRow;
    //   }
    // });


    // subtotal
    const totaltypeNo = computed(() => {
      return QuotationRow.value.reduce((sum, row) => sum + row.bom_quantity, 0);
    });

    const QuotationRow = ref([]);
    const search = ref("")
    return {
      visibleColumns: ref(['manufacturer', 'mpn', 'bom_quantity', 'required_quantity', 'stock_inventory', 'total_cost', 'lead_time', 'deviation', 'price', 'supplier_stock', 'supplier_name', 'source_name', 'total_quantity', 'excess_quantity', 'actions']),
      search,
      QuotationColumn: ref([]),
      QuotationRow,
      bom_name: ref('I1'),
      customSort,
      filter,
      loader: ref(true),
      visible,
      selectedRow: null,
      // filteredRows,
      totaltypeNo,
      QuotationColors: {
                bom: '#f0f9eb',
                supplier: '#fdeef4',
                computed: '#fff9e6',
            },
            clr: ref(''),

    }
  },
  computed: {
    //custom table header
    newquotescolm() {
      let datav = this.QuotationColumn.map(obj => {
        obj.color = this.QuotationColors[obj.datasource];
        obj.field = row => row.name;
        obj.format = val => `${val}`;
        obj.name = obj.key;
        obj.value = obj.key;
        obj.group = "starred"
        obj.sortable = true;

        return obj
      });

      return datav;
    },
    //custom filter
    filteredRows() {
      if (this.filter > 0) {
        alert("Filter")
        console.log("filter.value", this.filter)
        return this.QuotationRow.filter(row => row.required_quantity >= this.filter);
      } else {
        return this.QuotationRow;
      }
    }
  },
  methods: {
    filterDisp(keyvalue) {
            const output = this.visibleColumns.indexOf(keyvalue);
            var clr = "block"; console.log("filterDisp:", keyvalue, output);
            if (output >= 1) {
                clr = "none";
            }
            return clr;
        }, filterClr(keyvalue) {

            let output = this.QuotationColumn.filter((user) => {
                return user.key === keyvalue;
            });
            var clr = ""; console.log(keyvalue, output);
            if (output[0].datasource !== undefined) {
                clr = this.QuotationColors[output[0].datasource]
            }
            return clr;
        },
    onItemClick(data) {
      console.log("daataa", data);
      this.filter = data
      console.log("this.filter", this.filter);
    },
    getColumn() {
      QuotesService.getAllColumn().then((x) => {
        this.QuotationColumn = x.quotation_fields;

      });
    },
    selectRow(row) {
            // Unselect previously selected row
            if (this.selectedRow) {
                this.selectedRow.selected = false;
            }
            // Select the clicked row
            row.selected = true;
            this.selectedRow = row;
        },
    async getBomName() {
      if (this.bom_name != null) {

        await QuotesService.getQuotationRow(this.bom_name).then(x => {
          console.log("quotationHome", x)
          this.QuotationRow = x;
          this.loader = false;

        })
      }
    },

  },
  mounted() {
    this.getColumn();
    this.getBomName();

  },
}
</script> 
<style scoped>
.selected-row {
    background-color: lightblue;
    /* color: 'lightblue';
  border: '4px solid #2F6495'  */

}
</style>








 





  


  
  
  
  
  
  
  
  
  
 
  
  
  
  
 

  