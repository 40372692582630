<template>
  <div class="flex row">

    <div class="col-12 flex row justify-between">
      <q-table flat dense :rows="VendorList" :columns="Avlfields" virtual-scroll
                class="my-sticky-virtscroll-table" :virtual-scroll-sticky-size-start="3" v-model:pagination="pagination" selection="single" style="width:100%;height: 200px;">
        <template #header="props">
          <q-tr :props="props" class="bg-color text-center">
            <q-th>#</q-th>
            <q-th v-for="col in props.cols" :key="col.key" class="text-bold">
              {{ col.label }}
            </q-th>
          </q-tr>
        </template>
        <template #body="props">
          <q-tr :props="props">
            <q-td class="text-center">{{ props.rowIndex + 1 }}</q-td>
            <!-- <q-td key="is_primary" class="text-center">
              <q-checkbox dense v-model="props.row.is_primary" @click="updateCheckbox(props.row)" />
            </q-td> -->
            <q-td key="manufacturer" class="text-center">
              {{ props.row.manufacturer }}
            </q-td>
            <q-td key="mpn" class="text-center">
              {{ props.row.mpn }}
            </q-td>
            <q-td key="ipn" class="text-center">
              {{ props.row.ipn }}
            </q-td>
            <q-td key="description" class="text-center">
              {{ cleanupWords(description) }}...

              <q-tooltip anchor="bottom middle" self="top middle" :offset="[-5, -5]">
                {{ description }}
              </q-tooltip>
            </q-td>
            <!-- <q-td key="description" class="text-center">
              {{ cleanupWords(props.row.description) }}...
            </q-td> -->
            <!-- <q-td key="preview">
                   <img  src="/PNG.png"  /> 
                </q-td> -->



          </q-tr>
        </template>
      </q-table>
    </div>
    <!-- <div class="col-2 text-center	bg-hover">
      <div class=" text-dark bg-color" style=" height: 26px;">
        Preview
      </div>
      <div class="file-preview" style="margin-top: 10%;">

        <img src="/PNG.png" />



      </div>

    </div> -->
  </div>
</template>


<script>


import { ref, onMounted,watch } from "vue";
import { useMyStore } from '@/store';
import {commonFunction} from '@/utils'
// import { QuotesService } from "../../../services";


export default {
  name: "VendorTable",

  props: {
    VendorList: {
      type: Array
    },
    description: {
      type: String
    }

  },

  setup() {
    const pagination = ref({
            sortBy: 'desc',
            descending: false,
            page: 1,
            rowsPerPage: 5,
        });

        // Watch for changes in rowsPerPage and update local storage
        watch(() => pagination.value.rowsPerPage, (newRowsPerPage) => {
            localStorage.setItem('SubTablePage', newRowsPerPage);
        });

        // Load rowsPerPage from local storage on component mount
        const initializeData = () => {
            const storedRowsPerPage = localStorage.getItem('SubTablePage');
            if (storedRowsPerPage) {
                pagination.value.rowsPerPage = parseInt(storedRowsPerPage, 10);
            }
        };

        // Call the initialization method
        initializeData();
    const myStore = useMyStore();
    const Avlfields = ref([]);
    onMounted(async () => {
      // try {
      //   await myStore.loadPosts();
      //   Avlfields.value = myStore.fields.avl_fields.filter((field) => (field.key !== "index"));
      // } catch (error) {
      //   console.error('Error in component:', error);
      // }
    });
    return {
      pagination,
      myStore,
      Avlfields,
      selectedMpn: null,

    };
  },
  methods: {
    // getColumn() {
    //     QuotesService.getAllColumn().then((x) => {
    //     this.Avlfields = x.avl_fields.filter((field)=>(field.key!=="index"));

    //     });
    // },
    cleanupWords(description) {
      const words = commonFunction.descriptionFormat(description);
            return words
    },
    updateCheckbox(selectedBox) {
      try {
        this.selectedMpn = selectedBox.mpn;
        console.log("checked mpn is: " + this.selectedMpn);
        this.deselectCheckbox(selectedBox);
        this.$emit("selectedMpn", this.selectedMpn);
      } catch {
        console.log("Not Found");
      }
    },

    deselectCheckbox(clickedMpn) {

      this.VendorList.forEach(row => {
        if (row.bom_part_avl_id !== clickedMpn.bom_part_avl_id) {
          row.is_primary = false;
        } else {
          row.is_primary = true;
        }
      });
    },
  },
  mounted() {
    // this.getColumn();
    try {
         
        this.Avlfields = this.myStore.fields.avl_fields.filter((field) => (field.key !== "index"));
      } catch (error) {
        console.error('Error in component:', error);
      }
  },
};
</script>

<style lang="sass" >

.my-sticky-virtscroll-table
  /* height or max-height is important */
  height: 250px

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th /* bg color is important for th; just specify one */
    // background-color: #00b4ff

  thead tr th
    position: sticky
    z-index: 1
  /* this will be the loading indicator */
  thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
  thead tr:first-child th
    top: 0

  /* prevent scrolling behind sticky top row on focus */
  tbody
    /* height of all previous header rows */
    scroll-margin-top: 48px
</style>