<template>
  
    <q-list> 
      <q-list-section   class="filter-label">Unit</q-list-section>
      <q-toggle :disable="Enable==false" :model-value="(model=Values)" @update:model-value="model = $event" checked-icon="check" :true-value="Options && Options[1]" 
        :false-value="Options && Options[0]" size="sm" color="primary" unchecked-icon="check" default="Values" />
       <q-list-section  class="filter-label"> Total </q-list-section>
    </q-list>
   
</template>
  
<script>
import { ref } from "vue";

export default {
  name: "boolean-switch",
  props: ["Values", "Options", "booleanKey",'Enable'],
  setup(props) {
    const model = ref(props.Values);
    const labelKey = ref(props.booleanKey);
    return {
      model,
      labelKey
    };
  },
  watch: {
    model(newVal) {
      if (newVal && this.labelKey) {
        this.getChange(newVal, this.labelKey);
      }
    },
  },
  methods: {
    getChange(value, labelKey) {
      this.$emit("change-boolean-switch", [value, labelKey]);
    },
  }
};
</script>
  