<template>

    <q-card class="bg-transparent no-shadow no-border q-mr-md toaster" bordered>
        <q-card-section class="q-pa-none">

            <div class="row q-col-gutter-sm ">
                <div class="col-md-2 col-sm-12 col-xs-12 toaster">
                    <q-item class="q-pa-none  tarea flat ">
                        <q-item-section class=" q-pa-xs q-ml-none  text-right">
                            <q-item-label class="  text-h6 text-weight-bolder">{{ files }}</q-item-label>
                            <q-item-label class="text-bottom">No of Files</q-item-label>
                        </q-item-section>
                    </q-item>
                </div>

                <div class="col-md-2 col-sm-12 col-xs-12 toaster">
                    <q-item class="q-pa-none  tarea flat ">
                        <q-item-section class=" q-pa-xs q-ml-none  text-right">
                            <q-item-label class=" text-h6 text-weight-bolder">{{ bom!=null?$filters.ToasterDate(lastuploaddate):'-'
                                }}</q-item-label>
                            <q-item-label class="text-bottom">Last updated date</q-item-label>
                        </q-item-section>
                    </q-item>
                </div>
                <div class="col-md-2 col-sm-12 col-xs-12 toaster">
                    <q-item class="q-pa-none tarea tarea-success   flat ">
                        <q-item-section class=" q-pa-xs q-ml-none  text-right">
                            <q-item-label class=" text-h6 text-weight-bolder">{{ totaldata }}</q-item-label>
                            <q-item-label class=" text-bottom">{{ FileSuffix }} Used</q-item-label>
                        </q-item-section>
                    </q-item>
                </div>

                <div class="col-md-2 col-sm-12 col-xs-12 toaster">
                    <q-item class="q-pa-none  tarea tarea-danger flat ">
                        <q-item-section class=" q-pa-xs q-ml-none  text-right">
                            <q-item-label class=" text-h6 text-weight-bolder">{{ errboms }}</q-item-label>
                            <q-item-label class=" text-bottom">Issues Found</q-item-label>
                        </q-item-section>
                    </q-item>
                </div>
            </div>

        </q-card-section>
    </q-card>
</template>
<script>
import { ref } from 'vue';

export default {
    props: ['type', "files", "lastuploaddate", "totaldata", "FileSuffix","errboms"],
    setup() {
        return {
            bom: ref(localStorage.getItem('bom_name'))
        }
    }
}

</script>