<template>
  <div v-if="type=='common'">
  <q-circular-progress
      indeterminate
      size="25px"
      :thickness="0.2"
      color="blue"
      track-color="grey-11"
      class="q-ma-md "

    />
  </div> 
  <div v-if="type=='summary'" >
        <q-item>
        <q-item-section avatar>
          <q-skeleton type="QAvatar" animation="wave"/>
        </q-item-section>

        <q-item-section>
          <q-item-label>
            <q-skeleton type="text" animation="wave"/>
          </q-item-label>
          <q-item-label caption>
            <q-skeleton type="text" width="80%" animation="wave" />
          </q-item-label>
        </q-item-section>
      </q-item>

  </div>
  <div v-if="type=='image'">
    <q-skeleton animation="blink" type="circle" />
  </div>
  <div v-if="type=='dropdown'">
  <q-circular-progress
      indeterminate
      size="25px"
      :thickness="0.5"
      color="secondary"
      track-color="grey-11"
      class="q-ma-md"
    />
  </div> 

    <div v-if="type=='Table'">
  <q-markup-table>
    <thead>
      <tr>
        <th v-for="n in 10" :key="n" class="text-left" style="width: 100%">
          <q-skeleton animation="blink" type="text" />
        </th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="n in 9" :key="n">
        <td class="text-left" v-for="n in 10" :key="n">
          <q-skeleton animation="blink" type="text" width="85px" />
        </td>
     
      </tr>
    </tbody>
  </q-markup-table>
</div>

  <div v-if= "type =='Tree'" class="q-pa-md">
    <q-item v-for="n in 3" :key="n" style="max-width: 300px">
      <q-item-section avatar>
        <q-skeleton type="QAvatar" />
      </q-item-section>

      <q-item-section>
        <q-item-label>
          <q-skeleton type="text" />
        </q-item-label>
        <q-item-label caption>
          <q-skeleton type="text" width="65%" />
        </q-item-label>
      </q-item-section>
     
    </q-item>
  </div>
  <div v-if= "type =='PopupFields'" class="q-pa-md">
    <q-item v-for="n in 6" :key="n" style="max-width: 300px">
      <q-item-section>
        <q-item-label>
          <q-skeleton type="text" animation="wave" />
        </q-item-label>
        <q-item-label caption>
          <q-skeleton type="text" width="65%" animation="wave"/>
        </q-item-label>
      </q-item-section>
     
    </q-item>

   
  </div>
  <div v-if= "type =='EmailDraft'" class="q-pa-md">
    <q-item v-for="n in 9" :key="n" style="max-width: 300px">
      <q-item-section>
        <q-item-label>
          <q-skeleton type="text" animation="wave" />
        </q-item-label>
        <q-item-label caption>
          <q-skeleton type="text" width="65%" animation="wave"/>
        </q-item-label>
      </q-item-section>
     
    </q-item>

   
  </div>
</template>
<script>
export default {
  props: ["type"],
  setup() {
    return {

    }
  }
}
</script>