import { fetchWrapper } from '@/helpers/fetch-wrapper';
import { API_BASE_URL,LoginKey } from '@/api.config';
import axios from 'axios'
export const QuotesService = {
    getAllColumn,
    getQuotationRow,
    getSourceRow,
    SubTab,
    getPriceBreak,
    getBomExtract,
    getFieldData,
    getMpnSuggest,
    getVendorList,
    getFieldUpdate,
    updateAlterSupplier,
    UpdateVendor,
    updateAltenateMpn,
    updatePartsQuantity,
    updateBomname,
    getRfqUiSchema,
    getRfqSchema,
    getRfqDetails,
    updateRfq,
    getToaster,
    getQuotesSummary,
    getLifeCycle,
    UpdateLifeCycle,
    getpartsPreview,
    getLogin,
    updatePricingBom,
    updatePricingRow,
    exportQuote,
    safeDraft,
    Revert,
    getExportQuote,
    // Auto suggestion
    getSimilarManufacturers,
    getSimilarMpn,
    getSimilarSuppliers,
    // Manual Update
    ManualUpdate,
    getSupGuest,
    UpdatePriceGroup,

};

// const supersetURL = 'http://vuelogix.sca.humalitix.com:8088';
const apiUrl = `${API_BASE_URL}`;
// const Baseurl='/api';


//All table header fields
function getAllColumn() {
    return fetchWrapper.get('api/fields.json')
}
//partsPreview
function getpartsPreview(field, value) {
    let encodeValue = encodeURIComponent(encodeURIComponent(value));
    return fetchWrapper.get(`${apiUrl}/sourcing/partspreview/${field}/${encodeValue}`)
}
//export Quote
function exportQuote(bom_name) {
    return fetchWrapper.get(`${apiUrl}/quoting/${bom_name}/export`)
}
//safeDraft
function safeDraft(bom_name) {
    return fetchWrapper.get(`${apiUrl}/quoting/${bom_name}/savedraftquote`)
}
//Update Pricing
//Bom Level
function updatePricingBom(bom_name) {
    return fetchWrapper.post(`${apiUrl}/sourcing/trigger/${bom_name}`)
}

//Row level
function updatePricingRow(bom_name, ipn) {
    let newipn = encodeURIComponent(encodeURIComponent(ipn));
    return fetchWrapper.post(`${apiUrl}/sourcing/trigger/${bom_name}/part/${newipn}`)
}
function UpdatePriceGroup(bom_name, group_name,group_value,body) {
    return fetchWrapper.post(`${apiUrl}/sourcing/trigger/${bom_name}/${group_name}/${group_value}`,body)
}
// Quatation
function getQuotationRow(bom_name, qty) {
    if (!qty) {
        // return fetchWrapper.get(`${apiUrl}/quoting/${bom_name}`)
        return fetchWrapper.get(`${apiUrl}/sourcing/${bom_name}`)
    } else {
        return fetchWrapper.get(`${apiUrl}/sourcing/${bom_name}/${qty}`)
    }
}

//LifeCycleStatus
function getLifeCycle(iteration_name) {
    return fetchWrapper.get(`${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/lifecycle/status/`)
}
function UpdateLifeCycle(iteration_name, status) {
    return fetchWrapper.put(`${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/lifecycle/status/${status}`)
}
// Sourcing


function getPriceBreak(bom_name, mpn, spn, manufacturer) {
    let newMpn = encodeURIComponent(encodeURIComponent(mpn));
    let newManufacturer = encodeURIComponent(encodeURIComponent(manufacturer))
    let newSpn = encodeURIComponent(encodeURIComponent(spn));
    return fetchWrapper.get(`${apiUrl}/sourcing/${bom_name}/apb/${newMpn}/${newSpn}/${newManufacturer}`)
}
function getSourceRow(bom_name) {
    return fetchWrapper.get(`${apiUrl}/sourcing/${bom_name}`)
}
//Available supplier list
// function SubTab(bom_name, ipn, quantity) {
//     return fetchWrapper.get(`${apiUrl}/sourcing/${bom_name}/asl/${ipn}/${quantity}`)
// }
function SubTab(bom_name, ipn, quantity) {
    let newipn = encodeURIComponent(encodeURIComponent(ipn));
    return fetchWrapper.get(`${apiUrl}/sourcing/${bom_name}/asl/${newipn}/${quantity}`)
}
function updateAlterSupplier(bom_name, ipn, quantity, body) {
    let newipn = encodeURIComponent(encodeURIComponent(ipn));
    return fetchWrapper.put(`${apiUrl}/sourcing/${bom_name}/asl/${newipn}/${quantity}`, body)
}
//Quotes toaster
function getToaster(bom_name) {
    return fetchWrapper.get(`${apiUrl}/sourcing-summary/${bom_name}`)
}
//Quotes Summary

function getQuotesSummary(bom_name) {
    return fetchWrapper.get(`${apiUrl}/quoting-summary/${bom_name}`)
}


//Bom Extract
function getBomExtract(bom_name, lazy, qty) {
    if (!qty) {
        return fetchWrapper.get(`${apiUrl}/oem/${bom_name}/extract/${lazy}`)
    } else {
        return fetchWrapper.get(`${apiUrl}/oem/${bom_name}/extract/${lazy}/${qty}`)
    }
}
//Rfq Bom

function getRfqDetails(bom_name) {
    return fetchWrapper.get(`${apiUrl}/oem/{customer_name}/product/{product_name}/bom/${bom_name}`)
    // return fetchWrapper.get('data/rfq.json')
}
function updateRfq(bom_name, body) {
    return fetchWrapper.put(`${apiUrl}/oem/{customer_name}/product/{product_name}/bom/${bom_name}`, body)
}


function updateBomname(bom_name) {
    return fetchWrapper.put(`${apiUrl}/bom/${bom_name}`)
}
// Form field data
function getFieldData(bom_name) {
    return fetchWrapper.get(`${apiUrl}/bom/${bom_name}`)
}
function getFieldUpdate(bom_name, body) {
    return fetchWrapper.put(`${apiUrl}/bom/${bom_name}`, body)
}
//Revert Auto
function Revert(iteration_name, ipn) {
    let encodeIpn = encodeURIComponent(encodeURIComponent(ipn));
    return fetchWrapper.put(`${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/settings/${encodeIpn}/reset_user_recommendation`)
}

//Alternate Mpn suggestions
function getMpnSuggest(field, value) {
    return fetchWrapper.get(`${apiUrl}/part/similarity/${field}/${value}`)
}
function updateAltenateMpn(bom_name, ipn, body) {
    let encodeIpn = encodeURIComponent(encodeURIComponent(ipn));
    return fetchWrapper.put(`${apiUrl}/bom/${bom_name}/${encodeIpn}/avl`, body)
}
function updatePartsQuantity(bom_name, ipn, body) {
    let encodeIpn = encodeURIComponent(encodeURIComponent(ipn));
    return fetchWrapper.put(`${apiUrl}/bom/${bom_name}/${encodeIpn}/avl`, body)
}
//Available Vendor list
function getVendorList(bom_name, ipn, lazy) {
    let encodeIpn = encodeURIComponent(encodeURIComponent(ipn));
    return fetchWrapper.get(`${apiUrl}/oem/${bom_name}/${encodeIpn}/avl/${lazy}`)
}
function UpdateVendor(bom_name, ipn, body) {
    let encodeIpn = encodeURIComponent(encodeURIComponent(ipn));
    return fetchWrapper.put(`${apiUrl}/oem/${bom_name}/${encodeIpn}/avl`, body)
}
// Manufacturer Auto suggestions
function getSimilarManufacturers(manufacturer_name) {
    let encodeManufacturer = encodeURIComponent(encodeURIComponent(manufacturer_name));
    return fetchWrapper.get(`${apiUrl}/part/manufacturer/${encodeManufacturer}`)
}
// Mpn with Manufacturer Auto suggestion
function getSimilarMpn(manufacturer_name, mpn) {
    let encodeManufacturer = encodeURIComponent(encodeURIComponent(manufacturer_name));
    let encodeMpn = encodeURIComponent(encodeURIComponent(mpn));
    return fetchWrapper.get(`${apiUrl}/part/manufacturer/${encodeManufacturer}/mpn/${encodeMpn}`)
}
// Supplier Auto Suggestion
function getSimilarSuppliers(supplier_name) {
    let encodeSupplier = encodeURIComponent(encodeURIComponent(supplier_name));
    return fetchWrapper.get(`${apiUrl}/supplier/search/${encodeSupplier}`)
}
// Manual Update 
function ManualUpdate(bom_name, ipn, qty, body) {
    let encodeIpn = encodeURIComponent(encodeURIComponent(ipn));
    return fetchWrapper.post(`${apiUrl}/sourcing/manual/update/${bom_name}/${encodeIpn}/${qty}`, body)
}
// Export Quote
async function getExportQuote(ipn) {
    const bom = localStorage.getItem('bom_name') || "";
    const cmuser2 = JSON.parse(localStorage.getItem('cmuser')) || { "access_token": null };
    let encodeIpn = encodeURIComponent(encodeURIComponent(ipn));
    // return fetchWrapper.get(`${apiUrl}api/quoting/${encodeIpn}/export`)

    try {
        const response = await axios.get(`${apiUrl}/quoting/${encodeIpn}/export`, {
            responseType: 'arraybuffer',
            headers: {
                "Accept": "application/json", 'X-API-Key': cmuser2.access_token,
                'Content-Type': 'application/json',
                'responseType': 'arraybuffer'

            }
        });

        // Create a Blob from the array buffer
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        // Create a link element and trigger a download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Quote_Export_${bom}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading Excel file:', error);
    }
}

async function getSupGuest(id,bom_name) {
    var tok= await fetchWrapper.post(`${apiUrl}/user/dashboard/${id}/${bom_name}`)
    return tok
}




async function getLogin(username, passowrd) {
    let data = JSON.stringify({
        "username": username,
        "password": passowrd
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${apiUrl}/user/login`,
        headers: {
            'X-API-Key': `${LoginKey}`,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return (axios.request(config));



}


//json Forms - RFQ
function getRfqUiSchema() {
    return fetchWrapper.get('data/rfqJFormUiSchema.json')
}
function getRfqSchema() {
    return fetchWrapper.get('data/rfqJFormSchema.json')
}
