import { fetchWrapper } from '@/helpers';
import { API_BASE_URL } from '@/api.config';
import axios from 'axios'

const apiUrl2 = `${API_BASE_URL}`;


export const AlterPartService = {
    getAlterparts,
    addAslparts,
    getalterSubParts,
    exportParts,
    alterPriceBreak
};

// part alternatives 
function getAlterparts(bom_name,ipn,source_type,is_exact,part_status) {
    let newipn = encodeURIComponent(encodeURIComponent(ipn));
    console.log(part_status)
    return fetchWrapper.get(`${apiUrl2}/sourcing/alternative/parts/${bom_name}/${newipn}?is_exact=${is_exact}`)
}
function addAslparts(bom_name,ipn,body) {
    let newipn = encodeURIComponent(encodeURIComponent(ipn));
    return fetchWrapper.post(`${apiUrl2}/sourcing/alternative/parts/${bom_name}/${newipn}`,body)
}

// Alternate parts price break 
function alterPriceBreak(supplier_name,spn) {
    // let newspn = encodeURIComponent(encodeURIComponent(spn))
    return fetchWrapper.get(`${apiUrl2}/sourcing/alternative/parts/pricebreak/${supplier_name}/${spn}`)
}
// Alternate parts Options
function getalterSubParts(mpn) {
    let newmpn = encodeURIComponent(encodeURIComponent(mpn));
    return fetchWrapper.get(`${apiUrl2}/sourcing/alternative/parts/${newmpn}`)
}
// export alternative parts
async function exportParts(bom_name,ipn) {
    let newipn = encodeURIComponent(encodeURIComponent(ipn));
    const cmuser2 = JSON.parse(localStorage.getItem('cmuser')) || { "access_token": null };
    try {
        const response = await axios.get(`${apiUrl2}/sourcing/alternative/parts/${bom_name}/${newipn}/export`, {
            responseType: 'arraybuffer',
            headers: {
                "Accept": "application/json", 'X-API-Key': cmuser2.access_token,
                'Content-Type': 'application/json',
                'responseType': 'arraybuffer'

            }
        });

        // Create a Blob from the array buffer
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        // Create a link element and trigger a download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Part Alternatives.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading Excel file:', error);
    }
}
