<template>
  <!-- Tree Card Actions -->
  <q-card :class="{ ' tree-popup  ': type == 'popup' }">
    <q-card-actions class="Treefilterhead tree-label q-pa-xs" v-if="type !== 'popup' && type !== 'Sample'">
      <span align="left" class=" q-ml-sm">Document Explorer </span>
      <q-space />
      <q-input v-model="filter" ref="filterRef" placeholder="Search" class="  treeSearch" outlined dense
        v-if="type !== 'popup' && isSearchOpen">

        <template v-slot:append>
          <q-icon name="clear" color="grey" class="cursor-pointer" @click="resetFilter" />
        </template>
      </q-input>

      <q-btn v-if="isSearchOpen == false" flat  icon="fas fa-search" size="13px" @click="toggleSearch"
        class="cursor-pointer q-mr-xs" color="primary" :disable="bomName == null"></q-btn>
      <q-btn dense flat class="sortButtons q-pa-none" @click="handleSort()" :disable="bomName == null">
        <q-icon :class="{ 'sortingDefault': sortDirection === null }"
          :name="sortDirection === null ? 'fas fa-right-left' : (sortDirection === 'asc' ? 'fas fa-sort-alpha-down' : 'fas fa-arrow-up-z-a')"
          :color="sortDirection === 'asc' || sortDirection === 'desc' ? 'secondary' : 'primary'" size="16px">
          <q-tooltip v-if="sortDirection === 'asc'">Ascending</q-tooltip>
          <q-tooltip v-if="sortDirection === 'desc'">Descending</q-tooltip>
          <q-tooltip v-if="sortDirection === null">Sort by Last Updated</q-tooltip>
        </q-icon>
      </q-btn>
      <q-btn flat dense size="15px" icon="fa-solid fa-filter" :disable="bomName == null"
        :color="treeFilter !== 'All' && treeFilter !== 'string' ? 'secondary' : 'primary'" class="treeFilter ">
        <q-menu>
          <q-list dense v-for="Labelkey, index in TreeFilterJson" :key="index"
            :class="Labelkey.is_primary ? 'selectedFilterItem' : 'NonSelectedItem'">
            <q-item clickable v-close-popup @click="onItemClick(Labelkey.value)" class="q-pa-none">
              <q-item-section>
                <q-item-label>
                  <span class="q-mr-xs">
                    <q-btn rounded dense class="TreeFilterSpinner" style="font-size: 7px !important;" filled
                      v-if="Labelkey.value == 'Progress'">
                      <q-spinner-dots size="1em" color="white" style="font-size: 11px !important;">
                      </q-spinner-dots>
                    </q-btn>
                    <q-btn rounded dense class="TreeFilterSpinner" style="font-size: 7px !important;" filled
                      v-else-if="Labelkey.value == 'nosourcing'">
                      <q-icon size="1em" color="white" name="more_horiz" style="font-size: 11px !important;">
                      </q-icon>
                    </q-btn>
                    <q-icon v-else :name="Labelkey.icon" size="xs" style="font-size: 14px !important;"
                      :color="Labelkey.color"></q-icon>
                  </span>
                  {{ Labelkey.label }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
        <q-tooltip anchor="bottom middle" self="top middle" :offset="[6, 6]">Filter Based on Quote Status
        </q-tooltip>
      </q-btn>

    </q-card-actions>
    <!-- Tree -->
    <q-card-section class="q-pt-none tree-section">
      <spinner-component v-if="loader" type="Tree"></spinner-component>
      <!-- <q-scroll-area :delay="1200" style="height: 370px; max-width: 100%;"> -->
      <q-tree v-if="!loader && sortedNodes" :nodes="sortedNodes" node-key="name" accordion label-key="name"
        selected-color="secondary" class="selectedBOM" id="No-data" v-model:expanded="expanded" :filter="filter"
        ref="menuTree">

        <template v-slot:default-header="props">
          <div class=" flex col-12 row items-center">
            <div class=" col-12 flex justify-between  "
              :class="{ 'selected-rowTree rounded-borders': props.node.name == selected && props.node.type == 'iteration' }">
              <!-- Before Node Icons -->
              <div class="row items-center " :class="{ ' q-pa-xs   q-pl-xs': type == 'upload' }">
                <q-icon v-if="props.node.type == 'oem' && props.node.length"
                  :name="props.node.expandIcon == true ? 'fa-solid fa-caret-down' : 'fa-solid fa-caret-right'"
                  color='grey' size="xs"
                  :class="{ ' cursor text-active': props.node.name == selected, ' cursor  ': props.node.name !== selected, }"
                  @click="getBomName(props.node.name, props.node.type)"></q-icon>
                <q-icon v-if="props.node.type == 'product' && props.node.length"
                  :name="props.node.expandIcon == true ? 'fa-solid fa-caret-down' : 'fa-solid fa-caret-right'"
                  color='grey' size="xs"
                  :class="{ ' cursor text-active': props.node.name == selected, ' cursor  ': props.node.name !== selected, }"
                  @click="getBomName(props.node.name, props.node.type)"></q-icon>
                <q-icon v-if="props.node.type == 'iteration' && props.node.length" name="fa-solid fa-caret-down"
                  :color="props.node.name == selected ? 'secondary' : 'grey'" size="xs"
                  :class="{ ' cursor text-active': props.node.name == selected, ' cursor  ': props.node.name !== selected, }"
                  @click="getBomName(props.node.name, props.node.type)"></q-icon>
                <q-btn v-if="props.node.type == 'iteration' && (type == 'upload' || type == 'quotes') &&
                  props.node.lifecycle_status == 'Quoted' && props.node.order_status === 'Processing'" rounded
                  class="TreeStatusButtons" flat dense color="teal" icon="fas fa-clock" size="xs"
                  @click="getBomName(props.node.name, props.node.type)">
                  <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]">Quote Dispatched, awaiting order
                    confirmation</q-tooltip>
                </q-btn>
                <q-btn v-if="props.node.type == 'iteration' && (type == 'upload' || type == 'quotes') &&
                  props.node.lifecycle_status === 'Quoted' && props.node.order_status === 'Lost'"
                  @click="getBomName(props.node.name, props.node.type)" dense rounded class="TreeStatusButtons" flat>
                  <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]">Quote Lost </q-tooltip>
                  <q-icon color="red-3" name="fa-solid fa-circle-xmark" size="xs">
                  </q-icon>
                </q-btn>
                <q-btn v-if="props.node.type == 'iteration' && (type == 'upload' || type == 'quotes') &&
                  props.node.lifecycle_status == 'Quoted' && props.node.order_status === 'Won'"
                  @click="getBomName(props.node.name, props.node.type)" dense rounded class="TreeStatusButtons">
                  <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]">Quote Won</q-tooltip>
                  <q-icon color="light-green" name="fa-solid fa-circle-check" size="xs">
                  </q-icon>
                </q-btn>
                <q-btn
                  v-if="props.node.type == 'iteration' && (type == 'upload' || type == 'quotes') &&
                    props.node.lifecycle_status != 'Quoted' && props.node.lifecycle_status != 'In Sourcing' && props.node.lifecycle_status != 'In Quoting'"
                  @click="getBomName(props.node.name, props.node.type)" dense rounded class="TreeSpinnerButtons">
                  <q-icon color="white" class="TreeSpinner" name="more_horiz" size="14px">
                  </q-icon>
                  <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]">Not yet Sourcing</q-tooltip>
                </q-btn>
                <q-btn rounded dense class="TreeSpinnerButtons" color="orange-4" filled
                  @click="getBomName(props.node.name, props.node.type)"
                  v-if="props.node.type == 'iteration' && (type == 'upload' || type == 'quotes') && (props.node.lifecycle_status == 'In Sourcing' || props.node.lifecycle_status == 'In Quoting')">
                  <q-spinner-dots size="1em" color="white" class="TreeSpinner">
                  </q-spinner-dots>
                  <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]">Sourcing,Quoting In
                    Progress</q-tooltip>
                </q-btn>

                <q-icon :name="props.node.icon || 'share'" size="19px"
                  :color="props.node.name === selected ? 'secondary' : 'primary'"
                  :class="{ 'q-mr-sm cursor text-active': props.node.name == selected, 'q-mr-sm cursor  ': props.node.name !== selected, 'display-none  ': (props.node.type == 'iteration' && (type == 'upload' || type == 'quotes')), }"
                  @click="getBomName(props.node.name, props.node.type)" />


                <a :class="{ 'q-ml-sm text-secondary cursor': props.node.name == selected, 'text-primary cursor': props.node.name !== selected, 'q-ml-sm': props.node.type === 'iteration' }"
                  @click="getBomName(props.node.name, props.node.type)">{{
                    props.node.name }}
                      <q-tooltip v-if="props.node.type === 'iteration'" anchor="center right" self="center left"
                  :offset="[-2, -2]">{{ props.node.lifecycle_status }}</q-tooltip>
                </a>
              

              </div>
              <!-- Action Buttons -->
              <div v-if="type == 'upload' && props.node.name == this.selected" class="q-mr-md">
                <!-- selected All -->
                <q-btn dense flat @click="openPopup(props.node.type, 'edit')" class="q-mr-xs" color="primary"
                  icon="fas fa-edit">
                  <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]">Edit</q-tooltip>
                </q-btn>
                <q-btn dense flat size="xs" @click="deleteNode([props.node.type, props.node.lifecycle_status])"
                  class="q-mr-xs" color="negative" icon="delete">
                  <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]">Delete</q-tooltip>
                </q-btn>
                <!-- Selected Iteration -->
                <q-btn v-if="props.node.name == this.selected && props.node.type == 'iteration'" dense flat
                  @click="copyIteration('copy', props.node.name)" class="q-mr-xs" color="grey"
                  icon="fa-regular fa-copy">
                  <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]">Make a Copy</q-tooltip>
                </q-btn>
                <q-btn
                  v-if="props.node.name == this.selected && props.node.type == 'iteration' || props.node.type === 'product'"
                  dense flat @click="openPopup(props.node.type, 'move', props.node.name)" class="q-mr-xs"
                  icon="fa fa-circle-arrow-right" color="secondary">
                  <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]">Move {{ props.node.type ===
                    'product' ? this.myStore.treeLabels[1] : this.myStore.treeLabels[2] }}</q-tooltip>
                </q-btn>
              </div>
            </div>
          </div>
        </template>


      </q-tree>
      <!-- </q-scroll-area> -->



    </q-card-section>
  </q-card>
  <!-- Confirm Alert -->
  <q-dialog v-model="dialog" persistent>

    <div
      :class="{ 'popupcomponent  gdialog': commonpopupType === 'edit', 'movepopup q-gutter-lg gdialog': commonpopupType === 'move' }">

      <PopupComponent v-if="commonpopupType === 'edit'" popupType="edit" :editNode="selected" :node="bomType[0]"
        :close="closeDialog" @updated="updated" />
      <MoveRfqPopup v-if="commonpopupType === 'move'" :close="closeDialog" :bomType="bomType"
        :currentoem="currentoem" />
      <CopyRfq v-if="commonpopupType === 'copy'" @copyButton="copyButton" />
    </div>
  </q-dialog>
  <q-dialog v-model="confirm">
    <q-card class="gdialog card-outline-primary  confirm-popup ">

      <q-card-section class="card-header-section1">
        <div v-if="citeration" class="text-h6 q-ml-sm"><q-icon flat dense name="report_problem" class="q-mb-xs"
            color="warning"></q-icon><span class="q-ml-sm">Copy of RFQ </span></div>
        <div v-if="!citeration && bomType[1] === 'New'" class="text-h6 q-ml-sm"><q-icon flat dense name="report_problem"
            color="warning" class="q-mb-xs"></q-icon><span class="q-ml-sm">Delete RFQ </span> </div>
        <div v-if="!citeration && bomType[1] !== 'New'" class="text-h6 q-ml-sm"><q-icon name="report_problem" flat dense
            color="warning" size="25px" class="q-mb-xs"></q-icon><span class="q-ml-sm ">Force Delete {{ bomType[0]
              == 'oem' ? 'Customer' : (bomType[0] == 'product' ? 'Assembly' : 'RFQ') }} </span></div>
      </q-card-section>

      <q-card-section class="">

        <span v-if="citeration" class="">Make a copy of all the documents in RFQ {{ selected }} ?</span>
        <span v-if="!citeration && bomType[1] === 'New'" class="">Are you sure want to delete this {{ selected }}
          ?</span>
        <span v-if="!citeration && bomType[1] !== 'New'" class="">Are you sure want Force to delete this {{
          selected
        }} ?</span>
      </q-card-section>
      <q-card-actions align="right" class="card-footer-section">

        <q-btn label="Ok" outline color="secondary" @click="handleConfirm" />
        <q-btn label="Cancel" outline color="secondary" v-close-popup />
      </q-card-actions>
    </q-card>

  </q-dialog>
</template>

<script>
import { ref } from "vue";
import { useRoute } from 'vue-router'
import { SpinnerComponent, commonFunction } from '../utils'
import { AlertService, SettingService, UploadService } from "../services";
import PopupComponent from "./PopupComponent.vue";
import CopyRfq from "./CopyRfq.vue";
import MoveRfqPopup from './MoveRfqPopup'
import { useMyStore } from '@/store';
export default {
  // props: ['type',"oem"],
  props: {
    type: {
      type: String
    },

    newCreatedData: {
      type: String
    }
  },
  components: {
    SpinnerComponent,
    PopupComponent,
    MoveRfqPopup,
    CopyRfq

  },
  setup() {
    const filter = ref('')
    const filterRef = ref(null);
    const myStore = useMyStore();
    const route = useRoute()
    return {
      route,
      myStore,
      treeNode: ref([]),
      TreeFilterJson: ref([]),
      selected: ref(""),
      expanded: ref([]),
      loader: ref(true),
      dialog: ref(false),
      citeration: ref(false),
      treeFilter: ref('string'),
      sortDirection: ref(null),
      filter,
      filterRef,
      isSearchOpen: ref(false),
      confirm: ref(false),
      newUpdatedData: ref(''),
      bomType: ref([]),
      commonpopupType: ref(''),
      bomName: ref(localStorage.getItem('bom_name'))
    };
  },
  watch: {
    newNodetrigger() {
      this.getTree();
    },
    updateNodeTrigger() {
      this.getTree();
    },
    // Status changed call tree 
    refreshpricing() {
      this.getTree();
    },
    GlobalQty(new1, old1) {
      if (new1 != old1 && new1 !== null) {
        localStorage.setItem("qty", new1)
      }
    },
    selected(new1, old1) {
      if (new1 != old1 && new1 !== null) {
        this.myStore.copyerrmsg = ''
      }
    },
  },
  computed: {
    // Status changed - icon changing
    refreshpricing() {
      return this.myStore.refreshpricing;
    },
    // Set primary qty when iteration changed
    GlobalQty() {
      return this.myStore.GlobalQty;
    },
    newtreeNode() {
      //oem
      let dataoem = this.treeNode.map((obj, index) => {
        // push if child and expand
        if (obj.type == "oem" && obj.children.length > 0 && this.expanded.includes(obj.name)) {
          obj.icon = "factory"; obj.color = ""; obj.uniqueId = obj.name + index; obj.length = true; obj.expandIcon = true
        }
        // if it is not expand
        else if (obj.type == "oem" && obj.children.length > 0 && !this.expanded.includes(obj.name)) {
          obj.icon = "factory"; obj.color = ""; obj.uniqueId = obj.name + index; obj.length = true; obj.expandIcon = false
        }
        // default
        obj.icon = "factory"; obj.color = ""; obj.uniqueId = obj.name + index;
        //product
        obj.children.map((obj, index) => {
          // push if child and expand
          if (obj.type == "product" && obj.children.length > 0 && this.expanded.includes(obj.name)) {
            obj.icon = "book"; obj.length = true; obj.expandIcon = true; obj.color = ""; obj.uniqueId = obj.name + index + 1
          }
          // if it is not expand
          else if (obj.type == "product" && obj.children.length > 0 && !this.expanded.includes(obj.name)) {
            obj.icon = "book"; obj.length = true; obj.expandIcon = false; obj.color = ""; obj.uniqueId = obj.name + index + 1
          }
          // default
          obj.icon = "book"; obj.color = ""; obj.uniqueId = obj.name + index + 1


          //iteration
          obj.children.map((obj, index) => {
            obj.icon = "login"; obj.color = ""; obj.type = "iteration"; obj.uniqueId = obj.name + index + 2
            return obj
          });
          return obj
        });
        return obj
      });

      return dataoem;
    },
    sortedNodes() {
      if (this.treeFilter == 'lost') {
        const resultNodes = this.filterData(this.newtreeNode);
        resultNodes.forEach(this.includeAllChildren);
        const sorted = resultNodes;
        sorted.sort((a, b) => {
          const labelA = a.name.toLowerCase();
          const labelB = b.name.toLowerCase();
          if (this.sortDirection === "asc") {
            return labelA.localeCompare(labelB);
          } else {
            return labelB.localeCompare(labelA);
          }
        });
        // return resultNodes
        return sorted
      } else if (this.treeFilter == 'won') {
        const resultNodes = this.filterData(this.newtreeNode);
        resultNodes.forEach(this.includeAllChildren);
        const sorted = resultNodes;
        sorted.sort((a, b) => {
          const labelA = a.name.toLowerCase();
          const labelB = b.name.toLowerCase();
          if (this.sortDirection === "asc") {
            return labelA.localeCompare(labelB);
          } else {
            return labelB.localeCompare(labelA);
          }
        });
        // return resultNodes
        return sorted
      }
      else if (this.treeFilter == 'await') {
        const resultNodes = this.filterData(this.newtreeNode);
        resultNodes.forEach(this.includeAllChildren);
        const sorted = resultNodes;
        sorted.sort((a, b) => {
          const labelA = a.name.toLowerCase();
          const labelB = b.name.toLowerCase();
          if (this.sortDirection === "asc") {
            return labelA.localeCompare(labelB);
          } else {
            return labelB.localeCompare(labelA);
          }
        });
        // return resultNodes
        return sorted
      }
      else if (this.treeFilter == 'Progress') {
        const resultNodes = this.filterData(this.newtreeNode);
        resultNodes.forEach(this.includeAllChildren);
        const sorted = resultNodes;
        sorted.sort((a, b) => {
          const labelA = a.name.toLowerCase();
          const labelB = b.name.toLowerCase();
          if (this.sortDirection === "asc") {
            return labelA.localeCompare(labelB);
          } else {
            return labelB.localeCompare(labelA);
          }
        });

        return sorted;
        // return resultNodes
      } else if (this.treeFilter == 'nosourcing') {
        const resultNodes = this.filterData(this.newtreeNode);
        resultNodes.forEach(this.includeAllChildren);
        const sorted = resultNodes;
        sorted.sort((a, b) => {
          const labelA = a.name.toLowerCase();
          const labelB = b.name.toLowerCase();
          if (this.sortDirection === "asc") {
            return labelA.localeCompare(labelB);
          } else {
            return labelB.localeCompare(labelA);
          }
        });

        return sorted;
        // return resultNodes
      } else {
        const sorted = [...this.newtreeNode];
        sorted.sort((a, b) => {
          const labelA = a.name.toLowerCase();
          const labelB = b.name.toLowerCase();
          if (this.sortDirection === "asc") {
            return labelA.localeCompare(labelB);
          } else if (this.sortDirection === "desc") {
            return labelB.localeCompare(labelA);
          } else {
            return this.newtreeNode
          }
        });

        return sorted;
      }
    },
    newNodetrigger() {
      return this.newCreatedData
    },
    updateNodeTrigger() {
      return this.newUpdatedData
    },

  },
  created() {
    this.getTree();

  },



  methods: {
    //Filter Tree based on Quote Order Status and Lifecycle Status
    filterData(data) {
      const filteredData = [];

      data.forEach(oem => {
        const filteredOEM = { ...oem };
        filteredOEM.children = [];

        oem.children.forEach(product => {
          const filteredProduct = { ...product };
          filteredProduct.children = [];

          product.children.forEach(iteration => {
            //if click won 
            if (this.treeFilter == 'won') {
              if (iteration.order_status === 'Won' && iteration.lifecycle_status === 'Quoted') {
                filteredProduct.children.push(iteration);
              }
            }
            //if click lost 
            if (this.treeFilter == 'lost') {
              if (iteration.order_status === 'Lost' && iteration.lifecycle_status === 'Quoted') {
                filteredProduct.children.push(iteration);
              }
            }
            //if click await 
            if (this.treeFilter == 'await') {
              if (iteration.order_status === 'Processing' && iteration.lifecycle_status === 'Quoted') {
                filteredProduct.children.push(iteration);
              }
            }
            //if click Progress 
            if (this.treeFilter == 'Progress') {
              var lifestatus = ['In Sourcing', 'In Quoting']
              if (lifestatus.includes(iteration.lifecycle_status)) {
                filteredProduct.children.push(iteration);
              }
            }
            if (this.treeFilter == 'nosourcing') {
              var status = ['New', 'In RFQ', 'In BOM Extract']
              if (status.includes(iteration.lifecycle_status)) {
                filteredProduct.children.push(iteration);
              }
            }
          });


          if (filteredProduct.children.length > 0) {
            filteredOEM.children.push(filteredProduct);
          }
        });

        if (filteredOEM.children.length > 0) {
          filteredData.push(filteredOEM);
        }
      });

      return filteredData;
    },

    includeAllChildren(node) {
      if (node.children) {
        node.children.forEach(child => {
          this.includeAllChildren(child);
        });
      }
    },

    //Tree Filter based on Status
    onItemClick(value) {
      this.TreeFilterJson.forEach((qtyVal) => {
        if (qtyVal.value === value) {
          qtyVal.is_primary = true;
        }
        else {
          qtyVal.is_primary = false
        }
      })

      this.treeFilter = value
    },
    //Make a copy of Iteration
    copyIteration(type, name) {
      this.commonpopupType = type
      this.myStore.NIter.iteration = name
      // this.citeration = true
      this.dialog = true;
    },
    copyButton(type) {
      console.log(type);
      if (this.myStore.NIter.iteration === '') {
        this.dialog = true
      } else {

        this.confirm = true
        this.citeration = true
      }
    },
    async makeCopyIteration() {
      await UploadService.copyOfIteration(this.selected, this.myStore.NIter.iteration).then((x) => {
        AlertService.Success(x);
        localStorage.setItem('bom_name', this.myStore.NIter.iteration)
        this.getTree();
        this.confirm = false;
        this.dialog = false
      }).catch(err => {
        this.confirm = false;
        this.myStore.copyerrmsg = err
      })
    },
    //Tree Sorting Function
    handleSort() {
      if (this.sortDirection === null) {
        this.sortDirection = 'asc'
      } else if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = null
      }
    },

    //open Confirmation dialog
    openPopup(type, popuptype, name) {
      this.commonpopupType = popuptype
      this.dialog = true;
      this.bomType = [type, name];
    },
    //after node updated callback function
    updated(newData) {
      this.newUpdatedData = newData;
    },
    //click search open & close
    toggleSearch() {
      this.isSearchOpen = !this.isSearchOpen
    },
    //Clear Search
    resetFilter() {
      this.filter = ''
      this.filterRef.focus()
      this.isSearchOpen = false
    },
    //Close Confirmation
    closeDialog() {
      this.dialog = false;
    },
    //Handling Confirmation popup
    async handleConfirm() {
      try {
        // Determine which update function to call based on the condition
        if (this.citeration) {
          this.makeCopyIteration()
        }
        else {
          this.handleDelete()
        }

        // Close the dialog after the action is completed
        this.confirm = false;
      } catch {
        // Handle errors if needed
        console.error('Error in Function');
      }
    },
    //Delete Node - api call
    async deleteNode(type) {
      this.bomType = type
      this.citeration = false
      // Show Quasar Dialog
      this.confirm = true;
    },
    async handleDelete() {
      try {

        if (this.bomType[0] === 'oem') {
          await UploadService.deleteCustomer(this.selected);
        } else if (this.bomType[0] === 'product') {
          await UploadService.deleteProduct(this.selected);
        } else {
          const body = {
            force_delete: this.bomType[1] !== 'New' ? true : false
          };
          console.log("body", body);
          await UploadService.deleteIteration(this.selected, body);
        }


        AlertService.Success('Delete successful');
        this.confirm = false
        localStorage.setItem("qty", null);
        localStorage.setItem('bom_name', null)
        this.getTree();
      }
      catch (err) {
        AlertService.Error(err);
        this.confirm = false
      }
    },
    //Getting Tree Data From server
    getTree() {
      return new Promise((resolve, reject) => {
        this.loader = true
        UploadService.getStaticTree().then((x) => {
          this.treeNode = x;
          const selectedNode = localStorage.getItem('bom_name');
          //find First iteration
          if (selectedNode === "null") {
            for (const rootNode of this.treeNode) {
              if (rootNode.type === "oem") {
                const iterationName = commonFunction.findFirstIterationInOEM(rootNode);
                if (iterationName) {
                  localStorage.setItem("bom_name", iterationName)
                  this.selected = iterationName
                  this.myStore.NIter.iteration = iterationName
                  this.$emit("changeBom_name", this.selected);
                  break;  // once node found
                }
              }
            }
            //First Node Expand
            const filteredOemProductNames = commonFunction.filterOemProductNamesWithIteration(this.treeNode);
            this.myStore.NOEM = filteredOemProductNames[0]; this.myStore.NProduct.product = filteredOemProductNames[1]
            localStorage.setItem("expandedNodes", JSON.stringify(filteredOemProductNames.slice(0, 2)));
            const nodes = JSON.parse(localStorage.getItem('expandedNodes')) || [];
            this.expanded = nodes;
          } else {
            this.selected = selectedNode;
            this.$emit("changeBom_name", this.selected);
            const expandedNodes = JSON.parse(localStorage.getItem('expandedNodes')) || [];
            this.expanded = expandedNodes;
          }
          const expandNode = localStorage.getItem('expandedNodes');
          if (expandNode === "null") {
            const bom = localStorage.getItem('bom_name');
            const resultNodes = commonFunction.findNodesOemProduct(this.treeNode, bom);
            if (resultNodes.oem && resultNodes.product) {
              this.myStore.NOEM = resultNodes.oem.name
              this.myStore.NProduct.product = resultNodes.product.name
              this.myStore.NIter = { oem: resultNodes.oem.name, product: resultNodes.product.name, iteration:bom  }
              var r = []; r.push(resultNodes.oem.name), r.push(resultNodes.product.name)
              localStorage.setItem("expandedNodes", JSON.stringify(r));
            } else {
              console.log("No data found for the given iteration name.");
            }
          }
          const expandedNodes = JSON.parse(localStorage.getItem('expandedNodes')) || [];
          this.expanded = expandedNodes;
          // this.selected = selectedNode;
          // this.$emit("changeBom_name", this.selected);

          this.loader = false;
          resolve();

        }).catch(err => {
          console.log(err)
          this.loader = false
          document.getElementById('No-data').innerHTML = "No Data Available"
          reject(err);
        });
      });
    },
    //click to action on Node type iteraion
    async getBomName(val, type) {

      if (type === 'product' && this.type === 'upload') {
        const resultNodes = commonFunction.findNodesOemProduct(this.treeNode, val);
        if (resultNodes.oem) {
          this.currentoem = resultNodes.oem.name
          this.myStore.NOEM = resultNodes.oem.name
          this.myStore.NProduct = { oem: resultNodes.oem.name, product: val, Nodetype: type }
        }
      }
      if (type === 'iteration') {
        // localStorage.setItem("qty", null);
        localStorage.setItem('qty', null)
        this.selected = val;
        const resultNodes = commonFunction.findNodesOemProduct(this.treeNode, val);
        if (resultNodes.oem && resultNodes.product) {
          this.myStore.NOEM = resultNodes.oem.name
          this.myStore.NProduct.product = resultNodes.product.name
          this.myStore.NIter = { oem: resultNodes.oem.name, product: resultNodes.product.name, iteration: val, Nodetype: type }
          var r = []; r.push(resultNodes.oem.name), r.push(resultNodes.product.name)
          localStorage.setItem("expandedNodes", JSON.stringify(r));
        } else {
          console.log("No data found for the given iteration name.");
        }
        this.myStore.bom_name = val;
        localStorage.setItem("bom_name", val);
        localStorage.setItem("qty", null);
        var quotes = { 'GroupBy': 'none', 'GroupName': 'None','Filter': { "name": "All", "value": "all", "id": 1, "selected": false }, 'Filter_Field': "" }
        localStorage.setItem("quotespage", JSON.stringify(quotes));
        this.myStore.loadQuotes();
        this.myStore.isCardVisible=false;
        this.myStore.isToastVisible=false;
        this.$emit("changeBom_name", val);
      } else {
        this.selected = val;
      }
    },


  },
  mounted() {
    this.getTree().then(() => {
      const iterationQueryParam = this.route.query.iteration;
      if (iterationQueryParam) {
        // If the 'iteration' query parameter exists, call the findNodesForIteration function
        const iterationName = decodeURIComponent(iterationQueryParam);
        const resultNodes = commonFunction.findNodesOemProduct(this.treeNode, iterationName);
        if (resultNodes.oem && resultNodes.product) {
          var r = []; r.push(resultNodes.oem.name), r.push(resultNodes.product.name)
          localStorage.setItem("expandedNodes", JSON.stringify(r));
          const nodes = JSON.parse(localStorage.getItem('expandedNodes')) || [];
          this.expanded = nodes;
        }
        localStorage.setItem("bom_name", this.route.query.iteration)
        this.selected = this.route.query.iteration
      } else {
        this.selected = localStorage.getItem("bom_name");
      }
    }).catch(error => {
      // Handle error if getTree fails
      console.log('Error fetching tree data:', error);
    });
  },
  beforeMount() {
    SettingService.ViewFilters().then(x => { this.TreeFilterJson = x; })
  }
};
</script>