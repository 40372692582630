<template>
  <q-page class="q-pa-sm layout ">
    <div class=" page-header">
    <div class="page-heading q-pa-sm">Quotes</div>
    <span class="page-headerBreadcrumb q-ml-sm">
    <q-breadcrumbs class="qsummarybread">
      <q-breadcrumbs-el :label="formattedString(titledata[0])" icon="factory">
        <q-tooltip anchor="bottom middle" self="top middle" :offset="[-1, -1]">
          {{ myStore.NOEM }}
        </q-tooltip>
      </q-breadcrumbs-el>
      <q-breadcrumbs-el :label="formattedString(titledata[1])" icon="book"> <q-tooltip anchor="bottom middle"
          self="top middle" :offset="[-1, -1]">
          {{ myStore.NProduct.product }}
        </q-tooltip></q-breadcrumbs-el>
      <q-breadcrumbs-el @click="showTree()" :label="formattedString(titledata[2])" icon="login" class="text-info cursor-pointer"> <q-tooltip anchor="bottom middle"
          self="top middle" :offset="[-1, -1]">
          {{ myStore.NIter.iteration }}
        </q-tooltip></q-breadcrumbs-el>
    </q-breadcrumbs>
  </span>
  </div>
    <div class="col">



      <div class="row flex" v-if="myStore.isToastVisible">
        <div class="col-8  ">
          <QuotesToastersComponent type="quotes" :bom_name="selected" />
        </div>
        <div class="col-5 q-pr-lg" v-if="$q.screen.gt.xs" style="position: absolute;right: 0px;top: 25px;">


          <q-card class="qsummary" flat bordered>
            <q-card-section horizontal>
              <q-card-actions vertical class="justify-around q-px-md">
                <img src="/summary.png">
              </q-card-actions>
              <q-item class="q-pa-none  col ellipsis-text  ">
                <q-item-section class=" q-pa-none q-my-xs   q-pr-lg q-ml-none  text-right">
                  <q-item-label class="  qsummaryHeader">Quotes Summary</q-item-label>
                  <SpinnerComponent type='summary' v-if="summaryloading" />
                  <span v-if="!summaryloading">
                    <q-item class="q-mt-sm q-pa-none">
                      <q-item-section class="q-pa-none">
                        <q-item-label caption v-for="(QSum, index) in QSummary.slice(0, 4)" :key="index"
                          class="  qsummarycontent">Qty:{{ QSum.required_quantity }}, Lead Time: {{ QSum.lead_time !==
                            null
                            ?
                            QSum.lead_time : 0 }} weeks, Total
                          Price:$ {{ QSum.total_price !== null ? QSum.total_price : 0 }} @ $ {{ QSum.unit_price
                            !== null ? format(QSum.unit_price) : 0 }} each
                          <q-tooltip anchor="bottom middle" self="top middle" :offset="[-1, -1]">
                            Qty:{{ QSum.required_quantity }}, Lead Time: {{ QSum.lead_time !== null ?
                              QSum.lead_time : 0 }} weeks, Total
                            Price:$ {{ QSum.total_price !== null ? QSum.total_price : 0 }} @ $ {{ QSum.unit_price
                              !== null ? QSum.unit_price : 0 }} each

                          </q-tooltip>
                        </q-item-label></q-item-section></q-item>
                  </span>
                </q-item-section>
              </q-item>

              <q-card-actions vertical class="justify-around q-px-md dollar">
                <q-btn class="glossy" round color="secondary" size="xs" icon="fa-solid fa-dollar-sign" />
              </q-card-actions>
            </q-card-section>
          </q-card>


        </div>
      </div>
      <div class="  row flex  " v-if="myStore.isToastVisible">
        <div class="col-10 q-my-sm  ">
         
            <q-btn-dropdown class="custom-button q-mr-sm q-mb-xs btn-fixed-width" color="secondary text-secondary"
            icon="fa fa-circle-plus" :label="newQuotelabel" outline :disable="selected == null">
            <q-list dense>
              <q-item clickable v-close-popup @click="createQuote('newRfq')">
                <q-item-section>
                  <q-item-label>Create New</q-item-label>
                </q-item-section>
              </q-item>

              <q-item clickable v-close-popup @click="createQuote('copyRfq')">
                <q-item-section>
                  <q-item-label>Make a Copy</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
          <q-btn class=" q-mr-sm q-mb-xs btn-fixed-width" color="secondary text-secondary" outline @click="updatePricing()"
            :disable="EnableRefreshQuote || !disableupdatePrice || selected == null" label="Update Pricing"
            icon="edit" />
          <q-btn class="q-mr-sm q-mb-xs btn-fixed-width" color="secondary text-secondary" outline
            :disable="EnableSaveDraft || selected == null" @click="commonconfirmfunc('draft')" label="Save Draft"
            icon="fa-regular fa-floppy-disk" />

          <q-btn class="q-mr-sm q-mb-xs btn-fixed-width" outline color="secondary text-secondary" @click="commonconfirmfunc('export')"
            label="Export Quote" icon="fa fa-circle-arrow-right" :disable="EnableRefreshQuote || selected == null" />
          <q-btn :disable="EnableRefreshQuote || selected == null" outline color="secondary text-secondary"
            class="q-mr-sm q-mb-xs btn-fixed-width" @click="Refresh()" label="Refresh Quote" icon="fa fa-clipboard-list" />
        </div>
      </div>
      <div class="  row flex  ">
        <div v-if="myStore.isCardVisible"
          :class="{ ' col-3  ': LeftTab == 'products', '  col-3   ': LeftTab == 'filters', 'column-height': myStore.isToastVisible == true, 'column-height-up': myStore.isToastVisible == false, }">



          <q-card class="treecard" flat>

            <div class="q-mini-drawer-hide absolute lefticon-outer" style="top: 100px; right: -17px;z-index:99"
              v-if="myStore.isCardVisible == true">
              <q-btn dense round unelevated color="secondary" icon="fa-solid fa-angle-left"
                @click="myStore.isCardVisible = false" class="glossy lefticon" />
            </div>

            <q-tabs class="no-shadow flat" v-model="LeftTab" :switch-indicator="true" align="justify" narrow-indicator
              active-bg-color="primary text-white" indicator-color="primary">
              <q-tab name="products" label="Explorer" class="curved-tabs" />
              <q-tab name="filters" :label="step == 3 ? 'Filters' : 'Dashboard'" class="curved-tabs"
                v-if="step == 3 || step == 5" />
            </q-tabs>
            <q-separator />
            <q-tab-panels v-model="LeftTab" animated class=" text-center">

              <q-tab-panel name="products" class="q-pa-none">
                <bom-tree @changeBom_name="getBom_name" type="quotes" :newCreatedData="newCreatedData" />
              </q-tab-panel>
              <q-tab-panel name="filters" class="q-pa-none" style="overflow-x: hidden;">
                <filter-tab :bom_name="selected" :ipn="ipn" @updateStep="updateStep" v-if="step == 3"
                  :AlifeCycle="AlifeCycle" />
                <RightMenu @dashid="fdashid" v-if="step == 5" type="Quotes" />
              </q-tab-panel>
            </q-tab-panels>
          </q-card>
        </div>

        <!-- <div :class="{ 'col-9 column-height': myStore.isCardVisible == true, ' col-12 column-height': myStore.isCardVisible == false, }"> -->
        <div
          :class="{ 'col-9': myStore.isCardVisible == true, ' col-12': myStore.isCardVisible == false, 'column-height': myStore.isToastVisible == true, 'column-height-up': myStore.isToastVisible == false, }">
          <q-card class="q-ml-sm" style="border-radius: 20px;">

            <div class="q-mini-drawer-hide absolute lefticon-outer" style="top: 100px; left: -17px;z-index:99"
              v-if="myStore.isCardVisible == false">
              <q-btn dense round unelevated color="secondary" icon="fa-solid fa-angle-right"
                @click="myStore.isCardVisible = true" class="glossy lefticon" />
            </div>


            <q-stepper v-model="step" ref="stepper" header-nav color="secondary" done-color="secondary"
              active-color="primary" inactive-color="primary" error-color="negative">
              <q-step :name="1" title="RFQ" :icon="donerfq ? done : ''" :active-icon="editrfq ? 'edit' : done"
                :done="donerfq" :header-nav="donerfq" animated>
                <rfq-tab :bom_name="selected"  @updateRfqStep="updateRfqStep"
                  @rfqDetails="rfqDetails" @Editing="Editing" :AlifeCycle="AlifeCycle"></rfq-tab>

              </q-step>

              <q-step :name="2" title="BOM" :icon="doneBom ? done : ''" active-icon="done" :done="doneBom"
                :header-nav="doneBom">
                <bom-extract :bom_name="selected" @bom-ipn="emitIpn" @BackToRfq="BackToRfq" @updateBom="updateBom"
                  :AlifeCycle="AlifeCycle"></bom-extract>
              </q-step>

              <q-step :name="3" title="Sourcing" :icon="doneSource ? done : ''" active-icon="done" :done="doneSource"
                :header-nav="doneSource">
                <quotation-tab :bom_name="selected" @BackToRfq="BackToRfq" @bom-ipn="emitIpn"
                  @updateSource="updateSource" :AlifeCycle="AlifeCycle" @disablePrice="disablePrice"></quotation-tab>

              </q-step>
              <q-step :name="4" title="Labour" :icon="donelabour ? done : ''" disable active-icon="done"
                :done="donelabour" :header-nav="donelabour">
                <labour-tab></labour-tab>
              </q-step>
              <q-step :name="5" title="Quotation" :icon="donequotation ? done : ''" active-icon="done"
                :done="donequotation" :header-nav="donequotation">
                <SuperSet :dashid="dashid" @updateQuotes="updateQuotes" :bom_name="selected" type="QuotationTab"
                  :AlifeCycle="AlifeCycle" />
              </q-step>



            </q-stepper>

            <div class="q-mini-drawer-hide absolute lefticon-outer" style="top: -15px; right: 5px;z-index:99"
              v-if="myStore.isToastVisible == false">
              <q-btn dense round unelevated color="secondary" class="glossy lefticon" icon="fa-solid fa-angle-down"
                @click="myStore.isToastVisible = true" />
            </div>
            <div class="q-mini-drawer-hide absolute lefticon-outer" style="top: -15px; right: 5px;z-index:99"
              v-if="myStore.isToastVisible == true">
              <q-btn dense round unelevated color="secondary" icon="fa-solid fa-angle-up"
                @click="myStore.isToastVisible = false" class="glossy lefticon" />
            </div>

          </q-card>
        </div>


      </div>
    </div>


    <q-dialog v-model="commonconfirm">
      <q-card class="gdialog card-outline-primary confirm-popup">
        <q-card-section class="card-header-section1 ">
          <div v-if="confirmtype == 'draft'" class="text-h6 q-ml-sm"><q-icon name="report_problem" flat dense
              color="warning" size="25px" class="q-mb-xs"></q-icon><span class="q-ml-sm ">Save Draft Confirmation</span>
          </div>
          <div v-if="confirmtype == 'export'" class="text-h6 q-ml-sm"><q-icon name="report_problem" flat dense
              color="warning" size="25px" class="q-mb-xs"></q-icon><span class="q-ml-sm ">Export Quote
              Confirmation</span></div>
          <div v-if="confirmtype == 'copy'" class="text-h6 q-ml-sm"><q-icon name="report_problem" flat dense
              color="warning" size="25px" class="q-mb-xs"></q-icon><span class="q-ml-sm ">Copy of RFQ Name
              Confirmation</span></div>
        </q-card-section>

        <q-card-section class="">
          <span class="q-ml-sma" v-if="confirmtype == 'draft'">Draft Quote will be saved under Documents for this RFQ.
            Do
            you
            wish to continue? </span>
          <span class="q-ml-sma" v-if="confirmtype == 'export'">Quote will be exported based on available pricing and
            recommendations, including any recommendations overridden manually. Do you wish to continue? </span>
          <span v-if="confirmtype == 'copy'" class="">Make a copy of all the documents in RFQ {{
            selected }} ?</span>

        </q-card-section>

        <q-card-actions align="right" class="card-footer-section">
          <q-btn :loading="loading" label="Ok" outline color="secondary" @click="handleUpdatecommon(confirmtype)">
            <template v-slot:loading> <q-spinner-facebook /> </template>
          </q-btn>
          <q-btn label="Cancel" outline color="secondary" v-close-popup />
        </q-card-actions>

      </q-card>
    </q-dialog>
    <q-dialog v-model="newQuote" persistent transition-show="flip-down" transition-hide="flip-up">

      <q-card v-if="rfqpopupType === 'copyRfq'" class="gdialog" style="width: 500px;">

        <CopyRfq @copyButton="copyButton" />

      </q-card>
      <div v-if="rfqpopupType === 'newRfq'" style="overflow-x: hidden; width: 700px; max-width: 80vw;">
        <PopupComponent Type="iteration" pageType="Quotes" :newClose="closeDialog" @created="created"></PopupComponent>
      </div>
    </q-dialog>

  </q-page>
</template>
<script>
import { ref } from 'vue'
import { QuotesService, AlertService, UploadService } from '@/services'
import QuotesToastersComponent from '@/components/quotes/QuotesToastersComponent.vue'
import BomExtract from '@/components/quotes/BomExtract.vue'
import RfqTab from '@/components/quotes/RfqTab.vue'
import PopupComponent from '@/components/PopupComponent.vue'
import QuotationTab from '@/components/quotes/QuotationTab.vue'
import LabourTab from '@/components/quotes/LabourTab.vue'
import BomTree from '@/components/BomTree.vue'
import CopyRfq from '@/components/CopyRfq.vue'
import FilterTab from '@/components/filters/FilterTab.vue'
import SuperSet from '@/components/quotessummary/SuperSet.vue'
import RightMenu from '@/components/quotessummary/RightMenu.vue'
import { useMyStore } from '@/store';
import { Loading } from 'quasar';
import { SpinnerComponent ,commonFunction} from "@/utils"
export default {

  setup() {
    
    

    const myStore = useMyStore();
    return {
      myStore,
      QuotationRow: ref([]),
      selected: ref(localStorage.getItem("bom_name")),
      ipn: ref(''),
      LeftTab: ref('products'),
      tab: ref('rfq'),
      step: ref(1),
      done: ref("done"),
      donerfq: ref(false),
      loading: ref(false),
      doneBom: ref(false),
      doneSource: ref(false),
      donelabour: ref(false),
      EnableRefreshQuote: ref(false),
      EnableSaveDraft: ref(false),
      EnableExportQuote: ref(false),
      summaryloading: ref(false),
      donequotation: ref(false),
      editrfq: ref(''),
      AlifeCycle: ref(''),
      QSummary: ref([]),
      dashid: ref(''),
      commonconfirm: ref(false),
      confirmtype: ref(""),
      rfqpopupType: ref(''),
      disableupdatePrice: ref(true),
      newQuote: ref(false),
    }
  }, components: {
    CopyRfq,
    SpinnerComponent,
    QuotesToastersComponent,
    BomTree,
    BomExtract,
    RfqTab,
    PopupComponent,
    QuotationTab,
    LabourTab,
    FilterTab,
    SuperSet,
    RightMenu


  },
  watch: {
    step(newqty) {
      if (newqty == 1 || newqty == 2) {
        this.LeftTab = 'products';
        this.editrfq=''
      }
      if (newqty == 5) {
        this.LeftTab = 'filters';
      }

    },


    selected(newVal) {
      if (newVal) {
        this.getLifeCycleStatus();
        this.getQSummary();
      }

    },
    summary(newVal) {
      if (newVal) {
        this.getQSummary()
      }
    }
  },
  computed: {


    summary() {
      return this.myStore.newArray, this.myStore.refreshpricing
    },
    newQuotelabel() {
      return `New  ${this.myStore.treeLabels[2]}`
    },
   
  titledata() {
      var oemExpand = []
      if(this.selected!=null){
      oemExpand = JSON.parse(localStorage.getItem('expandedNodes'))
      oemExpand.push(this.selected);
      return oemExpand;
      }else{
        return ['','','']
      }
    },

  },
  methods: {
    formattedString(string) {
      const res = commonFunction.BreadcrumbFormat(string)
      return res
    },
    showTree(){
      this.myStore.isCardVisible=true;
      this.myStore.isToastVisible=true;
    },
    // receive Disable Update Pricing 
    disablePrice(disable) {
      this.disableupdatePrice = disable;
    },
     closeCard () {
      this.myStore.myStore.isCardVisible = false;
    },
    // New Quote Popup
    closeDialog() {
      this.newQuote = false
    },
    created(createdData) {
      this.step=1
      this.myStore.isCardVisible=true
      this.newCreatedData = createdData
    },
    createQuote(type) {
      this.rfqpopupType = type
      this.newQuote = true
      this.myStore.copyerrmsg = ''
    },
    copyButton(type) {
      if (this.myStore.NIter.iteration === '') {
        this.newQuote = true
      } else {

        this.commonconfirm = true
        this.confirmtype = type
      }
    },
    async copyQuote() {
      this.loading = true
      await UploadService.copyOfIteration(this.selected, this.myStore.NIter.iteration).then((x) => {
        localStorage.setItem('bom_name', this.myStore.NIter.iteration)
        this.loading = false;
        this.LeftTab = 'products'
        AlertService.Success(x);
        this.newQuote = false;
        this.commonconfirm = false;
        this.step=1
        this.myStore.isCardVisible=true
        this.myStore.newArray = [{ value: '-', is_primary: false, details: '-' }]
        this.myStore.refreshpricing = Math.random()
      }).catch(err => {
        this.loading = false;
        this.commonconfirm = false;
        this.myStore.copyerrmsg = err
      })
    },
    handleChange(value) {
      this.myStore.NIter.iteration = value
      this.myStore.copyerrmsg = ''
    },
    // formatting unit price
    format(price) {
      let num = price;
      let result = num.toFixed(4);
      return result.replace(/\.(\d{2})\d*/, '.$1');
    },
    commonconfirmfunc(type) {
      this.commonconfirm = true
      this.confirmtype = type;
    },
    async handleUpdatecommon(type) {
      try {
        // Determine which update function to call based on the condition
        if (type == 'export') {
          // Loading.show();
          this.exportClicked();
        }
        if (type == 'draft') {
          //  Loading.show();
          this.getsaveDraft();
        }
        if (type == 'copy') {
          this.loading = true
          //  Loading.show();
          this.copyQuote();
        }

        // Close the dialog after the action is completed
      } catch (err) {
        // Handle errors if needed
        console.error(err);
      }
    },
    // Export File
    async exportClicked() {
      try {
        this.commonconfirm = false
        Loading.show();
        await QuotesService.getExportQuote(this.selected).then((x) => {
          commonFunction.downloadQuote(x.data);
          Loading.hide();
        })
      } catch (error) {
        Loading.hide();
        AlertService.Error(error.response.data.detail);
      }
    },
   
    // Save Draft
    async getsaveDraft() {
      try {
        Loading.show();
        await QuotesService.safeDraft(this.selected).then((x) => {
          Loading.hide();
          console.log(x)
          this.commonconfirm = false
          AlertService.Success(x)
        })
      } catch (error) {
        Loading.hide();
        if (typeof error === 'string') {
          AlertService.Error(error);
        } else {
          AlertService.Error('Error');
        }

      }
    },
    // get Quote Summary
    async getQSummary() {
      this.summaryloading = true
      if (this.selected != null) {
        await QuotesService.getQuotesSummary(this.selected).then((x) => {
          this.myStore.NIter.iteration = localStorage.getItem('bom_name')
          this.QSummary = x;
          setTimeout(() => {
            this.summaryloading = false
          }, 500)


        }).catch(err => { console.log(err), this.summaryloading = false })
      } else { this.summaryloading = false }
    },
    // Get Bom Life Cycle Status
    async getLifeCycleStatus() {
      if (this.selected != null) {
        await QuotesService.getLifeCycle(this.selected).then((x) => {
          this.AlifeCycle = x;
          if (this.AlifeCycle !== '') {
            if (this.AlifeCycle === 'New') {
              this.EnableRefreshQuote = true
              this.EnableSaveDraft = true
              this.EnableExportQuote = true
              this.step = 1;
              this.donerfq = true;
              this.doneBom = false;
              this.doneSource = false;
              this.donelabour = false;
              this.donequotation = false;
            }
            else if (this.AlifeCycle === 'In RFQ') {
              this.EnableRefreshQuote = true
              this.EnableSaveDraft = true
              this.EnableExportQuote = true
              this.step = 1;
              this.donerfq = true;
              this.doneBom = false;
              this.doneSource = false;
              this.donelabour = false;
              this.donequotation = false;
            }
            else if (this.AlifeCycle === 'In BOM Extract') {
              this.EnableRefreshQuote = true
              this.EnableSaveDraft = true
              this.EnableExportQuote = true
              this.step = 2
              this.donerfq = true;
              this.doneBom = true;
              this.doneSource = false;
              this.donelabour = false
              this.donequotation = false;
            }
            else if (this.AlifeCycle === 'In Sourcing') {
              this.EnableRefreshQuote = false
              this.EnableSaveDraft = false
              this.EnableExportQuote = true
              this.step = 3
              this.donerfq = true;
              this.doneBom = true;
              this.doneSource = true;
              this.donelabour = false
              this.donequotation = false;
            }
            else if (this.AlifeCycle === 'In Quoting') {
              this.EnableRefreshQuote = false
              this.EnableSaveDraft = false
              this.EnableExportQuote = true
              this.step = 5
              this.donerfq = true;
              this.doneBom = true;
              this.doneSource = true;
              this.donelabour = true
              this.donequotation = true;
            }
            else {
              this.EnableRefreshQuote = false
              this.EnableSaveDraft = false
              this.EnableExportQuote = false
              this.step = 5
              this.donerfq = true;
              this.doneBom = true;
              this.doneSource = true;
              this.donelabour = true
              this.donequotation = true;
            }
          } else {
            this.donerfq = false;
            this.doneBom = false;
            this.doneSource = false;
            this.donelabour = false
            this.donequotation = false;
          }

        }).catch((err) => { console.log(err); })
      }
    },
    fdashid(dashid) {
      this.dashid = dashid;
      //  this.findLocalData();

    },
    // Refresh Quote
    Refresh() {
      Loading.show();
      this.step = 3;
      this.getQSummary()
      this.myStore.refreshpricing = Math.random();
      setTimeout(() => {
        Loading.hide();
      }, 1000)



    },
    // Trigger & Update Pricing
    async updatePricing() {
      Loading.show();
      await QuotesService.updatePricingBom(this.selected).then(async (x) => {
        console.log(x);
        setTimeout(() => {
          Loading.hide();
        }, 500)
        this.getQSummary()
        this.myStore.refreshpricing = Math.random();
      }).catch(err => {
        AlertService.Error(err);
        setTimeout(() => {
          Loading.hide();
        }, 500)
      })
    },
    Editing(edit) {
      this.editrfq = edit
    },
    updateStep(newStep) {
      this.step = newStep
    },
    BackToRfq(newStep) {
      this.step = newStep

    },
    updateRfqStep(nextStep, nextStatus) {
      if (this.AlifeCycle == 'New' || this.AlifeCycle == 'In RFQ') {
        // this.getLifeCycleStatus();
        setTimeout(() => {
          this.step = nextStep;
          this.AlifeCycle = nextStatus;
          this.getLifeCycleStatus();
        }, 3000);
        this.getLifeCycleStatus();
      }
      else {
        this.step = nextStep
        this.AlifeCycle = nextStatus
        this.getLifeCycleStatus();
      }


    },
    updateBom(nextStep, nextStatus) {
      if (this.AlifeCycle == 'In BOM Extract') {
        // this.getLifeCycleStatus();
        setTimeout(() => {

          this.step = nextStep;
          this.AlifeCycle = nextStatus;
          this.getLifeCycleStatus();
          this.EnableRefreshQuote = false;
          this.EnableSaveDraft = false

        }, 5000);
        this.getLifeCycleStatus();
      }
      else {
        this.step = nextStep;
        // this.AlifeCycle = nextStatus;
      }

    },
    updateSource(nextStep, nextStatus) {
      if (this.AlifeCycle == 'In Sourcing') {
        // this.getLifeCycleStatus();

        setTimeout(() => {
          this.getLifeCycleStatus();
          this.step = nextStep;
          this.AlifeCycle = nextStatus;
        }, 2000);
        this.getLifeCycleStatus();

      }
      else {
        this.step = nextStep;
        // this.AlifeCycle = nextStatus;
      }


    },
    updateQuotes() {
      if (this.AlifeCycle == 'In Quoting') {
        this.getLifeCycleStatus();
        this.EnableExportQuote = false
      }
      else {
        this.getLifeCycleStatus();
        // this.EnableExportQuote=false
      }
    },


    getBom_name(selected) {
      this.selected = selected;

    },
    emitIpn(bom_ipn) {
      this.ipn = bom_ipn;


    },


  },
  mounted() {
    this.getLifeCycleStatus();
    this.getQSummary();
    this.myStore.isCardVisible=true;
    this.myStore.isToastVisible=true;
  },
}
</script>
<style scoped>
.page-header{
  display: flex;
}
.page-headerBreadcrumb{
  margin-top: 14px;
}
</style>
