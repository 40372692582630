


export const commonFunction = {
  findFirstIterationInOEM,
  filterOemProductNamesWithIteration,
  findNodesOemProduct,
  BreadcrumbFormat,
  descriptionFormat,
  formatpreferredSupplier,
  labelColors,
  downloadQuote
}
// Find First Node to selected in Tree
function findFirstIterationInOEM(oem) {
  for (const childNode of oem.children) {
    const iterationName = findFirstIterationName(childNode);
    if (iterationName) {
      return iterationName;
    }
  }
  return null;
}
function findFirstIterationName(node) {
  if (node.type === "iteration") {
    return node.name;
  }

  if (node.children && node.children.length > 0) {
    for (const childNode of node.children) {
      const result = findFirstIterationName(childNode);
      if (result) {
        return result;
      }
    }
  }

  return null;
}
// ----------------------------------------------

// Find First Node to Be Expanded
function filterOemProductNamesWithIteration(data) {
  return data.reduce((filteredNames, node) => {
    if (
      (node.type === "oem" || node.type === "product") &&
      node.children &&
      filterNodesWithIteration(node.children).length > 0
    ) {
      filteredNames.push(node.name);
    }

    if (node.children && node.children.length > 0) {
      filteredNames = filteredNames.concat(
        filterOemProductNamesWithIteration(node.children)
      );
    }

    return filteredNames;
  }, []);
}
function filterNodesWithIteration(nodes) {
  return nodes.filter(node => {
    if (node.type === "iteration") {
      return true;
    }

    if (node.children && node.children.length > 0) {
      node.children = filterNodesWithIteration(node.children);
      return node.children.length > 0;
    }

    return false;
  });
}

//if Click node find OEM,Product
function findNodesOemProduct(data, targetIterationName) {
  const result = {
    oem: null,
    product: null,
  };

  function traverse(node, parentOem, parentProduct) {
    if (result.oem && result.product) {
      return;
    }

    if (node.name === targetIterationName) {
      result.oem = parentOem;
      result.product = parentProduct;
      return;
    }

    if (node.children) {
      for (const child of node.children) {
        if (node.type === "oem") {
          traverse(child, node, parentProduct);
        } else if (node.type === "product") {
          traverse(child, parentOem, node);
        } else {
          traverse(child, parentOem, parentProduct);
        }
      }
    }
  }

  for (const node of data) {
    traverse(node, null, null);
  }

  return result;
}

// Breadcrumb Formated String

function BreadcrumbFormat(string) {
  if (string.length > 10) {
    return string.replace(/^(.{8}).+(.{0})$/, '$1...$2');
  } else {
    return string
  }
}
function formatpreferredSupplier(string) {
  if (string.length > 10) {
    return string.replace(/^(.{14}).+(.{0})$/, '$1...$2');
  } else {
    return string
  }
}

function descriptionFormat(description) {
  const regex = /,\s*|\s+/;
  const words = description.split(regex);
  return words.slice(0, 2).join(" ");
}
function labelColors(best, filtered) {
  if (best && filtered) {
    return "BestRecommended"
  } else if (best === false && filtered === false) {
    return "NotRecommended"
  } else {
    return "PartialRecommended"
  }
}
 // Download Export File
function  downloadQuote(fileData) {
  try {
    const blob = new Blob([fileData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64' });
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = 'export-quote.xlsx';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  } catch (error) {
   console.log(error.response.data.detail);
  }
}
