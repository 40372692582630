<template>
  <div class="flex row">
    <div class="col-10 flex row justify-between">
      <q-table flat :rows="alternateMPN" :columns="similarityfields" dense hide-pagination selection="single"
        style="width:100%">
        <template #header="props">
          <q-tr :props="props" class="bg-grey-2 text-center">
            <q-th style="width:5%">#</q-th>
            <q-th v-for="col in props.cols" :key="col.key" class="text-bold">
              {{ col.label }}
            </q-th>
          </q-tr>
        </template>
        <template #body="props">
          <q-tr :props="props">
            <td class="text-center">{{ props.rowIndex + 1 }}</td>
            <q-td key="is_primary" class="text-center">
              <q-checkbox v-model="model" />
            </q-td>

            <q-td key="manufacturer" class="text-center">
              {{ props.row.manufacturer }}
            </q-td>
            <q-td key="mpn" class="text-center">
              {{ props.row.mpn }}
            </q-td>
            <q-td key="description" class="text-center">

              {{ cleanupWords(props.row.description) }}...

              <q-tooltip anchor="bottom middle" self="top middle" :offset="[-5, -5]">
                {{ props.row.description }}
              </q-tooltip>

            </q-td>


          </q-tr>
        </template>
      </q-table>
    </div>
    <div class="col-2 text-center	bg-hover">
      <div class=" text-dark bg-color" style=" height: 26px;">
        Preview
      </div>
      <div class="file-preview" style="margin-top: 10%;">
        <img
          src="https://static6.arrow.com/aropdfconversion/arrowimages/9a0908c0e0175cf32d9b6a9109754a6127e8378f/d3899924wf35bn.jpg" />




      </div>
      <div style="margin-top: 10%;cursor: pointer;">
        <a>DataSheet</a>

      </div>

    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useMyStore } from '@/store';
// import { QuotesService } from "@/services";



export default {
  name: "MpnSuggest",

  props: {
    alternateMPN: {
      type: Array
    },
  },


  setup(props) {
    const myStore = useMyStore();
    const similarityfields = ref([]);
    onMounted(async () => {
      // try {
      //   await myStore.loadPosts();
      //   similarityfields.value = myStore.fields.similarity_fields.filter((fields) => (fields.key !== "id"));
      // } catch (error) {
      //   console.error('Error in component:', error);
      // }
    });
    const custom_verified = props.alternateMPN.filter(x => x.is_customer_verified == true);
    const defaultBox = custom_verified ? custom_verified[0] = false : "";


    return {
      myStore,
      similarityfields,
      model: ref(defaultBox),
      mpn: '',

    };
  },
  methods: {

    // getColumn() {
    //   QuotesService.getAllColumn().then((x) => {
    //     this.similarityfields = x.similarity_fields
    //     this.similarityfields = this.similarityfields.filter((fields) => (fields.key !== "id"))
    //   });
    // },
    cleanupWords(description) {
      if (description !== null && description !== undefined) {
        const words = description.split(" ");
        return words.slice(0, 3).join(" ");
      }
      else {
        const words = ''
        return words
      }
    },





  },
  mounted() {

    try {
         
        this.similarityfields = this.myStore.fields.similarity_fields.filter((fields) => (fields.key !== "id"));
      } catch (error) {
        console.error('Error in component:', error);
      }
  },

};

</script>
<style scoped>
.bg-color {
  background-color: #F4F4F4;
}
</style>