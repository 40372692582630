import { fetchWrapper } from '@/helpers';
import { API_BASE_URL } from '@/api.config';
import axios from 'axios'
const baseUrl = '/data';
// const apiUrl = `${API_BASE_URL}`;
const apiUrl2 = `${API_BASE_URL}`;

export const EmailService = {
    EmailContent,
    SupplierEmail,
    Attachment,
    sendMailFun,
    moveRfqFun,
    moveAssemblyFun,
    sendAllSup,
    preferredSup,
    getVariance
};

// Email Content
function EmailContent() {
    return fetchWrapper.get(`${baseUrl}/Email.json`)
}

// get Supplier email
function SupplierEmail(supplier_name) {
    return fetchWrapper.get(`${apiUrl2}/email/get_email_ids/${supplier_name}`)
}
// get Attachment
async function Attachment(bom_name,bom_quantity, group_name, group_value, body) {
    console.log("group value",group_value);
    // const bom = localStorage.getItem('bom_name') || "";
    const cmuser2 = JSON.parse(localStorage.getItem('cmuser')) || { "access_token": null };
    // return fetchWrapper.get(`${apiUrl}api/quoting/${encodeIpn}/export`)

    try {
        const response = await axios.post(`${apiUrl2}/email/get_attachment/${bom_name}/${bom_quantity}/${group_name}/${group_value}`, body, {
            responseType: 'arraybuffer',
            headers: {
                "Accept": "application/json", 'X-API-Key': cmuser2.access_token,
                'Content-Type': 'application/json',
                'responseType': 'arraybuffer'

            }
        });

        // Create a Blob from the array buffer
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        // Create a link element and trigger a download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${group_value}_${bom_name}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading Excel file:', error);
    }
}
// Send Email
async function sendMailFun(bom_name, supplier_name, ipn, body) {
    const cmuser2 = JSON.parse(localStorage.getItem('cmuser')) || { "access_token": null };
    let encodeIpn = encodeURIComponent(encodeURIComponent(ipn));
    var formBody = [];
    for (var property in body) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(body[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
   
    formBody = formBody.join("&");
  
    
let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url:`${apiUrl2}/email/send_email?bom_name=${bom_name}&supplier_name=${supplier_name}&ipn=${encodeIpn}`,
    headers: { 
      'accept': 'application/json', 
      'X-API-Key':  cmuser2.access_token, 
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data : formBody
  };
   
  
    return await axios.request(config)
   
}
// Move Rfq Function

function moveRfqFun(iteration_name,product_folder) {
    return fetchWrapper.post(`${apiUrl2}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/move/${product_folder}`)
}
// Move Assembly Function

function moveAssemblyFun(product_name,customer_folder) {
    return fetchWrapper.post(`${apiUrl2}/oem/{customer_name}/product/${product_name}/move/${customer_folder}`)
}
// Send All Suppliers
function sendAllSup() {
    return fetchWrapper.get('api/quotation.json')
}
// get All preferredSuppliers
function preferredSup() {
    return fetchWrapper.get(`${apiUrl2}/email/preffered_supplier_email_ids`)
}
// get Price Variance
function getVariance(bom_name,ipn,qty) {
    return fetchWrapper.get(`${apiUrl2}/email/variance/${bom_name}/${ipn}?pb_quantity=${qty}`)
}