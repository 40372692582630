<template>
  <q-card class="q-ml-md col-12 q-mr-md " ref="fullscreenTarget">
    <q-card-actions class="filterhead table-card-actions" v-if="type == 'QuotationTab'">
      <q-btn @click="toggle" flat round dense :icon="toggleicon()"></q-btn>
      <q-btn rounded dense flat color="primary" icon="more_vertical" disable />

      <q-btn rounded dense flat color="primary" icon="search" disable />
      <q-space />
      <q-breadcrumbs align="center" style="font-weight: 600;" class="qsummarybread">
      <q-breadcrumbs-el :label="formattedString(titledata[0])" icon="factory" >
        <q-tooltip anchor="bottom middle" self="top middle" :offset="[-1, -1]">
              {{ myStore.NOEM }}
            </q-tooltip>
      </q-breadcrumbs-el>
      <q-breadcrumbs-el :label="formattedString(titledata[1])" icon="book" > <q-tooltip anchor="bottom middle" self="top middle" :offset="[-1, -1]">
              {{ myStore.NProduct.product }}
            </q-tooltip></q-breadcrumbs-el>
      <q-breadcrumbs-el  @click="showTree()" class="text-info cursor-pointer" :label="formattedString(titledata[2])" icon="login" > <q-tooltip anchor="bottom middle" self="top middle" :offset="[-1, -1]">
              {{ myStore.NIter.iteration}}
            </q-tooltip></q-breadcrumbs-el>
    </q-breadcrumbs>

      <q-space />

      <q-btn size="md" class="headerRow-fixed-width" outline dense label="Edit" color="secondary" disable  icon="edit"></q-btn>

      <q-btn size="md" class="headerRow-fixed-width" dense label="Submit" outline color="secondary" @click="UpdateNextStep()"
        icon="save"></q-btn>

    </q-card-actions>

    <div v-if="token" id="my-superset-container" :class="{ 'column-full-height': toogleclass() == true }"> Loading ..
    </div>
    <div  v-if="errmsg">
    <div :class="{'err-component':type !== 'QuotationTab','err-comp':type==='QuotationTab'}"> {{ errmsg }}
      Try Again After Sometime
    </div>
  </div>


  </q-card>
  <q-dialog v-model="confirm">
    <q-card class="gdialog card-outline-primary  confirm-popup">
      <q-card-section  class="card-header-section1">
        <div class="text-h6 q-ml-sm"><q-icon name="report_problem" flat dense
              color="warning" size="25px" class="q-mb-xs"></q-icon><span class="q-ml-sm ">Quote Submission Confirmation</span></div>
      </q-card-section>

      <q-card-section class="">
        <span class="">Quote is completed successfully. Do you want to export the quote file?</span>
      </q-card-section>
      <q-card-actions align="right" class="card-footer-section">
        <q-btn label="Ok" outline color="secondary" @click="UpdateQuote" />
        <q-btn label="Cancel" outline color="secondary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>

import { ref } from 'vue';
import { QuotesService, AlertService } from '@/services'
import { embedDashboard } from "@superset-ui/embedded-sdk";
import {SuperSetDomainurl} from "@/api.config"
import { useMyStore } from '@/store';
import { AppFullscreen } from 'quasar'
import {commonFunction} from '@/utils'

export default {
  name: 'LayoutDefault',
  props: ['dashid', 'bom_name', 'type', 'AlifeCycle'],
  setup() {
    const myStore = useMyStore();
    // const supersetId = ref({})
    // onMounted(() => {
    //   supersetId.value = userStore.dashboardlist1.id
    // })
    return {
      myStore,
      LifeCycleStatus: ref('Quoted'),
      confirm: ref(false),
      token: ref(''),
      errmsg: ref('')
    }
  },
  watch: {
    dashid(newVal, oldVal) {
      if (newVal != oldVal) {
        this.findLocalData();
      }

    },
    bom_name(newVal, oldVal) {
      if (newVal != oldVal) {
        this.findLocalData();
      }

    },
    refreshpricing() {
      this.findLocalData()
    }
  },
  computed: {
    refreshpricing() {
      return this.myStore.refreshpricing;
    },
    titledata() {
            var oemExpand = []
             oemExpand = JSON.parse(localStorage.getItem('expandedNodes'))
            oemExpand.push(this.bom_name);
            return oemExpand;
        },
  },
  components: {


  },

  // beforeUnmount() {
  //       document.removeEventListener("keydown", this.handleKeyDown);
  //   },
  methods: {
    // handleKeyDown(event) {
    //         if (event.keyCode === 70) {
    //             event.preventDefault();
    //             this.toggle();
    //         }
    //     },
     // Format the header row Breadcrumbs
     formattedString(string) {
      const res = commonFunction.BreadcrumbFormat(string)
      return res
    },
    showTree(){
      this.myStore.isCardVisible=true;
      this.myStore.isToastVisible=true;
    },
    toogleclass() {
      if (AppFullscreen.isActive) { return true; } else { return false; }
    },
    toggleicon() {
      if (AppFullscreen.isActive) { return 'fullscreen_exit'; } else { return 'fullscreen'; }
    },
    toggle() {
      const target = this.$refs.fullscreenTarget.$el;
      AppFullscreen
        .toggle(target)
        .then(() => {
          // success!
        })
        .catch((err) => {
          alert(err);
          // uh, oh, error!!
          // console.error(err)
        });

    },
    async UpdateNextStep() {

      // Show Quasar Dialog
      this.confirm = true;
    },
    UpdateQuote() {
      if (this.AlifeCycle == 'In Quoting') {

        QuotesService.UpdateLifeCycle(this.bom_name, this.LifeCycleStatus).then((x) => {
          console.log(x)
          AlertService.Success('Quotation Submitted Successfully');
          this.myStore.refreshpricing=Math.random();
          this.confirm = false
        })

        this.$emit('updateQuotes', this.LifeCycleStatus)


      }
      else {
        this.confirm = false
        this.$emit('updateQuotes', this.LifeCycleStatus)
      }
    },

    async findLocalData() {
      var t = ''
          var bom = localStorage.getItem('bom_name')
      let myData = localStorage.getItem("superset_token"); 
      if (myData == null) {
        try {
        if (this.dashid !== null && this.dashid !== '') {
          if(this.type === 'Dashboard'){ bom=null;} //may 03
        var tokeng = await QuotesService.getSupGuest(this.dashid, bom).then((x) => {

          t = JSON.parse(x);
          this.token = t.token
          this.errmsg = t.message
          return t.token
        }).catch((err) => { console.log("Guest Token  err", err); })
          if (!this.errmsg) {
          embedDashboard({
            id: this.dashid, // given by the Superset embedding UI
            supersetDomain: `${SuperSetDomainurl}`,
            mountPoint: document.getElementById("my-superset-container"), // any html element that can contain an iframe
            // fetchGuestToken: () => fetchGuestTokenFromBackend(),
            fetchGuestToken: () => tokeng,
            dashboardUiConfig: { // dashboard UI config: hideTitle, hideTab, hideChartControls, filters.visible, filters.expanded (optional)
              hideTitle: true,
              filters: {
                expanded: false,
              }
            },
          });

        }
        }
        }catch (err) {
          console.log("Api Failed", err)
        }
        } else {
        return "";
      }



    },
  },
  mounted() {

    this.findLocalData();
    // document.addEventListener("keydown", this.handleKeyDown);
  },

}
</script>
