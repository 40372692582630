<template>
  <q-btn-toggle :class="type?'q-mt-md':''" size="sm" :model-value="(model = String(Values))" :disable="Enable==false" @update:model-value="model = $event" rounded
    toggle-color="primary" :options="[
      { label: 'Y', value: 'true' },
      { label: 'N', value: 'false' }
    ]" />
</template>
  
<script>
import { ref } from "vue";

export default {
  name: "three-way-switch",
  props: ["Values", "threewaySwitchKey", "Enable",'type'],
  setup(props) {
    return {
      model: ref(String(props.Values)),
      labelKey: ref(props.threewaySwitchKey),
      Enablemodel:ref(props.Enable)
    };
  },
  watch: {
    model(newVal) {
      if (newVal && this.labelKey) {
        this.getChange(newVal, this.labelKey);
      }
    },
    Enablemodel(newVal,oldVal){
      if (newVal != oldVal ) {
        this.getChangeEnable(newVal, this.labelKey);
      } 
    }
  },
  methods: {
    //  value != "null" ? value == "false" ? false : true : null,
    getChange(value, labelKey) {
      this.$emit("change-three-way-toggle", [
        value,
        labelKey,
      ]);
    },
    getChangeEnable(enable,labelKey){
      this.$emit("change-three-way-toggleEnable", [
      enable,
        labelKey
        
      ]);
    },

    updateEnable(){
      this.Enablemodel=!this.Enablemodel
    }
  }
};
</script>
  