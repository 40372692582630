<template>
  <q-card class="filter-card-head ">
    <q-card-actions align="center" class=" filterhead1 tree-label q-pa-xs">
      IPN : {{ ipn }}
    </q-card-actions>
    <q-card-actions class="filterhead1  tree-label q-pa-sm">

      <q-btn-dropdown :label="ActiveFilters.label" class="q-ml-sm" dense outline color="secondary">
        <q-list v-for="(afkey, afindex) in autoFilters" :key="afindex" dense>
          <q-item clickable @click="changeFilters(afkey, afindex)" v-close-popup
            :active="afindex == ActiveFilters.label">
            <q-item-label class="q-mt-sm"><span v-for="(ikey, index) in icons" :key="index"><q-icon
                  v-if="index == afindex" :name="ikey"></q-icon> </span><span class="q-ml-md"> {{ afindex
                }}
                <q-tooltip anchor="top middle" self="top start" v-if="autoFilters[afindex].tooltip">

                  <span v-html="autoFilters[afindex].tooltip"></span>
                  <!-- Total Cost Filter first <br/>  Lead Time - Default value from Setting <br/>  Excess Qty - Default value from Setting <br/>  Deviation allowed, look for best price in alternative parts too -->

                </q-tooltip></span></q-item-label></q-item>
        </q-list>
        <!-- <q-tooltip anchor="bottom middle" self="top middle" :offset="[0, 0]">Auto Filters</q-tooltip> -->
      </q-btn-dropdown>
      <q-space></q-space>
      <q-btn dense round flat icon="fas fa-xmark" color="negative" :disabled="enableResetBtn" @click="getDefaultData()">
        <q-tooltip anchor="bottom middle" self="top middle" :offset="[0, 0]">Cancel</q-tooltip>
      </q-btn>
      <q-btn flat round dense icon="fas fa-check" color="positive" :disabled="AlifeCycle == 'New' || enableSaveBtn"
        @click="UpdateFilterswithIPN()">
        <q-tooltip anchor="bottom middle" self="top middle" :offset="[0, 0]"> Apply</q-tooltip>
      </q-btn>
      <q-btn flat round dense icon="fa-solid fa-check-double" size="15px" color="positive"
        :disabled="AlifeCycle == 'New' || enableSaveAllBtn" @click="UpdateFilters()">
        <q-tooltip anchor="bottom middle" self="top middle" :offset="[0, 0]">Apply to All Bom Parts</q-tooltip>
      </q-btn>
    </q-card-actions>
    <spinner-component v-if="loader" type="Tree"></spinner-component>
    <!-- {{ autoFilters['Best Lead Time'].priority }} -->
    <!-- <q-scroll-area  :delay="1200"   style="height: 370px;  "    > -->
    <q-card-section class="q-pa-none">
      <q-form>
        <q-table class="col-12 filtertable my-sticky-last-column-table1" flat bordered wrap-cells dense1 hide-no-data
          v-if="!loader">
          <template v-slot:top-row>
            <q-tr v-for="(key) in data.priority" :key="key" @click="getKeyData(key), selectRow(key)"
              :class="{ 'selected-rowFilter': selectedRow === key }">
              <q-td>
                <q-checkbox :model-value="data[key].enable" size="xs" @update:model-value="updateEnable(key)" />
              </q-td>
              <q-td v-m v-if="view[key] === 'boolean-switch'">
                <boolean-toggle @change-boolean-switch="getBooleanValue" :Values="data[key].value"
                  :Options="data[key].options" :booleanKey="key" :Enable="data[key].enable" />
              </q-td>
              <q-td v-else-if="view[key] === '3-way-switch'">
                <three-way-toggle @change-three-way-toggle="getThreeWayToggleValue" :Values="data[key].value"
                  :threewaySwitchKey="key" :Enable="data[key].enable" />
              </q-td>
              <q-td v-else-if="view[key] === 'slider'">
                <slider-component @change-slider="getSliderValue" :Values="data[key].value"
                  :MinValue="data[key].min_value" :MaxValue="data[key].max_value" :sliderKey="key"
                  :Enable="data[key].enable" />
              </q-td>
              <q-td v-else-if="view[key] === 'check-dropdown'">
                <multi-drop-down @change-checkbox-dropdown="getCheckBoxValue" :Values="data[key].value"
                  :Options="data[key].options" :multiSelectKey="key" :Enable="data[key].enable" />
              </q-td>
              <q-td v-else-if="view[key] === 'dropdown'">
                <drop-down @change-dropdown="getDropdown" :Values="data[key].value" :Options="data[key].options"
                  :selectKey="key" :Enable="data[key].enable" />
              </q-td>
              <q-td class="ellipsis-text">
                <span v-if="data[key].name === 'Lead Time'">Lead Time (weeks)</span>
                <span v-else> {{ data[key].name }}</span>


                <q-tooltip anchor="center left" self="center right" :offset="[2, 2]">
                  <span v-if="data[key].name === 'Lead Time'">Lead Time (weeks)</span>
                  <span v-else> {{ data[key].name }}</span>
                </q-tooltip>

              </q-td>
              <!-- <q-td class="starrow" > <q-btn :class="{ 'selected-rowt': selectedRow === key }" size="xs" dense @click="labelMoveUp" icon="arrow_upward"   outline unelevated /></q-td> -->
              <q-td class="starrow  ">
                <div class="row" style="width: 40px;">
                  <div class="col-6   " v-if="this.data[key].enable"> <q-btn
                      :class="{ 'selected-rowt': selectedRow === key }" size="xs" dense @click="labelMoveUp"
                      icon="arrow_upward" outline unelevated class=" ">
                      <q-tooltip>Increase filter priority</q-tooltip>
                    </q-btn></div>
                  <!-- <div class="col-1   "> -</div> -->
                  <div class="col-6   " v-if="this.data[key].enable"> <q-btn
                      :class="{ 'selected-rowt': selectedRow === key }" size="xs" dense @click="labelMoveDown"
                      icon="arrow_downward" outline unelevated> <q-tooltip>Decrease
                        filter
                        priority</q-tooltip></q-btn></div>
                </div>
              </q-td>
            </q-tr>
          </template>

        </q-table>
      </q-form>
    </q-card-section>
    <!-- </q-scroll-area> -->
  </q-card>
  <q-dialog v-model="confirm">
    <q-card class="gdialog card-outline-primary  confirm-popup">
      <q-card-section class="card-header-section1">
        <div v-if="applyFiltersForIPN" class="text-h6 q-ml-sm"><q-icon name="report_problem" flat dense color="warning"
            size="25px" class="q-mb-xs"></q-icon><span class="q-ml-sm ">Filters Update for Selected
            Ipn</span>
        </div>
        <div v-else class="text-h6 q-ml-sm"><q-icon name="report_problem" flat dense color="warning" size="25px"
            class="q-mb-xs"></q-icon><span class="q-ml-sm ">Filters Update for Entire Bom</span></div>
      </q-card-section>

      <q-card-section class="">
        <span class="" v-if="applyFiltersForIPN">Are you sure, you want to apply a filter to the selected
          <p>{{ ipn }} ?</p>
        </span>
        <span class="" v-else>Are you sure, you want to Apply filter for all parts in BOM ?</span>


      </q-card-section>

      <q-card-actions align="right" class="card-footer-section">
        <q-btn label="Ok" outline color="secondary" @click="handleUpdateFilters" />
        <q-btn label="Cancel" outline color="secondary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>


<script>
import { ref } from "vue";
import BooleanToggle from "./BooleanToggle.vue";
import MultiDropDown from "./MultiDropDown.vue";
import SliderComponent from "./Slider.vue";
import ThreeWayToggle from "./ThreeWayToggle.vue";
import DropDown from "./DropdownComponent.vue";
import { SpinnerComponent } from "@/utils"
import { AlertService, SettingService } from "@/services";
import { Loading } from 'quasar'
import { useMyStore } from '@/store';
export default {
  name: "filter-tab",
  components: {
    BooleanToggle,
    MultiDropDown,
    SliderComponent,
    ThreeWayToggle,
    DropDown,
    SpinnerComponent
  },
  props: ['bom_name', 'ipn', 'AlifeCycle'],


  setup() {
    const myStore = useMyStore();
    const data = ref(null);
    const booleanValue = ref(null);
    const checkBoxValue = ref(null);
    const dropdownValue = ref(null);
    const sliderValue = ref(null);
    const threeWayToggle = ref(null);
    const view = ref({});
    const key = ref("");
    const label = ref(null);
    const selectedRow = ref(null);
    const selectRow = (key) => {
      selectedRow.value = key;
    };



    return {
      myStore,
      booleanValue,
      sliderValue,
      checkBoxValue,
      dropdownValue,
      threeWayToggle,
      data,
      view,
      key,
      label,
      selectedRow,
      selectRow,
      loader: ref(true),
      enableResetBtn: ref(true),
      enableSaveBtn: ref(true),
      enableSaveAllBtn: ref(true),
      confirm: ref(false),
      applyFiltersForIPN: false,
      autoFilters: ref({}),
      FilterMatch: ref({}),
      FilterMatchkey: ref([]),
      DefaultData: ref([]),
      icons: ref({}),
      ActiveFilters: ref({})

    };
  },

computed:{
  refreshpricing() {
      return this.myStore.refreshpricing;
    },
},
  watch: {
    ipn(newipn, oldipn) {
      if (newipn !== oldipn) {
        this.getSettingIPN();
        this.getSettingIPNDefault();
      }
    },
    refreshpricing() {
      this.getSettingIPN();
    },
    booleanValue(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getChanges(newVal[0], newVal[1]);
      }
    },
    checkBoxValue(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getChanges(newVal[0], newVal[1]);
      }
    },
    sliderValue(newVal, oldVal) {
      if (newVal != oldVal) {
        this.enableResetBtn = false
        this.enableSaveBtn = false
        this.enableSaveAllBtn = false
        this.getChanges(newVal[0], newVal[1]);
      } else {
        this.enableResetBtn = false
        this.enableSaveBtn = false
        this.enableSaveAllBtn = false
        this.getChanges(newVal[0], newVal[1]);
      }

    },
    threeWayToggle(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getChanges(newVal[0], newVal[1]);
      }
    },
    dropdownValue(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getChanges(newVal[0], newVal[1]);
      }
    },

  },
  methods: {
    changeFilters(afkey, afindex) {
      // this.getDefaultData()
      var str = afindex.replace(/\s+/g, '_').toLowerCase();
      this.ActiveFilters = { name: str, label: afindex }
      if (afindex == 'Custom') {
        this.getSettingIPN()
      } else {
        for (let key2 in afkey) {
          if (key2 != 'priority' && key2 != 'filter_type' && key2 != 'tooltip' && key2!='preferred_supplier') {
            this.data[key2].value = afkey[key2].value
            this.data[key2].enable = afkey[key2].enable
            if (key2 == 'lead_time' || key2 == 'excess_qty') {
              this.data[key2].min_value = afkey[key2].min_value
              this.data[key2].max_value = afkey[key2].max_value
            }
          }
          if(key2=='preferred_supplier'){
            this.data[key2].value = this.DefaultData[key2].value;
            afkey[key2].value = this.DefaultData[key2].value;
          }
          this.data.priority = afkey.priority
        }
        // this.data=afkey
      }
      this.data.filter_type = afkey.filter_type
    },
    getSettingIPN() {
      this.loader = true;
      this.enableResetBtn = true
      this.enableSaveBtn = true
      this.enableSaveAllBtn = true
      SettingService.SettingsData(this.bom_name, this.ipn).then((x) => {
        this.data = x;
        this.ActiveFilters = this.data.filter_type != null ? this.data.filter_type : { "name": "custom", "label": "Custom" }
        this.loader = false;
      });

    },
    getSettingIPNDefault() {
      SettingService.SettingsData(this.bom_name, this.ipn).then((x) => {
        this.DefaultData = x;
      });

    },
    UpdateFilterswithIPN() {
      this.confirm = true
      this.applyFiltersForIPN = true;
    },
    extractValues(json) {
      const valuesArray = [];
      // const priorityArray=[]

      for (const key in json) {
        if (key != 'priority' && key != 'filter_type' && key != 'tooltip') {
          if (Array.isArray(json[key].value)) {
            valuesArray.push(...json[key].value);
            // valuesArray.push(...json[key].enable);
          } else {
            valuesArray.push(json[key].value);
            valuesArray.push(json[key].enable);
          }
        }
        if (key=='priority'){
          valuesArray.push(...json[key]);
        }

      }
      return valuesArray;
    },
    arraysEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }

      // Sort both arrays
      const sortedArr1 = [...arr1];
      const sortedArr2 = [...arr2];
      // Compare sorted arrays
      for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
          return false;
        }
      }
      return true;
    },
    UpdateFiltersforIPN() {
      if (this.ipn) {
        var compare = this.autoFilters[this.ActiveFilters.label]
        const array1 = this.extractValues(this.data);
        const array2 = this.extractValues(compare);
        if (this.arraysEqual(array1, array2)) {
          this.data.filter_type = this.ActiveFilters
        } else {
          this.data.filter_type = { "name": "custom", "label": "Custom" }
        }
        const body = this.data;
        SettingService.updateSettingsIpn(this.bom_name, body, this.ipn,).then((x) => {
          this.confirm = false
          AlertService.Success(x);

          this.getSettingIPN();
          this.getSettingIPNDefault();
          // this.$emit('updateStep', 3)
          this.$emit("FilterValue", this.ipn)
          this.myStore.refreshpricing = Math.random();
          this.enableSaveBtn = true;
          this.enableSaveAllBtn = true;
          this.enableResetBtn = true;
        }).catch(err => {
          this.confirm = false
          const newerr = err || [{ "msg": "Unprocessable Entity" }];
          if (newerr.length > 0) {
            AlertService.Error(newerr[0].msg);
          }
          else {
            AlertService.Error(newerr)
          }

        })

      }
    },
    UpdateFilters() {
      this.confirm = true
      this.applyFiltersForIPN = false
    },
    updateFiltersforBom() {
      // if (confirm("Are you sure ,you want to Apply  filter for all parts in BOM")) {
      var compare = this.autoFilters[this.ActiveFilters.label]
      const array1 = this.extractValues(this.data);
      const array2 = this.extractValues(compare);
      if (this.arraysEqual(array1, array2)) {
        this.data.filter_type = this.ActiveFilters
      } else {
        this.data.filter_type = { "name": "custom", "label": "Custom" }
      }
      console.log("filter type", this.data.filter_type);
      const body = this.data;
      SettingService.updateSettingsIpn(this.bom_name, body).then((x) => {
        // Loading.show();
        this.confirm = false
        // setTimeout(() => {
        AlertService.Success(x);
        this.getSettingIPN();
        this.getSettingIPNDefault();
        this.$emit("FilterValue", this.ipn)
        this.myStore.refreshpricing = Math.random();
        this.enableSaveBtn = true;
        this.enableSaveAllBtn = true;
        this.enableResetBtn = true;
        Loading.hide();
      }).catch(err => {
        this.confirm = false
        Loading.hide();
        const newerr = err || [{ "msg": "Unprocessable Entity" }];
        AlertService.Error(newerr[0].msg);
      })


    },
    async handleUpdateFilters() {
      try {
        // Determine which update function to call based on the condition
        if (this.applyFiltersForIPN) {
          await this.UpdateFiltersforIPN();
        } else {
          await this.updateFiltersforBom();
          Loading.show();
        }

        // Close the dialog after the action is completed
        this.confirm = false;
      } catch (err) {
        // Handle errors if needed
        console.error(err);
      }
    },
    updateEnable(key) {
      this.enableResetBtn = false
      this.enableSaveBtn = false
      this.enableSaveAllBtn = false
      this.data[key].enable = !this.data[key].enable

    },

    getDefaultData() {
      this.getSettingIPN()
      this.enableResetBtn = true;
      this.enableSaveBtn = true;
      this.enableSaveAllBtn = true;
    },
    getBooleanValue(value) {
      this.booleanValue = value;
    },
    getCheckBoxValue(value) {
      this.checkBoxValue = value;
    },
    getDropdown(value) {
      this.dropdownValue = value;
    },
    getSliderValue(value) {
      if (value == 0) {
        this.sliderValue = value;
      } else {
        this.sliderValue = value;
      }
    },
    getThreeWayToggleValue(value) {
      // this.threeWayToggle = value;
      if (value[0] === 'true') {
        this.threeWayToggle = [true, value[1]];
      } else {
        this.threeWayToggle = [false, value[1]];
      }

    },
    getKeyData(key) {
      this.key = key;
    },
    labelMoveUp() {
      this.enableResetBtn = false;
      this.enableSaveBtn = false;
      this.enableSaveAllBtn = false;
      if (this.label.indexOf(this.key) - 0) {
        let result = this.label.toSpliced(
          this.label.indexOf(this.key) - 1,
          2,
          this.key,
          this.label[this.label.indexOf(this.key) - 1]
        );
        this.data.priority = result
        return (this.label = result);
      }
    },
    labelMoveDown() {
      this.enableResetBtn = false;
      this.enableSaveBtn = false;
      this.enableSaveAllBtn = false;
      if (this.label[this.label.indexOf(this.key) + 1]) {
        let result = this.label.toSpliced(
          this.label.indexOf(this.key),
          2,
          this.label[this.label.indexOf(this.key) + 1],
          this.key
        );
        this.data.priority = result
        return (this.label = result);
      }
    },
    getChanges(value, field) {
      // this.ActiveFilters='Custom'
      this.enableResetBtn = false;
      this.enableSaveBtn = false;
      this.enableSaveAllBtn = false;
      switch (field) {
        case `${field}`: {
          this.data[field].value = value;
          return this.data;
        }
      }

      return this.data;
    },

  },
  mounted() {

    this.getSettingIPN()
    this.getSettingIPNDefault()
  },
  beforeMount() {
    SettingService.ViewSettingData().then((x) => {
      this.view = x.view;
      this.label = Object.keys(x.view);
    });
    SettingService.AutoFilters().then((x) => {
      this.autoFilters = x
      for (let fikey in x) {
        this.FilterMatchkey.push(fikey)
        this.FilterMatch = this.autoFilters[fikey]
      }
    })
    SettingService.Viewicons().then((x) => {
      this.icons = x.AutoFilters;
    });
  },
};
</script>
<style scoped></style>

<style lang="sass">
.my-sticky-last-column-table1
  /* specifying max-width so the example can
    highlight the sticky column on any browser window */
  

  thead tr:last-child th:last-child
    /* bg color is important for th; just specify one */
    /*  background-color: #00b4ff*/

  td:last-child
  /*  background-color: #00b4ff*/

  th:last-child,
  td:last-child
    position: sticky
    right: 0
    z-index: 1
</style>