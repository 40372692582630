<template>
  <div v-for="(k, index) in myStore.dashboardlist[type]" :key="index">
    <q-list bordered separator dense>
      <q-item-label header class="bg-secondary text-black shadow-2 q-pa-sm">{{ index }}</q-item-label>

      <q-item clickable v-ripple v-for="(list, index) in k" :key="index" class="q-my-sm" @click="changeid(list.id)"
        :active="list.id === cdashid">
        <q-item-section>
          <q-item-label>{{ list.label }}</q-item-label>
          <!-- <q-item-label caption lines="1">{{ list.id }}</q-item-label> -->
        </q-item-section>
      </q-item>

      <!-- <q-separator /> -->
    </q-list>




  </div>
</template>


<script>
import { ref } from 'vue';
import { useMyStore } from '@/store';
export default {
  name: 'RigthMenu',
  props: [ 'type'],
  setup() {
    const myStore = useMyStore();
    return {
      myStore,
      cdashid: ref(''),
    }
  },
  components: {


  },
  methods: {
    changeid(id) {
      this.cdashid = id;
      this.$emit("dashid", id);
    },
   
    findFirstSectionId(module) {
      const moduleData = this.myStore.dashboardlist[module];
      if (moduleData) {
        const sections = Object.keys(moduleData);
        if (sections.length > 0) {
          const firstSection = sections[0];
          const firstSectionData = moduleData[firstSection];
          if (firstSectionData && firstSectionData.length > 0) {
            return firstSectionData[0].id;
          }
        }
      }
      return null;
    }
  },
  mounted() {
  
    this.cdashid = this.findFirstSectionId(this.type);
    console.log("Quotes First Section First ID:", this.cdashid);
    this.$emit("dashid",this.cdashid );
  },

}
</script>
