<template>
    <q-select filled :disable="Enable==false" :model-value="(model=Values)" dense options-dense @update:model-value="model = $event" :options="Options" />
</template>

<script>
import { ref } from "vue";

export default {
    props: ["Values", "Options", "selectKey",'Enable'],
    setup(props) {
        return {
            model: ref(props.Values),
            labelKey: ref(props.selectKey)
        };
    },
    watch: {
        model(newVal) {
            if (newVal && this.labelKey) {
                this.getChange(newVal, this.labelKey);
            }
        },
    },
    methods: {
        getChange(value, labelKey) {
            this.$emit("change-dropdown", [value, labelKey]);
        },
    }
};
</script>
  