<template>
  <q-badge color="secondary" :class="type?' slider-badge q-mt-sm':'slider-badge'">
    {{ model }}{{ sliderKey=='excess_qty' ? '%':'' }}
  </q-badge>
  <q-item style="min-height: auto;padding: 0px;">
    <q-item-section side :class="type?'q-ml-sm filter-label':'filter-label'">
      {{ MinValue }}
    </q-item-section>
    <q-item-section>
      <q-slider dense :disable="Enable == false" :model-value="(model = Values)" @update:model-value="model = $event"
        color="primar" :min="MinValue" :step="2" :max="MaxValue" />
      <!-- <q-slider v-model="standard" :min="0" :max="50"/> -->
    </q-item-section>
    <q-item-section side :class="type?'filter-label q-mr-sm':'filter-label'">
      {{ MaxValue }}
    </q-item-section>
  </q-item>
  <!-- </div> -->
</template>
  
<script>
import { ref } from "vue";

export default {
  name: "slider-component",
  props: ["Values", "MinValue", "MaxValue", "sliderKey", 'Enable','type'],
  setup(props) {
    return {
      standard: ref(2),
      model: ref(props.Values),
      labelKey: ref(props.sliderKey)
    };
  },
  watch: {
    model(newVal) {
      if (newVal && this.labelKey) {
        this.getChange(newVal, this.labelKey);
      } else {
        this.getChange(newVal, this.labelKey);
      }
    },
  },

  methods: {
    getChange(value, labelKey) {
      this.$emit("change-slider", [value, labelKey]);
    },
  }
};
</script>
  