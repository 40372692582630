<template>


    <q-list bordered separator dense v-for="(list, index) in myStore.MenuList" :key="index">
      <q-item-label header class="bg-secondary text-black shadow-2 q-pa-sm">{{ index }}</q-item-label>
  
      <q-item clickable v-ripple  v-for="(list, key) in list" :key="key" class="q-my-sm" @click="changeid(index,list)"
        :active="list.url === myStore.MenuUrl">
      
  
        <q-item-section>
          <q-item-label>{{ list.label }}</q-item-label>
          <!-- <q-item-label caption lines="1">{{ list.id }}</q-item-label> -->
        </q-item-section>
      </q-item>
  
      <!-- <q-separator /> -->
    </q-list>
  
  
  
  
  <!-- </div> -->
  </template>
  
  
  <script>
  // import { ref } from 'vue';
  import { useMyStore } from '@/store';
  export default {
  name: 'RigthMenu',
  
  setup() {
    const myStore = useMyStore();
    return {
      myStore,
      // dashid:ref('34535f51-e3a3-4451-a390-19d84440d8bc')
    }
  },
  components: {
  
  
  },
  methods: {
    changeid(indexKey,list) {
      if(indexKey ==='Help'){
        this.myStore.MenuUrl = list.url;
      }else{
        this.download(list)
      }
     
    
    },
    download(list) {
      const link = document.createElement('a');
      link.href = list.url;
      link.download = list.label;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
    },
  
  },
  mounted() {
  
  },
  
  }
  </script>