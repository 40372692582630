<template>
    <q-page class="q-pa-sm layout">
        <div class="page-heading q-pa-sm">Documents</div>
        <div class="col">
            <div class="row flex">
                <div class="col-8   ">
                    <div class="col-8   ">
                        <FileToasters type="upload" :files="files" :lastuploaddate="lastuploaddate"
                            :totaldata="totaldata" :FileSuffix="FileSuffix"  :errboms="errboms"/>
                    </div>
                </div>
                <div class="col-4  uploadsection q-px-lg  " v-if="$q.screen.gt.xs">

                    <!-- <q-card class="card  bg-upload "> -->
                    <FileUpload :bom_name="selected" @filepicked="filepicked" />
                    <!-- </q-card> -->

                </div>
            </div>
            <div class=" row flex    ">
                <div class="col-10 q-my-sm  ">

                    <q-btn-dropdown outline class=" q-mr-md btn-fixed-width" color="secondary" label="Create New" icon="add">
                        <q-list dense>
                            <q-item clickable v-close-popup @click="openpopup('oem')">
                                <q-item-section>
                                    <q-item-label> {{ this.myStore.treeLabels[0] }}</q-item-label>
                                </q-item-section>
                            </q-item>

                            <q-item clickable v-close-popup @click="openpopup('product')">
                                <q-item-section>
                                    <q-item-label> {{ this.myStore.treeLabels[1] }}</q-item-label>
                                </q-item-section>
                            </q-item>

                            <q-item clickable v-close-popup @click="openpopup('iteration')">
                                <q-item-section>
                                    <q-item-label> {{ this.myStore.treeLabels[2] }}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </q-btn-dropdown>




                    <q-btn to="/quotes" outline color="secondary" label="Go to Quote" :disable="lhidequotes"
                        icon="fas fa-arrow-right" class="btn-fixed-width"/>


                </div>
                <div class="col-3 column-height" v-if="isCardVisible">
                    <q-card class="treecard" flat>

                        <div class="q-mini-drawer-hide absolute lefticon-outer" style="top: 100px; right: -17px;z-index:99"
                            v-if="isCardVisible == true">
                            <q-btn dense round unelevated color="secondary" icon="fa-solid fa-angle-left"
                                @click="isCardVisible = false" class="lefticon"/>
                        </div>
                        <q-tabs class="no-shadow flat " v-model="leftTab" :switch-indicator="true" align="justify"
                            narrow-indicator active-bg-color="primary text-white" indicator-color="primary">
                            <q-tab name="products" label="Explorer" class="curved-tabs" />

                        </q-tabs>
                        <q-separator />
                        <q-tab-panels v-model="leftTab" animated class="text-dark text-center">

                            <q-tab-panel name="products" class="q-pa-none">
                                <bom-tree @changeBom_name="getBom_name" type="upload" :popup="dialog"
                                    :newCreatedData="newCreatedData" />

                            </q-tab-panel>

                        </q-tab-panels>

                    </q-card>


                </div>

                <div
                    :class="{ 'col-9 column-height': isCardVisible == true, ' col-12 column-height': isCardVisible == false, }">
                    <q-card class="q-ml-sm treecard">

                        <div class="q-mini-drawer-hide absolute lefticon-outer" style="top: 100px; left: -17px;z-index:99"
                            v-if="isCardVisible == false">
                            <q-btn dense round unelevated color="secondary" icon="fa-solid fa-angle-right"
                                @click="isCardVisible = true" class="lefticon" />
                        </div>
                        <q-tabs class="" v-model="Tab1" :switch-indicator="true" indicator-color="primary"
                            align="justify" narrow-indicator active-bg-color="primary text-white "
                            active-class="TabStyle">
                            <q-tab name="quotes" label="Quotes" class="" />
                            <q-tab name="orders" disable label="Orders" class="" />
                            <q-tab name="tracking" disable label="Tracking" class="" />
                            <q-tab name="inventory" disable label="Inventory" class="" />

                        </q-tabs>
                        <q-separator />
                        <q-tab-panels v-model="Tab1" animated class="text-dark text-center ">

                            <q-tab-panel name="quotes" class="">

                                <FileList :bom_name="selected" @Toasterfunction="Toaster" @hidequotes1="fhidequotes"
                                    :receivefile="receivefile" @fileDeleted="fileDeleted" @ErrorCount="ErrorCount" />

                            </q-tab-panel>

                            <q-tab-panel name="orders" class="q-pa-xs">

                                <FileList :bom_name="selected" @Toasterfunction="Toaster" @hidequotes1="fhidequotes" />

                            </q-tab-panel>
                            <q-tab-panel name="tracking" class="q-pa-xs">

                            </q-tab-panel>
                            <q-tab-panel name="inventory" class="q-pa-xs">

                            </q-tab-panel>
                        </q-tab-panels>
                    </q-card>
                </div>

            </div>






        </div>

    </q-page>

    <q-dialog v-model="dialog" persistent>
        <div style="overflow-x: hidden; width: 700px; max-width: 80vw;">
            <PopupComponent :Type="single" :addClose="closeDialog" @created="created" />
        </div>
    </q-dialog>
</template>

<script>
import { ref } from 'vue'
import BomTree from '@/components/BomTree.vue'
import PopupComponent from '@/components/PopupComponent.vue'
import FileToasters from '@/components/upload/FileToasters.vue'
import FileList from '@/components/upload/FileList.vue'
import FileUpload from '@/components/upload/FileUpload.vue'
import { useMyStore } from '@/store';

export default {

    setup() {
        const myStore = useMyStore();
        const isCardVisible = ref(true);
        const closeCard = () => {
            isCardVisible.value = false;
        };
        return {
            myStore,
            isCardVisible,
            closeCard,
            Tab1: ref("quotes"),
            leftTab: ref('products'),
            dialog: ref(false),
            selected: ref(localStorage.getItem("bom_name")),
            files: ref(0),
            lastuploaddate: ref(),
            ToatalMB: ref(),
            totaldata: ref(0),
            lhidequotes: ref(null),
            receivefile: ref(''),
            receiveDeletefile: ref(''),
            FileSuffix: ref(''),
            newCreatedData: ref(''),
            errboms: ref(0)
        }
    },
    components: {
        BomTree,
        FileList,
        FileUpload,
        PopupComponent,
        FileToasters,
    },

    methods: {
        // receiving upload file 
        filepicked(fileName) {
            this.receivefile = fileName

        },
        // receiving deleted File
        fileDeleted(delFile) {
            this.receiveDeletefile = delFile
            if (this.receiveDeletefile) {
                this.receivefile = ''
            }
        },
        // receiving newly created Node
        created(createdData) {
            this.newCreatedData = createdData
        },
        // open the create new popup
        openpopup(type) {
            this.single = type;
            this.dialog = true;
        },
        //  get Bom name 
        getBom_name(selected) {
            this.selected = selected;
        },
        // receive Extract File 
        fhidequotes(hidequotes) {
            this.lhidequotes = hidequotes;
        },
        ErrorCount(err) {
            if(err > 0 ){
            this.errboms =err
            } else { this.errboms=0 } 
        },
        // get Toaster details from Files
        Toaster(toaster) {
            this.files = toaster.length
            if (toaster.length > 0) {
                this.lastuploaddate = toaster[0].created_at
            }
            else {
                this.lastuploaddate = null
            }
            this.ToatalMB = toaster.map(doc => (doc.document_size))
            var Size = this.ToatalMB.reduce((sum, item) => sum + item, 0);

            var i = Size == 0 ? 0 : Math.floor(Math.log(Size) / Math.log(1024));
            this.totaldata = (Size / Math.pow(1024, i)).toFixed(2) * 1;
            var suffix = (Size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
            this.FileSuffix = suffix.slice(-2)
            //  + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]


        },
        closeDialog() {
            this.dialog = false;

        },
    },
    mounted() {

    }

}
</script>

<style scoped></style>
